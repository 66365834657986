import axios from "axios";
import { message } from "antd";
import moment from "moment";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  FundOutlined,
  MenuOutlined,
  QuestionOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";

export const isNullOrEmptyArray = (value) => {
  return !value || value.length === 0;
};

export const getMenuItems = () => {
  if (!localStorage.getItem("menuList") === undefined) {
    clearLocalStorage();
  }
  let menuList = JSON.parse(localStorage.getItem("menuList"));

  if (isNullOrEmptyArray(menuList)) return [];

  let items = [];
  menuList.forEach((posMenu) => {
    const menu = posMenu;
    if (!menu || !menu.url || !menu.name) return;
    // const icon = getIconByName(menu.icon);
    items.push({
      key: menu.url,
      // icon: React.createElement(icon),
      label: menu.name,
      components: menu.components,
    });
  });
  return items;
};

export const getIconByName = (icon) => {
  if (icon === "UserOutlined") return UserOutlined;
  if (icon === "VideoCameraOutlined") return VideoCameraOutlined;
  if (icon === "UploadOutlined") return UploadOutlined;
  if (icon === "BarChartOutlined") return BarChartOutlined;
  if (icon === "CloudOutlined") return CloudOutlined;
  if (icon === "AppstoreOutlined") return AppstoreOutlined;
  if (icon === "TeamOutlined") return TeamOutlined;
  if (icon === "ShopOutlined") return ShopOutlined;
  if (icon === "FundOutlined") return FundOutlined;
  if (icon === "MenuOutlined") return MenuOutlined;
  return QuestionOutlined;
};

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  // if (!token || !token.token) window.location.replace("/unauthorized");
  return token.token;
};

export const getService = async (serviceUrl, body) => {
  try {
    const response = await axios.get(`/api/itc-gov-service-new${serviceUrl}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
      params: body,
    });
    return response.data;
  } catch (error) {
    let msg = error?.message;
    if (error?.response?.data?.message) msg = error.response.data.message;
    if ([400, 401, 504].includes(error?.response?.status)) clearLocalStorage();
    message.warning(msg);
  }
};

export const getServicePublic = async (serviceUrl, body, type = "json") => {
  try {
    const response = await axios.get(`/api/itc-gov-service-new${serviceUrl}`, {
      params: body,
      responseType: type
    });
    return response.data;
  } catch (error) {
    let msg = error?.message;
    if (error?.response?.data?.message) msg = error.response.data.message;
    if ([400, 401, 504].includes(error?.response?.status)) clearLocalStorage();
    message.warning(msg);
  }
};

export const getFileServicePublic = async (serviceUrl, body) => {
  try {
    const response = await axios.get(`/api/itc-gov-service-new${serviceUrl}`, {
      params: body,
      responseType: "blob"
    });
    return response.data;
  } catch (error) {
    let msg = error?.message;
    if (error?.response?.data?.message) msg = error.response.data.message;
    if ([400, 401, 504].includes(error?.response?.status)) clearLocalStorage();
    message.warning(msg);
  }
};
export const postServicePublic = async (serviceUrl, body) => {
  try {
    const response = await axios.post(`/api/itc-gov-service-new${serviceUrl}`, body, {
      headers: {},
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401)
      message.warning("Хүчингүй нэвтрэлт. Дахин нэвтэрнэ үү");

    let msg = error?.message;
    if (error?.response?.data?.message) msg = error.response.data.message;
    message.warning(msg);
  }
};

export const clearLocalStorage = () => {
  const token = localStorage.getItem("token");
  localStorage.clear();
  localStorage.setItem("token", token);
};

export const postService = async (serviceUrl, body) => {
  try {
    const response = await axios.post(`/api/itc-gov-service-new${serviceUrl}`, body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.status === 401)
      message.warning("Хүчингүй нэвтрэлт. Дахин нэвтэрнэ үү");

    let msg = error?.message;
    if (error?.response?.data?.message) msg = error.response.data.message;
    message.warning(msg);
  }
};

export const isNotPhoneNumber = (text) => {
  const regex = /^\d{8,10}$/;
  return (
    !text ||
    (!Number.isNaN(text) && !regex.test(text)) ||
    text === "" ||
    text === "-"
  );
};

export const isGreaterThanZero = (value) => {
  try {
    const parsedValue = parseInt(value);
    return !isNaN(parsedValue) && parsedValue > 0;
  } catch (err) {
    return false;
  }
};

export const truncateText = (text, length) => {
  if (!length || length === 0) length = 10;
  if (!text || text.length === 0) return "";

  if (text.length < length) return text;

  return text.substring(0, length) + "...";
};

export const currencyFormat = (num) => {
  if (!num || num.length === 0) return "₮0.00";
  return "₮" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const renderDate = (dateTimeString) => {
  if (!dateTimeString || dateTimeString.length === 0) return "";
  // Parse the dateTimeString using Moment.js
  const dateTime = moment(dateTimeString);

  // Format the dateTime in the desired format
  return dateTime.format("YYYY-MM-DD HH:mm:ss");
};

export const renderUserName = (user) => {
  if (!user) return "";
  let name = "";
  if (user.lastname?.length > 0) name = user.lastname.substring(0, 1) + ". ";

  name += user.firstname;
  if (
    !user.positionGroup ||
    !user.positionGroup.orgName ||
    !user.positionGroup.groups ||
    user.positionGroup.groups.length === 0
  )
    return name;

  name = truncateText(user.positionGroup.orgName, 15);
  return `${name} (${user.positionGroup.groups[0].name})`;
};

export const getTokenInfo = () => {
  let token = getToken();
  return jwtDecode(token);
};
