import { LandingPage } from "../Landing/LandingPage";
import About from "../Landing/About";
import Law from "../Landing/Law";
import News from "../Landing/News";
import NewsId from "../Landing/NewsId";
import Project from "../Landing/projects/Project";
import ProjectDetail from "../Landing/projects/ProjectDetail";
import {
  ApartmentOutlined,
  AuditOutlined,
  ContainerOutlined,
  FileProtectOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  SafetyCertificateOutlined,
  SnippetsOutlined,
  StarOutlined,
  TeamOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import Publicity from "../Landing/Publicity";
import Workers from "../Landing/Workers";
import IsoComponent from "../Landing/IsoComponent";
import FeedBack from "../Landing/FeedBack";
import Contact from "../Landing/Contact";
import HumanResource from "../Landing/HumanResource";
import JobAds from "../Landing/JobAds";
import JobIds from "../Landing/JobIds";

export const landingComponentList = [
  {
    componentCode: "/",
    component: <LandingPage />,
    title: "Нүүр хуудас",
    icon: <HomeOutlined />,
    path: "/",
  },
  {
    componentCode: "about",
    component: <About />,
    title: "Бидний тухай",
    icon: <ApartmentOutlined />,
    path: "/about",
  },
  {
    componentCode: "law",
    component: <Law />,
    title: "Хууль, Эрх зүй",
    path: "/law",
    icon: <AuditOutlined />,
  },
  {
    componentCode: "news",
    component: <News />,
    title: "Мэдээ мэдээлэл",
    path: "/news",
    icon: <ContainerOutlined />,
  },
  {
    componentCode: "newsId",
    component: <NewsId />,
    title: "Мэдээ, мэдээлэл",
    path: "/newsId",
    icon: <ContainerOutlined />,
  },
  {
    componentCode: "project",
    component: <Project />,
    title: "Хэрэгжүүлж буй төслүүд",
    icon: <SnippetsOutlined />,
    path: "/project",
  },
  {
    componentCode: "publicity",
    component: <Publicity />,
    title: "Ил тод байдал",
    icon: <FileProtectOutlined />,
    path: "/publicity",
  },
  {
    componentCode: "projectDtl",
    component: <ProjectDetail />,
    title: "Хэрэгжүүлж буй төслүүд",
    path: "/projectDtl",
  },
  {
    componentCode: "workers",
    component: <Workers />,
    icon: <TeamOutlined />,
    title: "Албан хаагчдын мэдээлэл",
    path: "/workers",
  },
  {
    componentCode: "iso",
    component: <IsoComponent />,
    icon: <SafetyCertificateOutlined />,
    title: "ISO/IEC 27001:2022",
    path: "/iso",
  },
  {
    componentCode: "feedback",
    component: <FeedBack />,
    icon: <MailOutlined />,
    title: "Санал хүсэлт",
    path: "/feedback",
  },
  {
    componentCode: "contact",
    component: <Contact />,
    icon: <PhoneOutlined />,
    title: "Холбоо барих",
    path: "/contact",
  },
  {
    componentCode: "human-resource",
    component: <HumanResource />,
    icon: <UserAddOutlined />,
    title: "Хүний нөөц",
    path: "/human-resource",
  },
  {
    componentCode: "job-ads",
    component: <JobAds />,
    icon: <StarOutlined />,
    title: "Нээлттэй ажлын байр",
    path: "/job-ads",
  },
  {
    componentCode: "job-adsId",
    component: <JobIds />,
    icon: <StarOutlined />,
    title: "Нээлттэй ажлын байр",
    path: "/job-ads",
  },
];
