import { Table, Empty, Button, Tooltip, Image, Badge } from "antd";
import { useEffect, useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { postService } from "../../tools";
import { mainUrl, newsUrl } from "../../serviceUrls";
import NewsAdd from "./NewsAdd";
import NewsDelete from "./NewsDelete";
import NewsEdit from "./NewsEdit";

const News = (props) => {
  const [data, setData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0, // Set total to 0 initially
    },
  });

  // Fetch data on component mount
  useEffect(() => {
    getdata(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, []);

  const getdata = async (currentPage = 1, pageSize = 10) => {
    setLoadingTable(true);
    const body = {
      page: currentPage - 1,  // API usually expects 0-indexed pages
      size: pageSize,
    };

    postService(newsUrl.getNewsActive, body)
        .then((res) => {
          setData(res.result);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              current: currentPage,
              pageSize: pageSize,
              total: res.totalItems,  // Set total count for pagination
            },
          });
        })
        .finally(() => {
          setLoadingTable(false);
        });
  };

  // Handle table pagination changes
  const handleTableChange = (pagination, filters, sorter) => {
    setLoadingTable(true);
    const { current, pageSize } = pagination;
    getdata(current, pageSize);
  };

  const columns = [
    {
      title: <div>Огноо</div>,
      dataIndex: "createdDateStr",
      key: "createdDateStr",
      width: 120,
      fixed: "left",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      ellipsis: true,
    },
    {
      title: <div>Нүүр зураг</div>,
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (img) => (
          <div>
            <Image alt="ITC_GOV" preview={true} src={"/api/itc-gov-service-new/file/view/" + img} />
          </div>
      ),
      ellipsis: true,
    },
    {
      title: <div>Нэр</div>,
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: <div>Дэлгэрэнгүй</div>,
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: <div>Төлөв</div>,
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      width: 90,
      render: (status) => (
          <div>
            <Tooltip
                title={
                  status === 3 ? "Идэвхтэй" : status === 1 ? "Идэвхгүй" : "Идэвхгүй"
                }
            >
              <Badge
                  status={status === 3 ? "success" : status === 1 ? "error" : "error"}
                  text={status === 3 ? "Идэвхтэй" : status === 1 ? "Идэвхгүй" : "Идэвхгүй"}
              />
            </Tooltip>
          </div>
      ),
      ellipsis: true,
    },
    {
      title: <div>Үйлдэл</div>,
      key: "id",
      width: 70,
      render: (params) => (
          <div>
            <div className="flex gap-2">
              <NewsEdit getNews={getdata} id={params.id} data={params} />
              <NewsDelete getNews={getdata} id={params.id} />
            </div>
          </div>
      ),
    },
  ];

  const data1 = data.map((e, i) => ({
    key: i,
    title: e.title,
    createdDateStr: e.createdDateStr,
    type: e.type,
    image: e.image,
    status: e.status,
    description: e.description,
    id: e.id,
    tags: [e.type === 0 ? "nice" : "developer"],
    newsImagesList: e.newsImagesList,
    newsDocumentList: e.newsDocumentList,
  }));

  return (
      <div>
        <div className="items-center">
          <div className="w-full mb-2 px-2 flex gap-2 justify-end">
            <Button onClick={() => getdata(tableParams.pagination.current, tableParams.pagination.pageSize)} icon={<ReloadOutlined style={{ padding: "0px 5px" }} />} />
            <NewsAdd getNews={getdata} />
          </div>
        </div>
        <Table
            size="middle"
            pagination={tableParams.pagination}
            columns={columns}
            dataSource={data1}
            bordered
            scroll={{ x: 1000, y: 500 }}
            loading={loadingTable}
            onChange={handleTableChange}
            locale={{
              emptyText: <Empty description="Хоосон" />,
            }}
        />
      </div>
  );
};

export default News;
