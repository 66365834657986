// import { Exo_2 } from "next/font/google";
// const inter = Exo_2({
//   subsets: ["latin"],
//   weight: "800",
// });
import {FormattedHTMLMessage} from "react-intl";

const Title = ({ name, size, color, bgColor }) => {
  return color === "brand-50" ? (
    <section
      style={{ background: "#031842", width: "100%" }}
    //   className={`${inter.className}`}
    >
      <div className="py-6 font-black text-3xl text-white flex items-start">
        <div className="w-3 h-7 bg-white"></div>
        <div
          className={
            size
              ? "text-lg leading-5 pl-6 w-11/12"
              : "test-size max-md:text-2xl leading-7 pl-6 w-11/12"
          }
        >
          <FormattedHTMLMessage id={name}/>
        </div>
      </div>
    </section>
  ) : (
    <section
      style={{ background: bgColor ? bgColor : "#fff", width: "100%" }}
    //   className={`${inter.className}`}
    >
      <div className="py-6 font-black text-3xl text-brand-50 flex items-start">
        <div className="w-3 h-7 bg-brand-50"></div>
        <div
          className={
            size
              ? "text-xl leading-5 pl-6 w-11/12"
              : "test-size max-md:text-2xl leading-7 pl-6 w-11/12"
          }
        >
          <FormattedHTMLMessage id={name}/>
        </div>
      </div>
    </section>
  );
};
export default Title;
