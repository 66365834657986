import SimpleMotion from "../../components/assist/simpleMotion/SimpleMotion";
import ProjectComp from "../ProjectComp";
import ProjectHeader from "./ProjectHeader";

const Project = () => {
  return (
    <SimpleMotion>
      <ProjectComp />
      <ProjectHeader />
    </SimpleMotion>
  );
};
export default Project;
