import {Badge, Button, Divider, Empty, Table, Tooltip} from "antd";
import axios from "axios";

import { useEffect, useState } from "react";
// import Add from "./add";
// import Delete from "./delete";
// import View from "./view";
// import config from "../../../../config";
import {ReloadOutlined} from "@ant-design/icons";
import {postService} from "../../tools";
import {jobadsUrl} from "../../serviceUrls";
import View from "./jobAdsView";
import Delete from "./jobAdsDelete";
import Add from "./jobAdsAdd";

const JobAdsComp = (props) => {
    const [data, setData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoadingTable(true);
        const header = {
            Authorization: "Bearer " + localStorage.getItem("token"),
        };
        const body = {
            a: 0,
        };
        await postService(jobadsUrl.getJobAdsActive,body).then((res =>{
            console.log("res:::",res)
            if (res.code === 1) {
                setData(res.result);
            }
        }))
            .finally(() => {
                setLoadingTable(false);
            });
        // axios
        //     .post("/api/postBT/jobads/getJobAds", body, { headers: header })
        //     .then((res) => {
        //         if (res.data.code === 1) {
        //             setData(res.data.result);
        //         }
        //     })
        //     .catch((err) => {
        //         console.log("err: ", err);
        //     })
        //     .finally(() => {
        //         setLoadingTable(false);
        //     });
    };
    const handleTableChange = (pagination, filters, sorter) => {};

    const columns = [
        {
            title: <div>Огноо</div>,
            dataIndex: "createdDateStr",
            key: "createdDateStr",
            width: 120,
            fixed: "left",
            ellipsis: true,
        },
        // {
        //   title: <div>Зураг</div>,
        //   dataIndex: "image",
        //   key: "image",
        //   width: 100,
        //   render: (img) => (
        //     <div>
        //       <Image
        //         alt="Obertech"
        //         preview={true}
        //         src={config.data.uploadUrl + img}
        //       />
        //     </div>
        //   ),
        //   ellipsis: true,
        // },
        {
            title: <div>Гарчиг</div>,
            dataIndex: "title",
            key: "title",
            // width: 120,
            ellipsis: true,
        },
        {
            title: <div>Дэлгэрэнгүй</div>,
            dataIndex: "description",
            key: "description",
            ellipsis: true,
        },
        {
            title: <div>Тоо ширхэг</div>,
            dataIndex: "quantity",
            key: "quantity",
            ellipsis: true,
            width: 110,
        },
        {
            title: "Төлөв",
            key: "type",
            sorter: (a, b) => a.type - b.type,
            render: (a) => (
                <Tooltip title={a.type === 1 ? "Идэвхтэй" : "Идэвхгүй"}>
                    <Badge
                        status={a.type === 1 ? "success" : "error"}
                        text={a.type === 1 ? "Идэвхтэй" : "Идэвхгүй"}
                    />
                </Tooltip>
            ),
        },
        {
            title: <div>Үйлдэл</div>,
            key: "id",
            fixed: "right",
            width: 140,
            render: (params) => (
                <div>
                    <div className="flex gap-2">
                        <View getData={getData} id={params.id} data={params} />
                        <Delete getData={getData} id={params.id} />
                    </div>
                </div>
            ),
        },
    ];

    const tableData = data
        ? data.map((e, i) => ({
            key: i,
            id: e.id,
            title: e.title,
            description: e.description,
            quantity: e.quantity,
            image: e.image,
            type: e.type,
            createdDateStr: e.createdDateStr.slice(0, 10),
        }))
        : [];
    return (
        <div>
            <div className="items-center">
                <div className="w-full mb-2 px-2 flex gap-2 justify-end">
                    <Button
                        onClick={getData}
                        icon={<ReloadOutlined style={{ padding: "0px 5px" }} />}
                    ></Button>
                    <Add getData={getData} />
                </div>
            </div>
            <Table
                size="middle"
                columns={columns}
                bordered
                dataSource={tableData}
                scroll={{ x: 1000, y: 500 }}
                loading={loadingTable}
                onChange={handleTableChange}
                locale={{
                    emptyText: <Empty description="Хоосон" />,
                }}
            />
        </div>
    );
};
export default JobAdsComp;
