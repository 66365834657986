import { LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Title from "./Title";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
const Law = () => {
  return (
    <div>
      <SimpleMotion>
        <div className="max-w-[1280px] mx-auto mt-4 bg-white">
          <Title name={"law"} />
          <div className="grid  grid-cols-1  px-14  max-lg:grid-cols-3 max-sm:grid-cols-1 border-t pt-5">
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=367"}
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border px-1"
              target="_blank"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                МОНГОЛ УЛСЫН ҮНДСЭН ХУУЛЬ
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=299"}
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
              target="_blank"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">Иргэний хууль</div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=14403"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Татварын ерөнхий хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=11227"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Нэмэгдсэн өртгийн албан татварын тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=209"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Гаалийн тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=208"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Гаалийн тариф, гаалийн татварын тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=12254"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Төсвийн тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=10497"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Шилэн дансны тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=492"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Төрийн болон орон нутгийн өмчийн тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=493"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Төрийн болон орон нутгийн өмчийн хөрөнгөөр бараа, ажил,
                үйлчилгээ худалдан авах тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=16230709635751"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Хөдөлмөрийн тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=564"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Хөдөлмөрийн аюулгүй байдал, эрүүл ахуйн тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=12408"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Төрийн болон албаны нууцын тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=16390263044601"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Нийтийн мэдээллийн ил тод байдлын тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=16390288615991"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Хүний хувийн мэдээлэл хамгаалах тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=16390355252531"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Цахим гарын үсгийн тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=16390365491061"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Кибер аюулгүй байдлын тухай хууль
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=16230720813481"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Журам батлах тухай (олон нийтийн хяналтыг хэрэгжүүлэх)
              </div>
            </Link>
            <Link
              to={"https://legalinfo.mn/mn/detail/8928"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Авлигын эсрэг хууль
              </div>
            </Link>
          </div>
          <div className="mt-4"></div>
          <Title name={"rules"} />
          <div className="flex flex-col   px-14 border-t pt-5 pb-20">
            <Link
              to={"https://legalinfo.mn/mn/detail?lawId=17141247338061"}
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Улсын төсөвт үйлдвэрийн газрыг өөрчлөн зохион байгуулах тухай
                /Санхүүгийн мэдээллийн технологийн төв/
              </div>
            </Link>
            <Link
              to={
                "https://legalinfo.mn/mn/detail?lawId=17141247350391&showType=1"
              }
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                &quot;Санхүүгийн мэдээллийн технологийн төв&quot; УТҮГ-ын дүрэм
              </div>
            </Link>
            <Link
              to={
                "https://legalinfo.mn/mn/detail?lawId=16530298796001&showType=1"
              }
              target="_blank"
              className="flex items-center gap-2 font-medium text-brand-50 hover:bg-gray-100 py-1 border-b border-l border-r px-1"
            >
              <LinkOutlined />
              <div className="underline underline-offset-2">
                Үндсэн системийн үйл ажиллагааг зохицуулах, систем ашиглаж
                үйлчилгээ үзүүлэх, мэдээлэл солилцох журам
              </div>
            </Link>
          </div>
        </div>
      </SimpleMotion>
    </div>
  );
};
export default Law;
