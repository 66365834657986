import { useEffect, useState } from "react";
import { postServicePublic } from "../../tools";
import { projectUrl } from "../../serviceUrls";
import { Empty, Skeleton, Spin } from "antd";
import { Link } from "react-router-dom";
import Arrow from "../../components/assist/arrow/Arrow";
import {FormattedMessage, useIntl} from "react-intl";

const ListItem = ({
  project,
  handleClick,
  isClicked,
  projectId,
  dataDtl,
  winWidth,
}) => {
  const intl = useIntl();
  let lan = ''
  useEffect(() => {
    lan = localStorage.getItem("localStorage")
  }, []);

  const projectReve = intl.formatMessage({id:'budget_revenue'})
  const projectExpen = intl.formatMessage({id:'budget_expenditure'})
  const projectControl = intl.formatMessage({id:'budget_control'})


  console.log("lan:",lan)

  return (
    <div className={"h-full max-sm:h-fit"}>
      <button
        onClick={() => handleClick(project.id)}
        className={`border cursor-pointer group hover:border-brand-50 h-full flex flex-col ${
          projectId === project.id
            ? "bg-brand-50 text-white"
            : "bg-white text-brand-50"
        } `}
      >
        <section
          className={
            projectId === project.id ? "bg-brand-50 w-full" : "bg-white w-full"
          }
        >
          <div
            className={`pt-6 pb-3 font-black flex items-start justify-items-start`}
          >
            <div
              className={`w-3 h-7 ${
                projectId === project.id ? "bg-white" : "bg-brand-50"
              }`}
            ></div>
            <div className={"text-xl leading-5 pl-6 pr-3 w-11/12 text-start"}>
              {project.title === "Төсвийн орлого " ? (
                  projectReve
              ) : project.title === "Төсвийн зарлага" ? (
                  projectExpen
              ) : project.title === "Үйл ажиллагааны хяналт" ? (
                  projectControl
              ) : (
                  project.title
              )}
            </div>
          </div>
        </section>
        <div
            className={`flex justify-between items-center text-xl font-semibold mb-0 gap-3 pr-5 pt-3 pb-5 ${
                projectId === project.id ? "bg-brand-50" : "bg-white"
            }`}
        >
          <div
              className={`duration-300 flex items-start px-3 ${
                  projectId === project.id ? "rotate-90" : ""
              }`}
          >
            <div
                className={`inline-block w-[8px] h-[8px] border-t-[2px] border-r-[2px] rotate-45 ${
                projectId === project.id ? "border-white" : "border-brand-50"
              }`}
            ></div>
          </div>
          <div className="text-sm text-justify leading-5 font-light">
            {lan === "mn"
                ? project.description
                : project.title === "Төсвийн орлого "
                    ? <FormattedMessage id={"budget_revenue_content"}/>
                    : project.title === "Төсвийн зарлага"
                        ? <FormattedMessage id={"budget_expenditure_content"}/>
                        : <FormattedMessage id={"budget_control_content"}/>}
          </div>
        </div>
      </button>
      {isClicked && dataDtl && projectId === project.id && winWidth < 640 && (
        <div
          className={`grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 text-brand-50 bg-white`}
        >
          {dataDtl
            .filter(
              (project) =>
                project.id !== 176 &&
                project.id !== 175 &&
                project.id !== 178 &&
                project.id !== 177
            )
            .map((project, index) => (
              <Link
                key={index}
                to={`/project/${project.projectId}/${project.id}`}
                className="py-6 px-9 border cursor-pointer hover:border-brand-50 group"
              >
                <div className="flex justify-between text-xl items-baseline font-semibold mb-2">
                  <div className="w-11/12 leading-5">{project.title}</div>
                  <div className="group-hover:translate-x-4 duration-300 flex items-baseline">
                    <Arrow />
                  </div>
                </div>
                <div className="text-sm text-justify leading-5 font-medium">
                  {project.description}
                </div>
              </Link>
            ))}
        </div>
      )}
    </div>
  );
};
const ProjectHeader = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataDtl, setDataDtl] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [winWidth, setWinWidth] = useState(null);
  const getProjects = () => {
    setLoading(true);
    postServicePublic(projectUrl.getProjectActive)
      .then((res) => {
        setWinWidth(window.innerWidth);
        setData(res?.result);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getProjects();
  }, []);
  const handleClick = async (id) => {
    if (projectId === id && isClicked) {
      setDataDtl(null);
      setProjectId(null);
      setIsClicked(false);
    }

    if (projectId !== id) {
      let project = data.filter((it) => it.id === id);
      if (project !== null && project.length !== 0)
        await setDataDtl(project[0].projectHdrs);
      setProjectId(id);
      setIsClicked(true);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="max-w-[1280px] max-xl: mx-auto mt-4 max-sm:mt-1 mb-4">
        <div
          className={`grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 place-items-start text-brand-50 ${
            loading ? "bg-white" : ""
          }`}
        >
          {loading ? (
            <>
              <Skeleton active className="pr-8 pl-8" />
              <Skeleton active className="pr-8" />
              <Skeleton active className="pr-8 mb-8" />
            </>
          ) : data ? (
            data.map((project, index) => (
              <ListItem
                key={index}
                project={project}
                handleClick={handleClick}
                isClicked={isClicked}
                projectId={projectId}
                dataDtl={dataDtl}
                winWidth={winWidth}
              />
            ))
          ) : (
            <Empty />
          )}
        </div>
        {isClicked && dataDtl && winWidth >= 640 && (
          <div
            className={`grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 text-brand-50 bg-white`}
          >
            {dataDtl
              .filter(
                (project) =>
                  project.id !== 176 &&
                  project.id !== 175 &&
                  project.id !== 178 &&
                  project.id !== 177
              )
              .map((project, index) => (
                <Link
                  key={index}
                  to={`/project/${project.projectId}/${project.id}`}
                  className="py-6 px-9 border cursor-pointer hover:border-brand-50 group"
                >
                  <div className="flex justify-between text-xl items-baseline font-semibold mb-2">
                    <div className="w-11/12 leading-5">{project.title}</div>
                    <div className="group-hover:translate-x-4 duration-300 flex items-baseline">
                      <Arrow />
                    </div>
                  </div>
                  <div className="text-sm text-justify leading-5 font-medium">
                    {project.description}
                  </div>
                </Link>
              ))}
          </div>
        )}
      </div>
    </Spin>
  );
};
export default ProjectHeader;
