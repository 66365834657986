import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloack";
import keycloakContext from "./common/keyCloakContext";

const eventLogger = (event, error) => {
  // console.log('onKeycloakEvent', event, error)
};

const tokenLogger = (tokens) => {
  localStorage.setItem("token", JSON.stringify(tokens));
};

const loginUser = () => {
  keycloak.login();
};

const logout = () => {
  keycloak.logout();
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReactKeycloakProvider
    initOptions={{ onLoad: "none", checkLoginIframe: false }}
    authClient={keycloak}
    onEvent={eventLogger}
    onTokens={tokenLogger}
  >
    <keycloakContext.Provider value={{ loginUser, logout }}>
      <App />
    </keycloakContext.Provider>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
