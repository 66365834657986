import React, {useEffect, useState} from "react";
import {Button, Popover, Row} from "antd";
import {AppstoreFilled} from "@ant-design/icons";
import {getService} from "../tools";
import {otherUrl} from "../serviceUrls";

const HeaderOtherSystemMenu = () => {
  const theme = localStorage.getItem('theme');
  const [menus, setMenus] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    // getService(otherUrl.getMenusWithHTML).then((res) => setMenus(res));
  }, []);

  if (!menus)
    return null;

  return (
    <Row>
      <Popover
        content={<div style={{height: "100%"}}
                      dangerouslySetInnerHTML={{__html: theme === 'dark' ? menus.dark : menus.light}} />}
        placement="bottomRight"
        open={open}
        trigger="hover"
        onOpenChange={handleOpenChange}
      >
        <Button icon={<AppstoreFilled />} type="primary" ghost />
      </Popover>
    </Row>
  );
};

export default HeaderOtherSystemMenu;
