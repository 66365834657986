import { Image } from "antd";
import React, { useEffect, useState } from "react";
import Title from "../Landing/Title";
import StatCounter from "./StatCounter";
import { getServicePublic } from "../tools";
import { projectUrl } from "../serviceUrls";

const TimeCounterComp = ({ statData, projectCode }) => {
  const [stat, setStat] = useState([0, 0, 0, 0]);
  const [value, setValue] = useState([0, 0, 0, 0]);

  useEffect(() => {
    setStatRate();
    runAtSpecificTime();
  }, []);

  const runAtSpecificTime = () => {
    const oneHour = 3600000;
    const now = new Date();
    let eta_ms =
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() === 23 ? 0 : now.getHours() + 1,
        0,
        0,
        0
      ).getTime() - now;
    setTimeout(function () {
      // run every hour from now on
      setInterval(setStatRate, oneHour);
    }, eta_ms);
  };

  const setStatRate = async () => {
    const baseStat = [];
    getServicePublic(projectUrl.getStatData).then((res) => {
      let data = res;
      if (projectCode === "VATPS") {
        baseStat.push(
          data.filter((x) => x.statCode === "Хэвлэгдсэн баримтын тоо")[0]
            .statValue
        );
        baseStat.push(
          data.filter((x) => x.statCode === "Бүртгүүлсэн баримтын тоо")[0]
            .statValue
        );
        baseStat.push(
          data.filter((x) => x.statCode === "Борлуулагчийн тоо")[0].statValue
        );
        baseStat.push(
          data.filter((x) => x.statCode === "Нийт хэрэглэгчийн тоо")[0]
            .statValue
        );
      } else if (projectCode === "ETAX") {
        baseStat.push(
          data.filter((x) => x.statCode === "Татвар төлөгчийн тоо")[0]
            ? data.filter((x) => x.statCode === "Татвар төлөгчийн тоо")[0]
                .statValue
            : 1057743
        );
        baseStat.push(
          data.filter((x) => x.statCode === "Тайлангийн тоо")[0]
            ? data.filter((x) => x.statCode === "Тайлангийн тоо")[0].statValue
            : 967093
        );
        baseStat.push(
          data.filter((x) => x.statCode === "Тайлангийн төрөл")[0]
            ? data.filter((x) => x.statCode === "Тайлангийн төрөл")[0].statValue
            : 41
        );
      }
    });

    if (statData !== null && statData.length !== 0) {
      const now = new Date();
      let stats = [0, 0, 0, 0];
      let values = [0, 0, 0, 0];
      for (let i = 0; i < now.getHours(); i++) {
        stats[0] += statData[i].srStat1;
        stats[1] += statData[i].srStat2;
        stats[2] += statData[i].srStat3;
        stats[3] += statData[i].srStat4;
      }
      stats[0] +=
        Math.round(
          (now.getMinutes() * 60 + now.getSeconds()) *
            (statData[now.getHours()].srStat1 / 3600)
        ) + baseStat[0];
      stats[1] +=
        Math.round(
          (now.getMinutes() * 60 + now.getSeconds()) *
            (statData[now.getHours()].srStat2 / 3600)
        ) + baseStat[1];
      stats[2] +=
        Math.round(
          (now.getMinutes() * 60 + now.getSeconds()) *
            (statData[now.getHours()].srStat3 / 3600)
        ) + baseStat[2];
      stats[3] +=
        Math.round(
          (now.getMinutes() * 60 + now.getSeconds()) *
            (statData[now.getHours()].srStat4 / 3600)
        ) + baseStat[3];
      setStat(stats);

      values[0] = statData[now.getHours()].srStat1 / 3600;
      values[1] = statData[now.getHours()].srStat2 / 3600;
      values[2] = statData[now.getHours()].srStat3 / 3600;
      values[3] = statData[now.getHours()].srStat4 / 3600;
      setValue(values);
    } else {
      setStat(baseStat);
    }
  };

  const counter = () => {};

  return projectCode === "VATPS" ? (
    <div className="max-w-[1280px] mx-auto mt-4 text-sm">
      <div className="grid grid-cols-4 max-xl:grid-cols-2 max-sm:grid-cols-1 gap-3">
        <StatCounter
          colors={"brand-50"}
          code={"VATPS"}
          name={`Хэвлэгдсэн баримт`}
          value={value[0]}
          initVal={stat[0]}
        />
        <StatCounter
          code={"VATPS"}
          name={`Баталгаажсан баримт`}
          value={value[1]}
          initVal={stat[1]}
        />
        <StatCounter
          code={"VATPS"}
          colors={"brand-50"}
          name={`Борлуулагч тоо`}
          value={value[2]}
          initVal={stat[2]}
        />
        <StatCounter
          code={"VATPS"}
          name={`Систем хэрэглэгчийн тоо`}
          value={value[3]}
          initVal={stat[3]}
        />
      </div>
    </div>
  ) : projectCode === "ETAX" ? (
    <div className="max-w-[1280px] mx-auto mt-4 bg-white">
      <Title name={"Тоон мэдээлэл /2024 оны байдлаар/"} />
      <div
        className={
          "grid grid-cols-3 max-xl:grid-cols-1 h-fit gap-4 w-full px-9 pb-6"
        }
      >
        <div
          className={
            "grid max-xl:grid-cols-2 max-md:grid-cols-1  h-fit gap-4 w-full"
          }
        >
          <StatCounter
            code={"ETAX"}
            name={`Татвар төлөгчийн тоо`}
            value={value[0]}
            initVal={stat[0]}
          />
          <StatCounter
            code={"ETAX"}
            name={`Тайлангийн тоо`}
            value={value[1]}
            initVal={stat[1]}
          />
        </div>
        <div
          className={
            "grid grid-cols-1 max-xl:grid-cols-2 max-md:grid-cols-1 h-fit gap-4 w-full"
          }
        >
          <div
            className={
              "flex px-10 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
            }
          >
            <div className={"py-3 text-white"}>
              <p className={"font-semibold pb-2"}>Тайлангийн төрөл</p>
              <p className={"text-[25px] font-semibold py-2"}>
                {stat[2].toLocaleString("en-US")}
              </p>
            </div>
          </div>
          <div
            className={
              "flex px-10 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
            }
          >
            <div className={"py-3 text-white"}>
              <p className={"font-semibold pb-2 text-gray-600"}>
                Тайлангийн илгээлт
              </p>
              <p className={"text-[25px] font-semibold py-2"}>-</p>
            </div>
          </div>
        </div>
        <div
          className={
            "grid grid-cols-1 max-xl:grid-cols-2 max-md:grid-cols-1 h-fit gap-4 w-full"
          }
        >
          <div
            className={
              "flex px-4 py-2 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
            }
          >
            <div
              className={
                "h-full flex items-center pl-3 pr-6 max-sm:pl-0 max-sm:pr-3"
              }
            >
              <Image
                className={"h-[71px] w-[62px] object-contain"}
                src={"/images/icon/female_icon.png"}
                alt="ITC GOV"
                width={44}
                height={71}
              />
            </div>
            <div className={"py-2 max-sm:py-1"}>
              <p className={"pb-2 max-sm:pb-1"}>Татварын тайлан шийдвэрлэлт:</p>
              <p className={"font-black pb-1"}>Байцаагч</p>
              <p className={"text-[25px] font-black pb-2 max-sm:pb-1"}>32,4%</p>
            </div>
          </div>
          <div
            className={
              "flex px-4 py-2 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
            }
          >
            <div
              className={
                "h-full flex items-center pl-3 pr-6 max-sm:pl-0 max-sm:pr-3"
              }
            >
              <Image
                className={"h-[63px] object-contain"}
                src={"/images/icon/com_icon.png"}
                alt="ITC GOV"
                width={62}
                height={63}
              />
            </div>
            <div className={"py-2 max-sm:py-1"}>
              <p className={"pb-2 max-sm:pb-1"}>Татварын тайлан шийдвэрлэлт:</p>
              <p className={"font-black pb-1"}>Систем</p>
              <p className={"text-[25px] font-black pb-2 max-sm:pb-1"}>67,5%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default TimeCounterComp;
