
import { Image } from "antd";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import Title from "./Title";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import {FormattedMessage} from "react-intl";

const IsoComponent = () => {
  const [isFirstScrolled0, setIsFirstScrolled0] = useState(true);
  const [isFirstScrolled1, setIsFirstScrolled1] = useState(true);
  const [isFirstScrolled2, setIsFirstScrolled2] = useState(true);
  const [isFirstScrolled3, setIsFirstScrolled3] = useState(true);
  const [isFirstScrolled4, setIsFirstScrolled4] = useState(true);
  //let isScrolled = false;
  useEffect(() => {
    setOnScroll();
  }, [
    isFirstScrolled0,
    isFirstScrolled1,
    isFirstScrolled2,
    isFirstScrolled3,
    isFirstScrolled4,
  ]);

  const setOnScroll = () => {
    window.onscroll = function () {
      onScrollFunc();
    };
  };

  const onScrollFunc = () => {
    if (document.getElementById("isoTimeLine")) {
      if (document.documentElement.scrollTop < 400) {
        if (!isFirstScrolled0) {
          document.getElementById("arrow_0").className =
            "flex lg:justify-end max-lg:justify-start pt-5 pb-6 slideDownFadeIn";
          document.getElementById("text_0").className =
            "text-justify leading-snug textFadeIn";
          document.getElementById("image_0").className =
            "lg:mt-[110px] max-lg:mt-[40px] flex justify-center lg:w-[422px] max-lg:max-h-[260px] bg-[#F0F0F0] textFadeIn";
        }
        // document.getElementById('arrow_1').className = 'flex justify-start pt-5 pb-6 slideDownFadeIn';
        // document.getElementById('text_1').className = 'text-justify leading-snug textFadeIn';
        // document.getElementById('image_1').className = 'pt-[107px] max-lg:pt-[40px] flex items-center justify-center lg:w-[426px] h-full textFadeIn';
      } else if (document.documentElement.scrollTop < 800) {
        setIsFirstScrolled0(false);
        document.getElementById("arrow_0").className =
          "flex lg:justify-end max-lg:justify-start pt-5 pb-6 slideDownFadeOut";
        document.getElementById("text_0").className =
          "text-justify leading-snug textFadeOut";
        document.getElementById("image_0").className =
          "lg:mt-[110px] max-lg:mt-[40px] flex justify-center lg:w-[422px] max-lg:max-h-[260px] bg-[#F0F0F0] textFadeOut";
        if (!isFirstScrolled1) {
          document.getElementById("arrow_1").className =
            "flex justify-start pt-5 pb-6 slideDownFadeIn";
          document.getElementById("text_1").className =
            "text-justify leading-snug textFadeIn";
          document.getElementById("image_1").className =
            "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full textFadeIn";
        }
      } else if (document.documentElement.scrollTop < 1150) {
        setIsFirstScrolled1(false);
        // document.getElementById('arrow_0').className = 'flex lg:justify-end max-lg:justify-start pt-5 pb-6 slideUpFadeIn';
        // document.getElementById('text_0').className = 'text-justify leading-snug textFadeInUp';
        // document.getElementById('image_0').className = 'lg:mt-[110px] max-lg:mt-[40px] flex justify-center items-center lg:w-[422px] max-lg:max-h-[260px] bg-[#F0F0F0] textFadeInUp';
        document.getElementById("arrow_1").className =
          "flex justify-start pt-5 pb-6 slideDownFadeOut";
        document.getElementById("text_1").className =
          "text-justify leading-snug textFadeOut";
        document.getElementById("image_1").className =
          "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full textFadeOut";
        if (!isFirstScrolled2) {
          document.getElementById("arrow_2").className =
            "flex justify-end pt-5 pb-6 slideDownFadeIn";
          document.getElementById("text_2").className =
            "text-justify leading-snug textFadeIn";
          document.getElementById("image_2").className =
            "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full textFadeIn";
        }
      } else if (document.documentElement.scrollTop < 1450) {
        setIsFirstScrolled2(false);
        // document.getElementById('arrow_1').className = 'flex justify-start pt-5 pb-6 slideUpFadeIn';
        // document.getElementById('text_1').className = 'text-justify leading-snug textFadeInUp';
        // document.getElementById('image_1').className = 'pt-[107px] max-lg:pt-[40px] flex items-center justify-center lg:w-[426px] h-full textFadeInUp';
        document.getElementById("arrow_2").className =
          "flex lg:justify-end max-lg:justify-start pt-5 pb-6 slideDownFadeOut";
        document.getElementById("text_2").className =
          "text-justify leading-snug textFadeOut";
        document.getElementById("image_2").className =
          "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full textFadeOut";
        if (!isFirstScrolled3) {
          document.getElementById("arrow_3").className =
            "flex justify-start pt-5 pb-6 slideDownFadeIn";
          document.getElementById("text_3").className =
            "text-justify leading-snug textFadeIn";
          document.getElementById("image_3").className =
            "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full textFadeIn";
        }
      } else if (document.documentElement.scrollTop < 1950) {
        setIsFirstScrolled3(false);
        // document.getElementById('arrow_2').className = 'flex justify-end pt-5 pb-6 slideUpFadeIn';
        // document.getElementById('text_2').className = 'text-justify leading-snug textFadeInUp';
        // document.getElementById('image_2').className = 'pt-[107px] max-lg:pt-[40px] flex items-center justify-center lg:w-[426px] h-full textFadeInUp';
        document.getElementById("arrow_3").className =
          "flex justify-start pt-5 pb-6 slideDownFadeOut";
        document.getElementById("text_3").className =
          "text-justify leading-snug textFadeOut";
        document.getElementById("image_3").className =
          "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full textFadeOut";
        if (!isFirstScrolled4) {
          document.getElementById("arrow_4").className =
            "flex justify-end pt-5 pb-6 slideDownFadeIn";
          document.getElementById("text_4").className =
            "text-justify leading-snug textFadeIn";
          document.getElementById("image_4").className =
            "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full textFadeIn";
        }
      } else {
        setIsFirstScrolled4(false);
        // document.getElementById('arrow_3').className = 'flex justify-start pt-5 pb-6 slideUpFadeIn';
        // document.getElementById('text_3').className = 'text-justify leading-snug textFadeInUp';
        // document.getElementById('image_3').className = 'pt-[107px] max-lg:pt-[40px] flex items-center justify-center lg:w-[426px] h-full textFadeInUp';
        document.getElementById("arrow_4").className =
          "flex lg:justify-end max-lg:justify-start pt-5 pb-6 slideDownFadeOut";
        document.getElementById("text_4").className =
          "text-justify leading-snug textFadeOut";
        document.getElementById("image_4").className =
          "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[422px] h-full textFadeOut";
      }
    }
  };

  return (
    <div className="text-brand-50 scroll-smooth">
     
      <SimpleMotion>
        <div className="mt-4 max-w-[1280px] mx-auto bg-brand-50 text-white">
          <Title
            name={"iso_header"}
            color={"brand-50"}
          />
          <div className="px-9">
            <FormattedMessage id={"iso_content"}/>
          </div>
          <div className="border-t mt-8 p-8 px-14 pl-12 flex gap-8 max-xl:flex-col">
            <div className="w-5/12 z-0 max-xl:w-full">
              <Swiper
                effect={"coverflow"}
                slide={"true"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 2.5,
                }}
                pagination={{ el: ".swiper-pagination", clickable: true }}
                // navigation={{
                //   nextEl: ".swiper-button-next",
                //   prevEl: ".swiper-button-prev",
                //   clickable: true,
                // }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container sm:h-[550px] sm:w-[450px] max-sm:h-[325px] max-sm:w-[250px]"
              >
                <SwiperSlide>
                  <Image
                    style={{ objectFit: "contain", height: "100%" }}
                    // src={"/images/background/background3.png"}
                    src={"/images/sertificate/sert2024.png"}
                    alt="ITC GOV"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    style={{ objectFit: "contain", height: "100%" }}
                    // src={"/images/background/background3.png"}
                    src={"/images/sertificate/sert1.png"}
                    alt="ITC GOV"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    style={{ objectFit: "contain", height: "100%" }}
                    // src={"/images/background/background3.png"}
                    src={"/images/sertificate/sert2.png"}
                    alt="ITC GOV"
                  />
                </SwiperSlide>

                <div className="slider-controler z-50">
                  <div className="swiper-button-prev slider-arrow">
                    <ion-icon name="arrow-back-outline"></ion-icon>
                  </div>
                  <div className="swiper-button-next slider-arrow">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
              </Swiper>
            </div>
            <div className="text-justify w-7/12 max-xl:w-full">
              <p className="text-xl mb-8 font-[1000]">
                <FormattedMessage id={"iso_main_header"}/>
              </p>
              <div className="mb-8">
                <FormattedMessage values={{br:<br/>}} id={"iso_main_content_1"}/>
              </div>
              <div className="mb-8">
                <FormattedMessage values={{br:<br/>}} id={"iso_main_content_2"}/>
              </div>
              <div>
                <FormattedMessage values={{br:<br/>}} id={"iso_main_content_3"}/>
              </div>
            </div>
          </div>
        </div>
        <div
          id={"isoTimeLine"}
          className="mt-4 max-w-[1280px] mx-auto bg-white text-brand-50 py-10 text-[12px] flex flex-col items-center max-sm:pl-2"
        >
          <div
            className={
              "flex justify-center max-lg:flex-col lg:divide-dashed lg:divide-x-2 lg:divide-gray-300 max-lg:border-l-2 max-lg:border-dashed max-lg:border-gray-300 max-lg:ml-3 max-lg:pr-5"
            }
          >
            <div
              className={
                "max-w-[462px] lg:pr-10 max-lg:pl-10 max-sm:pl-5 relative pt-5"
              }
            >
              <div
                className={
                  "rounded-full bg-white border-2 border-[#173D7A] w-5 h-5 absolute lg:-right-[11px] top-12 max-lg:-left-[11px]"
                }
              ></div>
              <div
                id={"arrow_0"}
                className={
                  "flex lg:justify-end max-lg:justify-start pt-5 pb-6 opacity-0"
                }
              >
                <div className={"rev-triangle lg:hidden"}></div>
                <div
                  className={
                    "bg-[#173D7A] text-white text-center pt-1 font-semibold text-xl w-[118px] h-[38px]"
                  }
                >
                  2020 он
                </div>
                <div className={"triangle max-lg:hidden"}></div>
              </div>
              <div
                id={"text_0"}
                className={"text-justify leading-snug opacity-0"}
              >
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцоо ISO 27001:2013
                олон улсын стандартыг байгууллагын үйл ажиллагаандаа нэвтрүүлэх
                шийдвэрийг гаргаж ажлын хэсэг байгуулсан.
                <br />
                <br />
                Зөрүүтэй байдлын шинжилгээ /Gap assessment/-г хийж шаардлага
                бодлого, дүрэм журмын цар хүрээ болон зохицуулалтуудыг
                тодорхойлсон.
                <br />
                <br />
                Байгууллагын бүх мэдээллийн хөрөнгүүдийг тодорхойлж хөрөнгөд
                суурилсан эрсдэлийн үнэлгээг хийж гүйцэтгэсэн.
                <br />
                <br />
                Үйл ажиллагаанд ашиглагдах нийт 30 гаруй баримт бичгийг
                боловсруулж баталгаажуулсан.
                <br />
                <br />
                Бодлого, журмуудыг мөрдөж эхэлсэн бөгөөд хэрэгжүүлэхтэй
                холбоотой холбогдох бүртгэлүүдийг үүсгэсэн.
              </div>
            </div>
            <div className={"max-w-[462px] pl-10 max-sm:pl-5"}>
              <div
                id={"image_0"}
                className={
                  "lg:mt-[110px] max-lg:mt-[40px] flex justify-center lg:w-[422px] max-lg:max-h-[260px] bg-[#F0F0F0] opacity-0"
                }
              >
                <Image
                  style={{
                    objectFit: "none",
                    width: "100%",
                    backgroundColor: "#F0F0F0",
                    paddingTop: "40px",
                    paddingBottom: "40px",
                  }}
                  src={"/images/iso/pic0.png"}
                  alt="ITC GOV"
                  preview={false}
                />
              </div>
            </div>
          </div>
          <div
            className={
              "flex justify-center max-lg:flex-col-reverse lg:divide-dashed lg:divide-x-2 lg:divide-gray-300 max-lg:border-l-2 max-lg:border-dashed max-lg:border-gray-300 max-lg:ml-3 max-lg:pr-5"
            }
          >
            <div className={"max-w-[462px] lg:pr-10 max-lg:pl-10 max-sm:pl-5"}>
              <div
                id={"image_1"}
                className={
                  "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full opacity-0"
                }
              >
                <Image
                  style={{ objectFit: "none", width: "100%" }}
                  src={"/images/iso/pic2.png"}
                  alt="ITC GOV"
                  preview={false}
                />
              </div>
            </div>
            <div
              className={
                "max-w-[462px] lg:pl-10 max-lg:pl-10 max-sm:pl-5 relative pt-5"
              }
            >
              <div
                className={
                  "rounded-full bg-white border-2 border-[#173D7A] w-5 h-5 absolute -left-[11px] top-12"
                }
              ></div>
              <div
                id={"arrow_1"}
                className={"flex justify-start pt-5 pb-6 opacity-0"}
              >
                <div className={"rev-triangle"}></div>
                <div
                  className={
                    "bg-[#173D7A] text-white text-center pt-1 font-semibold text-xl w-[118px] h-[38px]"
                  }
                >
                  2021 он
                </div>
              </div>
              <div
                id={"text_1"}
                className={"text-justify leading-snug opacity-0"}
              >
                Бүх шатны сургалтуудыг зохион байгуулж ажилтан бүрд стандарт,
                бодлого, журмуудыг мөрдөж ажиллахад шаардлагатай мэдлэгийг
                олгосон.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцооны чиглэлээр
                анхны дотоод аудиторуудыг бэлтгэж бэлтгэгдсэн нөөцөд тулгуурлан
                дотоод аудитыг амжилттай зохион байгуулсан.
                <br />
                <br />
                Олон улсын магадлан итгэмжлэгдсэн, гэрчилгээжүүлэх эрхтэй Их
                британи улсын “Certiva Limited” болон “LMS Certification Pvt”
                Ltd байгууллагаар Мэдээллийн аюулгүй байдлын удирдлагын
                тогтолцоо ISO 27001:2013 олон улсын стандартын шаардлагуудаар
                хөндлөнгийн гэрчилгээжүүлэх аудитыг амжилтай хийлгэж нийт 10
                бүлэг шаардлага болон сонгон авсан 112 хяналтуудыг амжилттай
                нэвтрүүлэн хэрэгжүүлж байгаа гэж дүгнэгдэн олон улсын
                баталгаажсан гэрчилгээг хүлээн авсан.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцооны 2 дахь дотоод
                аудитыг амжилттай зохион байгуулсан.
              </div>
            </div>
          </div>
          <div
            className={
              "flex justify-center max-lg:flex-col lg:divide-dashed lg:divide-x-2 lg:divide-gray-300 max-lg:border-l-2 max-lg:border-dashed max-lg:border-gray-300 max-lg:ml-3 max-lg:pr-5"
            }
          >
            <div
              className={
                "max-w-[462px] lg:pr-10 max-lg:pl-10 max-sm:pl-5 relative pt-5"
              }
            >
              <div
                className={
                  "rounded-full bg-white border-2 border-[#173D7A] w-5 h-5 absolute lg:-right-[11px] top-12 max-lg:-left-[11px]"
                }
              ></div>
              <div
                id={"arrow_2"}
                className={
                  "flex lg:justify-end max-lg:justify-start pt-5 pb-6 opacity-0"
                }
              >
                <div className={"rev-triangle lg:hidden"}></div>
                <div
                  className={
                    "bg-[#173D7A] text-white text-center pt-1 font-semibold text-xl w-[118px] h-[38px]"
                  }
                >
                  2022 он
                </div>
                <div className={"triangle max-lg:hidden"}></div>
              </div>
              <div
                id={"text_2"}
                className={"text-justify leading-snug opacity-0"}
              >
                Өмнөх эрсдэлийг бууруулсан байдалд дүн шинжилгээ хийж дараагийн
                эрсдэлийн үнэлгээг хийж гүйцэтгэсэн.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцооны 3 дахь дотоод
                аудитыг амжилттай зохион байгуулсан.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцоо ISO 27001:2013
                олон улсын стандартын шаардлагуудаар хөндлөнгийн баталгаажуулах
                аудитыг амжилттай хийлгэж стандартын бүх шаардлагуудыг хангасан
                бөгөөд тасралтгүй сайжруулах үйл явцыг амжилттай хэрэгжүүлж
                байгаа байгууллага гэж дүгнэгдэн гэрчилгээг сунгуулсан.
                <br />
                <br />
                Монгол улсын хууль тогтоомжтой стандартын шаардлагуудыг
                уялдуулан нийцлийг хангах чиглэлээр онцгой анхаарч ажилласан.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцооны 4 дэх дотоод
                аудитыг амжилттай зохион байгуулсан.
                <br />
                <br />
                Харилцагч байгууллагууддаа өөрсдийн хэрэгжүүлсэн сайн жишгийг
                сурталчлан таниулах, хамтын ажиллагаандаа стандартын
                шаардлагуудыг мөрдөж ажиллахын давуу тал болон бусад
                шаардлагатай мэдлэг, мэдээллийг түгээн дэлгэрүүлэх олон ажлыг үе
                шаттай зохион байгуулж төрийн байгууллагууд болон хамтран
                ажилладаг хувийн хэвшлүүдийг энэ чиглэлээр манлайлан ажиллаа.
              </div>
            </div>
            <div className={"max-w-[462px] pl-10 max-lg:pl-5"}>
              <div
                id={"image_2"}
                className={
                  "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[422px] h-full opacity-0"
                }
              >
                <Image
                  style={{ objectFit: "none", width: "100%" }}
                  src={"/images/iso/pic3.png"}
                  alt="ITC GOV"
                  preview={false}
                />
              </div>
            </div>
          </div>
          <div
            id={"2023"}
            className={
              "flex justify-center max-lg:flex-col-reverse lg:divide-dashed lg:divide-x-2 lg:divide-gray-300 max-lg:border-l-2 max-lg:border-dashed max-lg:border-gray-300 max-lg:ml-3 max-lg:pr-5"
            }
          >
            <div className={"max-w-[462px] lg:pr-10 max-lg:pl-10 max-sm:pl-5"}>
              <div
                id={"image_3"}
                className={
                  "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[426px] h-full opacity-0"
                }
              >
                <Image
                  style={{ objectFit: "none", width: "100%" }}
                  src={"/images/iso/pic4.jpg"}
                  alt="ITC GOV"
                  preview={false}
                />
              </div>
            </div>
            <div
              className={
                "max-w-[462px] lg:pl-10 max-lg:pl-10 max-sm:pl-5 relative pt-5"
              }
            >
              <div
                className={
                  "rounded-full bg-white border-2 border-[#173D7A] w-5 h-5 absolute -left-3 top-12"
                }
              ></div>
              <div
                id={"arrow_3"}
                className={"flex justify-start pt-5 pb-6 opacity-0"}
              >
                <div className={"rev-triangle"}></div>
                <div
                  className={
                    "bg-[#173D7A] text-white text-center pt-1 font-semibold text-xl w-[118px] h-[38px]"
                  }
                >
                  2023 он
                </div>
              </div>
              <div
                id={"text_3"}
                className={"text-justify leading-snug opacity-0"}
              >
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцоо ISO 27001:2013
                олон улсын стандартад өөрчлөлт орж байгаатай холбогдуулан
                байгууллагын үйл ажиллагааг стандартын дараагийн хувилбар болох
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцоо ISO 27001:2022
                хувилбарт заасан шаардлагуудад нийцүүлэх, тухайн стандартын
                аудитыг хийлгэж баталгаажуулахад шаардлагатай бэлтгэл ажлыг
                хангах зорилготой ажлын хэсгийг байгуулсан.
                <br />
                <br />
                Дээд шатны эрх бүхий байгууллагаас хийсэн аудитын хүрээнд
                эрсдэл, аюулгүй байдлын чиглэлээр үл тохирол, сайжруулах
                зөвлөмжгүйгээр амжилттай дүгнэгдсэн.
                <br />
                <br />
                Эрсдэлийн удирдлагыг амжилттай хэрэгжүүлж өмнөх эрсдэлүүдийг
                хангалттай хэмжээнд бууруулж чадсан.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцоо ISO 27001:2013
                олон улсын стандартын шаардлагуудаар хөндлөнгийн баталгаажуулах
                аудитыг амжилтай хийлгэж стандартын бүх шаардлагуудыг хангасан
                бөгөөд тасралтгүй сайжруулах үйл явцыг амжилттай хэрэгжүүлж
                байгаа байгууллага гэж дүгнэгдэн гэрчилгээг сунгуулсан.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцооны 5 дахь дотоод
                аудитыг амжилттай зохион байгуулсан.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцоо ISO 27001:2022
                олон улсын стандартад шинээр тусгагдсан шаардлагуудыг бүрэн
                хангуулахтай холбоотой олон тооны зохицуулалтын шинэтгэлийг
                бодлого, журмын зохицуулалт болон үйл ажиллагаандаа тусган
                нэвтрүүлж дууслаа.
              </div>
            </div>
          </div>
          <div
            id={"2024"}
            className={
              "flex justify-center max-lg:flex-col lg:divide-dashed lg:divide-x-2 lg:divide-gray-300 max-lg:border-l-2 max-lg:border-dashed max-lg:border-gray-300 max-lg:ml-3 max-lg:pr-5 lg:pb-20"
            }
          >
            <div
              className={
                "max-w-[462px] lg:pr-10 max-lg:pl-10 max-sm:pl-5 relative pt-5"
              }
            >
              <div
                className={
                  "rounded-full bg-white border-2 border-[#173D7A] w-5 h-5 absolute lg:-right-[11px] top-12 max-lg:-left-[11px]"
                }
              ></div>
              <div
                id={"arrow_4"}
                className={
                  "flex lg:justify-end max-lg:justify-start pt-5 pb-6 opacity-0"
                }
              >
                <div className={"rev-triangle lg:hidden"}></div>
                <div
                  className={
                    "bg-[#173D7A] text-white text-center pt-1 font-semibold text-xl w-[118px] h-[38px]"
                  }
                >
                  2024 он
                </div>
                <div className={"triangle max-lg:hidden"}></div>
              </div>
              <div
                id={"text_4"}
                className={"text-justify leading-snug opacity-0"}
              >
                Шилжилтийн ажлын хэсгийн үр дүнг дүгнэн бодлого журмын
                шинэчлэлтэй холбоотой сургалтыг бүх шатанд амжилттай,
                хүртээмжтэйгээр зохион байгуулж дууслаа.
                <br />
                <br />
                Мэдээллийн аюулгүй байдлын удирдлагын тогтолцооны шинэ хувилбар
                /ISO 27001:2022/ олон улсын стандартын шаардлагуудаар анхны
                дотоод аудитыг хийснээр 6 дахь дотоод аудитаа амжилттай зохион
                байгуулсан.
                <br />
                <br />
                Монгол улсын кибер аюулгүй байдлын үндэсний зөвлөлийн ажлын
                албатай хамтарсан уулзалтыг удаа дараа зохион байгуулж энэ
                чиглэлээр шинээр хамтран хэрэгжүүлэх үйл ажиллагаануудаа
                тодотгон, бодлогын түвшинд нэгдмэл байдлаар ажиллах, кибер
                аюулгүй байдлын чадавхыг бэхжүүлэх талаар байр сууриа нэгтгэн
                хамтран ажиллаж байна.
                <br />
                <br />
                Олон улсын магадлан итгэмжлэгдсэн, гэрчилгээжүүлэх эрхтэй АНУ-ын
                “Global Inter Certification” Co., Ltd. байгууллагаар Мэдээллийн
                аюулгүй байдлын удирдлагын тогтолцоо ISO 27001:2022 олон улсын
                стандартын шаардлагуудаар хөндлөнгийн гэрчилгээжүүлэх аудитыг
                амжилтай хийлгэж нийт 10 бүлэг шаардлага болон сонгон авсан 93
                хяналтуудыг амжилттай нэвтрүүлэн хэрэгжүүлж байгаа гэж дүгнэгдэн
                олон улсын баталгаажсан гэрчилгээг хүлээн авсан.
              </div>
            </div>
            <div className={"max-w-[462px] pl-10 max-lg:pl-5"}>
              <div
                id={"image_4"}
                className={
                  "pt-[107px] max-lg:pt-[40px] flex justify-center lg:w-[422px] h-full opacity-0"
                }
              >
                <Image
                  style={{ objectFit: "none", width: "100%" }}
                  src={"/images/iso/pic5.png"}
                  alt="ITC GOV"
                  preview={false}
                />
              </div>
            </div>
          </div>
        </div>
      </SimpleMotion>
    </div>
  );
};
export default IsoComponent;
