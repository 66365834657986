const mn = {
    "main_intro_1":"Санхүүгийн мэдээллийн технологийн төв",
    "main_intro_2":"Улсын төсөвт үйлдвэрийн газар",

    "header_about_us":"Бидний тухай",
    "header_projects":"Хэрэгжүүлж буй төслүүд",
    "header_news":"Мэдээ, мэдээлэл",
    "header_transparency":"Ил тод байдал",
    "header_law":"Хууль, эрх зүй",
    "header_account":"Шилэн данс",

    "about_us_vision":"Алсын хараа",
    "about_us_vision_content":"Монгол Улсын төрийн санхүүгийн салбарын мэдээллийн технологийн тэргүүлэгч байх.",


    "vision_contact":"Холбоо барих",
    "vision_feedback":"Санал хүсэлт",
    "vision_call":"Авлига мэдээлэх утас: +976110",

    "intro":"Танилцуулга",
    "about_us_intro":"Санхүүгийн мэдээллийн технологийн төв нь санхүү, татвар, гаалийн байгууллагын үйл ажиллагааг цахимжуулах чиг үүрэгтэй Улсын төсөвт үйлдвэрийн газар юм. Мэдээллийн системүүдийг хөгжүүлэх, нэвтрүүлэх,системийн тасралтгүй үйл ажиллагааг хангах, санхүүгийн мэдээллийн технологийн чиглэлээр мэргэжил, арга зүйн зөвлөгөө өгөх, санхүүгийн мэдээллийн нэгдсэн санг бий болгох үйл ажиллагааг хэрэгжүүлдэг. Мөн Засгийн газрын харьяа төрийн захиргааны байгууллагуудын мэдээллийн технологийн уялдаа холбоог сайжруулахад дэмжлэг үзүүлэн ажиллаж байна.",
    "about_us_mission":"Эрхэм зорилго",
    "about_us_mission_1":"Мэдээллийн системийн хөгжүүлэлтийг гүйцэтгэх",
    "about_us_mission_2":"IT-ийн дэвшилтэт технологийг үйл ажиллагаандаа ашиглах",
    "about_us_mission_3":"IT-ийн дэвшилтэт технологийг үйл ажиллагаандаа ашиглах",
    "about_us_mission_4":"Санхүүгийн мэдээллийн нэгдсэн системийг хянах, аюулгүй ажиллагааг хангах",

    "org_structure":"Бүтэц зохион байгуулалт",
    "about_us_org_administration":"Захиргааны алба",
    "about_us_org_finance":"Санхүүгийн цахим мэдээллийн алба",
    "about_us_org_system":"Систем төлөвлөлт статистикийн алба",
    "about_us_org_system_dev":"Систем хөгжүүлэлтийн алба",
    "about_us_org_system_implementation":"Систем нэвтрүүлэлтийн алба",
    "about_us_org_system_integration":"Системийн нэгтгэл хамтын ажиллагааны алба",
    "footer_content":"Монгол Улсын төрийн санхүүгийн салбарын мэдээллийн технологийн  тэргүүлэгч байх",


    "about_main_functions":"Байгууллагын үндсэн чиг үүрэг",
    "about_main_functions_development":"Программ хангамж хөгжүүлэлт",
    "about_main_functions_development_content":"Санхүү, татвар, гаалийн байгууллагуудын захиалга, бодлогын даалгаврын дагуу мэдээллийн систем, программ хангамж хөгжүүлэх, тэдгээртэй уялдуулах, мэдээлэл солилцох боломжийг бүрдүүлэх",
    "about_main_functions_collaboration":"Хамтын ажиллагаа",
    "about_main_functions_collaboration_content":"Засгийн газрын Хэрэг эрхлэх газраас ирүүлсэн ажлын даалгавар, албан хүсэлтэд тусгагдсан асуудалд хамаарах үйл ажиллагааг цахимжуулахад мэдээллийн технологийн дэмжлэг үзүүлэх, хамтран ажиллалах",
    "about_main_functions_security":"Мэдээллийн аюулгүй байдал",
    "about_main_functions_security_content":"Санхүү, татвар, гаалийн байгууллагын санхүүгийн мэдээллийн системийн үйл ажиллагаанд мэдээллийн технологийн дэвшил нэвтрүүлж, аюулгүй байдлыг хангах",
    "about_main_functions_database":"Өгөгдлийн сан",
    "about_main_functions_database_content":"Cанхүү, татвар, гаалийн байгууллагын өгөгдлийн сангийн үйл ажиллагаанд дэмжлэг үзүүлэх, мэдээллийн нэгдсэн сан үүсгэх, өгөгдөл боловсруулах, дүн шинжилгээ хийх",
    "about_main_functions_innovation":"Инноваци",
    "about_main_functions_innovation_content":"Хариуцсан мэдээллийн системийн сайжруулалт, нэвтрүүлэлт, тасралтгүй үйл ажиллагааг хангах, мэдээллийн технологийн инновацыг үйл ажиллагаандаа нэвтрүүлэх",
    "about_main_functions_research":"Судалгаа шинжилгээ",
    "about_main_functions_research_content":"Cанхүү, татвар, гаалийн үйл ажиллагааг цахимжуулах боломжийн талаар судалгаа, шинжилгээ хийх",
    "about_main_functions_coherence":"Системийн уялдаа",
    "about_main_functions_coherence_content":"Монгол Улсын Засгийн газраас баримталж буй бодлогын хүрээнд төрийн байгууллагуудын мэдээллийн технологийн хамтын ажиллагааг сайжруулж, цахим засаглалын үр ашгийг нэмэгдүүлэх",


    "employees":"Албан хаагчдын мэдээлэл",

    "more_view":"Дэлгэрэнгүй",

    "project_content":"Санхүүгийн мэдээллийн технологийн төв Улсын төсөвт үйлдвэрийн газар нь санхүү, татвар, гаалийн байгууллагын мэдээллийн нэгдсэн системийн хяналт, программ хангамжийн хөгжүүлэлтийг хариуцах замаар төсвийн орлого бүрдүүлэлт, татварын бааз суурийг нэмэгдүүлэх, төсвийн зарлагын хяналтыг сайжруулах, экспорт, импортын үйл ажиллагааг автоматжуулах, төрийн бусад байгууллагуудын үйл ажиллагаанд мэдээлэл технологийн дэмжлэг үзүүлэх зэрэг төрийн цахим шилжилтэд өргөн цар хүрээтэй ажлыг хэрэгжүүлж байна.",

    "iso_header":"МАБУТ олон улсын стандарт",
    "iso_content":"Мэдээллийн Аюулгүй Байдлын Менежментийн Тогтолцоо ISO/IEC 27001:2013 олон улсын стандартыг нэвтрүүлсэн анхны төрийн байгууллага",
    "iso_main_header":"Мэдээллийн Аюулгүй Байдлын Менежментийн Тогтолцооны олон улсын стандартад нийцсэн байгууллага",
    "iso_main_content_1":"Манай байгууллага гааль, татвар, санхүүгийн мэдээллийн нэгдсэн системийн хяналт, программ хангамжийн хөгжүүлэлтийг хариуцах замаар гааль, татвар, санхүүгийн албаны үйл ажиллагаанд мэдээллийн дэвшилтэт технологийг нэвтрүүлэх чиг үүргийнхээ хүрээнд Мэдээллийн аюулгүй байдлын удирдлагын тогтолцоог бий болгох, хэрэгжүүлэх, хадгалах, тасралтгүй сайжруулахад онцгой анхаардаг бөгөөд байгууллагын засаглал, чадавхын түвшнийг дэлхийн жишигт нийцүүлэн шат ахиулах бодлогыг баримтлан энэ чиглэлээр салбартаа түүчээлэн ажиллаж байна.",
    "iso_main_content_2":"Тус УҮГ нь хариуцан ажиллаж буй системүүдийн өгөгдөл мэдээлэл болон биет хөрөнгүүдийг хамруулан “Мэдээллийн Аюулгүй Байдлын Менежментийн Тогтолцоог 2021 онд амжилттай нэвтрүүлж олон улсад магадлан итгэмжлэгдсэн Их Британи, Умард Ирландын Нэгдсэн Хаант Улсын “Certiva Limited” болон “LMS Certification Pvt” Ltd байгууллагуудаар баталгаажуулах аудитыг хийлгэн ISO/IEC 27001:2013 стандартыг хангасан төрийн байгууллагууд дундаас анхны байгууллага болсон.",
    "iso_main_content_3":"Бид мэдээллийн аюулгүй байдлын тогтолцоогоо тасралтгүй сайжруулан ажиллаж байгаа бөгөөд стандартын шинэ хувилбар болох ISO/IEC 27001:2022 стандарт руу амжилттай шилжиж олон улсын баталгаажсан сертификатыг 2024 оны 6 дугаар сарын 24-ний өдөр гардан авсан.",
    "home_breadCrumb":"Нүүр хуудас",

    "budget_revenue":"Төсвийн орлого",
    "budget_revenue_content":"Төсвийн орлого, түүний бүтэц, бүрэлдэхүүн Үндэсний орлогыг хуваарилах, дахин хуваарилахтай холбогдсон эдийн засгийн харилцааны илрэл нь төсвийн орлого мөн.",
    "budget_expenditure":"Төсвийн зарлага",
    "budget_expenditure_content":"Төрөөс өөрийн үүргийг гүйцэтгэх зорилгоор төсөвт төвлөрүүлсэн хөрөнгийг зарцуулахтай холбогдон үүсч байгаа эдийн засгийн харилцааны илрэл нь төсвийн зарлага, санхүүжилт юм.",
    "budget_control":"Төсвийн хяналт",
    "budget_control_content":"Төсвийн хяналт нь төсвийн зорилго, зорилт өөрийн үндсэн зарчимд суурилан хэрэгжиж, өөрийн зорилго зорилтдоо хүрсэн эсэхийг үнэлэх үйл явц юм.",

    "home":"Нүүр хуудас",
    "home_req":"Хэрэглэгчийн систем нийлүүлэгчийн хүсэлт гаргах",
    "home_transparency":"Ил тод байдал",
    "home_transparency_activity":"Үйл ажиллагаа",
    "home_transparency_activity_plan":"Төлөвлөгөө",
    "home_transparency_activity_report":"Санал, хүсэлт шийдвэрлэлт",
    "home_transparency_human":"Хүний нөөц",
    "home_transparency_human_emp":"Албан хаагчдын мэдээлэл",
    "home_transparency_human_career":"Сул орон тооны мэдээлэл",
    "home_transparency_finance":"Төсөв санхүү",
    "home_transparency_finance_procure":"Тендер",
    "home_transparency_finance_audit":"Хяналт шалгалт",
    "home_address":"Хаяг байршил:",
    "home_address_item":"Улаанбаатар хот, Хан-уул дүүрэг,<br/> 15 дугаар хороо, Махатма ганди<br/> гудамж 31/1 байр, Стадион Оргил/17011/,<br/> NM Тауэр, 16 дугаар давхар",
    "home_contact":"Холбоо барих",
    "home_contact_1":"Архив, бичиг хэрэг",
    "home_contact_2":"Хүний нөөц",
    "home_contact_3":"Систем хяналт",
    "home_contact_email":"Цахим шуудан :",
    "home_contact_email_item":"info@itc.gov.mn",
    "home_time":"Цагийн хуваарь :",
    "home_time_1":"Даваа-Баасан",
    "home_time_2":"Бямба, нямд амарна.",
    "formatted_iso_image":"/images/human/butets.jpg",
    "formatted_iso_image_2":"/images/logo/newLogoMini.png",
    "home_login":"Нэвтрэх",

    "law":"Хууль",
    "rules":"Дүрэм, Журам, Тогтоол"
}

export default mn;