import {Badge, Button, Divider, Image, Space, Table, Tooltip} from "antd";
import { useEffect, useState } from "react";
import {ReloadOutlined} from "@ant-design/icons";
import {postService} from "../../tools";
import ProjectEdit from "./ProjectEdit";
import ProjectDelete from "./ProjetcDelete";
import {projectUrl} from "../../serviceUrls";
import ProjectAdd from "./ProjectAdd";
import ProjectOption from "./ProjectOption";
const Project = (props) => {
    const [project, setProject] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    useEffect(() => {
        getProject();
    }, []);

    const getProject = async () => {
        setLoadingTable(true);
        const body = {
            page: 0,
            size: 10,
        };
        postService(projectUrl.getProjectActive, body)
            .then((res) => {
                setProject(res.result);
            })
            .catch((err) => {
                console.log("err: ", err);
            })
            .finally(() => {
                setLoadingTable(false);
            });
    };

    const columns = [
        {
            title: <div>Нэр</div>,
            dataIndex: "title",
            key: "title",
            width: 220,
            ellipsis: true,
        },
        {
            title: <div>Дэлгэрэнгүй</div>,
            dataIndex: "description",
            key: "description",
            // width: 120,
            ellipsis: true,
        },
        {
            title: <div>Төлөв</div>,
            dataIndex: "type",
            key: "type",
            width: 120,
            sorter: (a, b) => a.type - b.type,
            render: (a) => (
                <div>
                    <Tooltip title={a === 1 ? "Идэвхтэй" : "Идэвхгүй"}>
                        <Badge
                            status={a === 1 ? "success" : "error"}
                            text={a === 1 ? "Идэвхтэй" : "Идэвхгүй"}
                            // style={{ color: "red" }}
                        />
                    </Tooltip>
                </div>
            ),
            ellipsis: true,
        },
    ];

    const data = project.map((e, i) => ({
        key: i,
        title: e.title,
        description: e.description,
        createdDateStr: e.createdDateStr,
        type: e.type,
        id: e.id,
        hdrs: e.projectHdrs,
    }));
    const expandedRowRender = (params) => {
        const columns = [
            {
                title: "Date",
                dataIndex: "createdDateStr",
                key: "createdDateStr",
                width: 140,
            },
            {
                title: "Нэр",
                dataIndex: "title",
                key: "title",
            },
            {
                title:"Гарчиг",
                dataIndex:"description",
                key:"description"
            },
            // {
            //   title: <div>Лого</div>,
            //   dataIndex: "image",
            //   key: "image",
            //   width: 80,
            //   render: (img) => (
            //     <div>
            //       <Image alt="ITC" preview={true} src={config.data.uploadUrl + img} />
            //     </div>
            //   ),
            //   ellipsis: true,
            // },
            {
                title: "Төлөв",
                key: "status",
                sorter: (a, b) => a.status - b.status,
                render: (a) => (
                    <Tooltip title={a.status === 1 ? "Идэвхтэй" : "Идэвхгүй"}>
                        <Badge
                            status={a.status === 1 ? "success" : "error"}
                            text={a.status === 1 ? "Идэвхтэй" : "Идэвхгүй"}
                        />
                    </Tooltip>
                ),
            },
            {
                title: "Үйлдэл",
                dataIndex: "operation",
                width: 120,
                key: "operation",
                render: (params) => (
                    <Space size="small">
                        <ProjectEdit data={params} getProject={getProject} />
                        {/* <ProjectDetal getProject={getProject} id={params.id} /> */}
                        <ProjectDelete getProject={getProject} id={params.id} />
                    </Space>
                ),
            },
        ];
        const data = params.hdrs
            ? params.hdrs.map((e, i) => ({
                key: i,
                title: e.title,
                description: e.description,
                createdDateStr: e.createdDateStr,
                image: e.image,
                type: e.type,
                status: e.status,
                id: e.id,
                operation: e,
            }))
            : [];
        return <Table bordered columns={columns} dataSource={data} pagination={false} />;
    };
    return (
        <div>
            <div className="items-center">
                <div className="flex justify-end gap-2">
                    <Button
                        onClick={getProject}
                        icon={<ReloadOutlined style={{ padding: "0px 5px" }} />}
                    ></Button>
                    <ProjectAdd />
                    <ProjectOption getProject={getProject} />
                </div>
            </div>
            <Table
                loading={loadingTable}
                columns={columns}
                bordered
                scroll={{ y: 450 }}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ["0"],
                }}
                dataSource={data}
            />
        </div>
    );
};
export default Project;
