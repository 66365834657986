import {Button, Popconfirm, message, notification} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState } from "react";
import {postService} from "../../tools";
import {publicityUrl} from "../../serviceUrls";

const TransparencyDelete = ({data}) => {
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const deleteFunc = async () => {
        setLoading(true);
        const body = {
            id: data.id,
        };
        try{
            await postService(publicityUrl.deletePublicity, body)
            api.success({
                message: 'Амжилттай',
                description: 'Амжилтай файл устгалаа.',
            });
        }catch(err){
            console.error("Error delete data: ", err);
        }finally {
            setLoading(false);
        }
    };
    return (
        <div>
            <Popconfirm
                title="Устгахдаа итгэлтэй байна уу?"
                okText={"Тийм"}
                okType={"default"}
                onConfirm={deleteFunc}
                cancelText="Үгүй"
            >
                <Button
                    size="small"
                    loading={loading}
                    icon={<DeleteOutlined className="text-red-500" />}
                ></Button>
            </Popconfirm>
        </div>
    );
};
export default TransparencyDelete;
