import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Form,
    Input,
    Modal,
    Select,
    Upload,
    message, Progress, Image,
} from "antd";
import {EditOutlined, MinusCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import {postService} from "../../tools";
import {fileUrl, newsUrl} from "../../serviceUrls";
const formItemLayoutBtn = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 26,
        offset: 4,
    },
};

const NewsEdit = ({ data, getNews }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [featuredNews, setFeaturedNews] = useState(false);
    const [disableForm, setDisableForm] = useState(false);
    const [description, setDescription] = useState('');
    const [fileListImg, setFileListImg] = useState([]);
    const [progressImg, setProgressImg] = useState(0);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImageName, setPreviewImageName] = useState(null);
    const [newsAddVideo, setNewsAddVideo] = useState([]);
    const [additionalDoc, setAdditionalDoc] = useState([]);
    const [hasAddional, setHasAddional] = useState(false);
    const [newsImages, setNewsImages] = useState([]);
    const [fileType, setFileType] = useState(null);
    const [imageName, setImageName] = useState("");

    const showModal = () => {
        setFeaturedNews(data.type === 2 ? true : false);
        setDescription(data.description);
        setFileList(data.image !== null ? [
            {
                uid: "-1",
                name: data.image,
                status: "done",
                url: "/api/itc-gov-service-new/file/view/"+ data.image,
            },
        ] : []);

        setFileListImg(data.newsImagesList);
        setNewsAddVideo(data.newsDocumentList ? data.newsDocumentList.filter((el) => el.isVideo === 1).map((el) => el.url) : []);
        setAdditionalDoc(data.newsDocumentList ? data.newsDocumentList.filter((el) => el.isVideo === 1) : data.newsDocumentList);
        setNewsImages(data.newsImagesList);
        setIsModalOpen(true);
        setImageName(data.image)
        console.log("data:",data)
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = (values) => {
        let additional = [];
        if(values.additional){
            values.addional[0].videos.forEach(el => (
                additional.push({
                    newsId: values.id,
                    isVideo: 1,
                    fileStoreId: getVideoId(el),
                    status: 1,
                    url: el,
                })
            ));
            additional.push(...additionalDoc, additional);
        }
        const body = {
            id: values.id,
            title: values.title,
            description: description,
            image:imageName,
            status: values.status,
            ytubeUrl: values.ytubeUrl ? values.ytubeUrl : 0,
            type: featuredNews ? 2 : 1,
            newsImagesList: newsImages,
            newsDocumentList:additionalDoc
        };
        setDisableForm(true);
        console.log("body::",body)
        postService(newsUrl.updateNews, body)
            .then((res) => {
                message.success("Амжилттай.");
                setIsModalOpen(false);
                console.log("111")
                getNews();
            })
            .catch((err) => {
                message.error("Амжилтгүй!");
            })
            .finally(() => {
                setDisableForm(false);
            });
    };

    const uploadFileSlideImg = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const fmData = new FormData();
        fmData.append("file", file);
        try{
            const res = await postService(fileUrl.upload, fmData);
            console.log("res::",res)
            newsImages.push({
                id: null,
                newsId:data.id ,
                fileStoreId: res.code,
                status: 1,
            });
            setNewsImages(newsImages);
            onSuccess("Ok");
            message.success("Амжилтай файл хууллаа");
        } catch(error){
            onError("Амжилтгүй файл хууллаа.")
            message.error("Амжилтгүй файл хууллаа.")
        }
    };
    const uploadFileImg = async (options) => {
        const { onSuccess, file, onError } = options;
        const fmData = new FormData();
        fmData.append("file", file);
        postService(fileUrl.upload, fmData).then((res)=>{
            setImageName(res.code);
        })
        onSuccess("Амжилттай")
        message.success("Амжилттай файл хууллаа.")
    };
    const handlePreview = (file) => {
        setFileType(file.type);
        setPreviewImageName(file);
        setPreviewVisible(true);
    };

    const deleteImage = (data) => {
        setNewsImages(newsImages.map(x => {
            if (x.id === data.id) {
                return { ...x, status: 0 };
            }
            return x;
        }));
    };


    const getVideoId = (url) => {
        let rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        if (url.match(rx)) {
            return url.match(rx)[1];
        }
        return null;
    }
    return (
        <div>
            <Button
                size="small"
                type="primary"
                onClick={showModal}
                className="bg-blue-500"
                icon={<EditOutlined />}
            ></Button>
            <Modal
                title="Мэдээ засах"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={1000}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 1000,
                    }}
                    disabled={disableForm}
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{
                        id: data.id,
                        title: data.title,
                        description: data.description,
                        image: data.image,
                        backgroundImage: data.backgroundImage,
                        status: data.status,
                        ytubeUrl: data.ytubeUrl,
                    }}
                >
                    <Form.Item label="ID" name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="Нүүр зураг"
                        name="imageName"
                        rules={[
                            {
                                required: fileList.length === 0,
                                message: "Зураг аа оруулна уу!",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.fileList.filter((el) => el.status === 'done' || el.status === 1).length !== 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Зураг оруулахад алдаа гарлаа, дахин оруулна уу!'));
                                },
                            }),
                        ]}
                    >
                        <Upload
                            style={{ display: "flex" }}
                            multiple={false}
                            accept="jpg"
                            customRequest={uploadFileImg}
                            onChange={({ fileList }) => setFileList(fileList)}
                            listType="picture-card"
                            fileList={fileList}
                            onRemove={null}
                            onPreview={handlePreview}
                        >
                            {fileList.length >= 1 ? null : "Зураг нэмэх"}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="Мэдээний зургууд"
                        name={"images"}
                        rules={[
                            {
                                required: fileListImg.length === 0,
                                message: "Зураг аа оруулна уу!",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.fileList.filter((el) => el.status === 'done' || el.status === 1).length !== 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Дор хаяж нэг зураг оруулна уу!'));
                                },
                            }),
                        ]}
                    >
                        <Upload
                            style={{ display: "flex" }}
                            multiple={false}
                            accept="jpg"
                            customRequest={uploadFileSlideImg}
                            onChange={({fileList}) => setFileListImg(fileList)}
                            listType="picture-card"
                            fileList={fileListImg}
                            onRemove={(file) => {
                                deleteImage(file);
                            }}
                            onPreview={handlePreview}
                        >
                            Зураг нэмэх
                        </Upload>
                        {progressImg > 0 ? <Progress percent={progressImg} /> : null}
                    </Form.Item>

                    <Form.Item
                        label="Гарчиг"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Гарчиг аа оруулна уу!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Дэлгэрэнгүй">
                        <div className="mt-4">
                            <Editor
                                apiKey={"wmksu0je4for1l2gfdhj8pu1422sb7wdsrrzukw95vl85d70"}
                                onEditorChange={(newValue, editor) => {
                                    setDescription(newValue);
                                }}
                                onInit={(evt, editor) => {}}
                                value={description}
                                init={{
                                    plugins:
                                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                    toolbar:
                                        "undo redo | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                }}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item label="Mэдээ" name="type">
                        <Checkbox
                            onChange={(e) => setFeaturedNews(e.target.checked)}
                            checked={featuredNews}
                        >
                            Онцлох мэдээ
                        </Checkbox>
                    </Form.Item>

                    <Form.Item
                        label="Төрөл"
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: "Төрөл өө оруулна уу!",
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: 120,
                            }}
                            options={[
                                {
                                    value: 1,
                                    label: "Идэвхгүй",
                                },
                                {
                                    value: 3,
                                    label: "Идэвхтэй",
                                },
                            ]}
                        />
                    </Form.Item>
                    {additionalDoc ? (
                        <Form.Item label="Видео:">
                            <Form.List
                                name={'videos'}
                                initialValue={newsAddVideo}>
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name}) => (
                                            <Form.Item
                                                key={key}
                                            >
                                                <Form.Item
                                                    name={[name]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Youtube холбоос оруулна уу.',
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="Бичлэгийн холбоос" style={{width: '60%',}}/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}
                                                                     className="dynamic-delete-button"/>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                style={{
                                                    width: '60%',
                                                }}
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Видео нэмэх
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    ) : (
                        <Form.List name="addional">
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields?.map((field, index) => (
                                        <Form.Item
                                            key={field.key}
                                            label="Видео:">
                                            <Form.List
                                                name={[index, 'videos']}>
                                                {(fields, {add, remove}) => (
                                                    <>
                                                        {fields.map(({key, name}) => (
                                                            <Form.Item
                                                                key={key}
                                                            >
                                                                <Form.Item
                                                                    name={[name]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Youtube холбоос оруулна уу.',
                                                                        },
                                                                    ]}
                                                                    noStyle
                                                                >
                                                                    <Input placeholder="Бичлэгийн холбоос" style={{width: '60%',}}/>
                                                                </Form.Item>
                                                                <MinusCircleOutlined onClick={() => remove(name)}
                                                                                     className="dynamic-delete-button"/>
                                                            </Form.Item>
                                                        ))}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                style={{
                                                                    width: '60%',
                                                                }}
                                                                onClick={() => add()}
                                                                block
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Видео нэмэх
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Form.Item>
                                    ))}
                                    <Form.Item {...formItemLayoutBtn}>
                                        <Button
                                            danger={hasAddional}
                                            type="dashed"
                                            onClick={() => {
                                                if (!hasAddional) {
                                                    add();
                                                    setHasAddional(true);
                                                } else {
                                                    remove(0);
                                                    setHasAddional(false);
                                                }
                                            }}
                                            style={{
                                                width: "100%",
                                            }}
                                            icon={hasAddional ? <MinusOutlined/> : <PlusOutlined/>}
                                        >
                                            {hasAddional ? "Нэмэлт мэдээлэл хасах" : "Нэмэлт мэдээлэл нэмэх"}
                                        </Button>
                                        <Form.ErrorList errors={errors}/>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    )}

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                            span: 20,
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-blue-500"
                            size="large"
                        >
                            Шинэчлэх
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default NewsEdit;
