import React, { useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    Progress,
    Select,
    Upload,
    message, Checkbox, Image
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import {MinusCircleOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {postService} from "../../tools";
import {fileUrl, newsUrl} from "../../serviceUrls";

const formItemLayoutBtn = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 26,
        offset: 4,
    },
};

const NewsAdd = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageName, setImageName] = useState("");
    const [formDisable, setFormDisable] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileListImg, setFileListImg] = useState([]);
    const [fileListAdd, setFileListAdd] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressImg, setProgressImg] = useState(0);
    const [progressFile, setProgressFile] = useState(0);
    const [form] = Form.useForm();
    const [valueDoc, setValue] = useState([{ data: "" }]);
    const [requirement, setRequirement] = useState([{ data: "" }]);
    const [description, setDescription] = useState("");
    const [newsImages, setNewsImages] = useState([]);
    const [featuredNews, setFeaturedNews] = useState(false);
    const [additionalDoc, setAdditionalDoc] = useState([]);
    const [hasAddional, setHasAddional] = useState(false);
    const [previewImageName, setPreviewImageName] = useState(null);

    const showModal = () => {
        setNewsImages([]);
        setDescription("");
        setFileList([]);
        setFileListImg([]);
        setFileListAdd([]);
        setAdditionalDoc([]);
        setIsModalOpen(true);
        setHasAddional(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setRequirement([]);
        setValue([]);
        form.resetFields();
        setIsModalOpen(false);
        setDescription("");
    };

    const onFinish = (values) => {
        let additional = [];
        if (hasAddional) {
            values.addional[0].videos.forEach(el => (
                additional.push({
                    id:null,
                    newsId: null,
                    isVideo: 1,
                    fileStoreId: getVideoId(el),
                    status: 1,
                    url: el,
                })
            ));
            additional.push(...additionalDoc);
        }
        const body = {
            id:null,
            title: values.title,
            description: description,
            image: imageName,
            status: values.status,
            type: featuredNews ? 2 : 1,
            newsDocumentList: additional,
            newsImagesList: newsImages,
        };
        setFormDisable(true);
        postService(newsUrl.saveNews, body)
            .then((res) => {
                message.success(res.data);
                setIsModalOpen(false);
                props.getNews();
                form.resetFields();
            })
            .catch((err) => {
                message.success("error");
            })
            .finally(() => {
                setFormDisable(false);
            });
    };

    const uploadFileImg = async (options) => {
        const { onSuccess, onProgress, file } = options;
        const MAX_FILE_SIZE = 1000000;

        console.log("options::", options);
        console.log("file:::", file);
        console.log("size:::", file.size);

        if (file.size > MAX_FILE_SIZE) {
            message.warning("Зураг хэмжээ их байна.")
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        console.log("Form data:", formData);

        try {
            postService(fileUrl.upload, formData).then(res => {
                if (!res) {
                    console.error("Error: Response is undefined or failed");
                    return;
                }
                setImageName(res.code);
                onProgress({ percent: 100 });
                onSuccess("ok");
            });
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };

    const uploadFileNewsImages = async (options) => {
        const { onSuccess, onProgress, file } = options;
        const formData = new FormData();
        formData.append("file", file);
        try {
            const res = await postService(fileUrl.upload, formData);
            const newsImageList = {
                status: 1,
                fileStoreId : res.code
            }
            setNewsImages((prevImages) => [...prevImages, newsImageList]);
            onProgress({ percent: 100 });
            onSuccess("ok");
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };

    const deleteImage = (data) => {
        if (data.url) {
            const imageName = data.url.split('=')[1];
            setNewsImages(newsImages.filter((x) => x.fileStoreId !== imageName));
        }
    };

    const getVideoId = (url) => {
        let rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        if (url.match(rx)) {
            return url.match(rx)[1];
        }
        return null;
    }

    const handlePreview = (file) => {
        setPreviewImageName(file.url);
        setPreviewVisible(true);
    }

    console.log("imageName:::",imageName)

    return (
        <div className="w-full flex justify-end mb-2">
            <Button type="primary" onClick={showModal} className="bg-blue-500">
                Мэдээ нэмэх +
            </Button>
            <Modal
                title="Мэдээ нэмэх"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={1000}
            >
                <Form
                    form={form}
                    name="basic"
                    labelAlign=" "
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    style={{
                        maxWidth: 1000,
                    }}
                    initialValues={{
                        status: 1,
                        ytubeUrl: "0",
                    }}
                    layout="horizontal"
                    onFinish={onFinish}
                    autoComplete="off"
                    disabled={formDisable}
                >
                    <Modal
                        open={previewVisible}
                        title={"Зураг"}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                    >
                        <Image
                            alt="aaa"
                            width={"100%"}
                            height={"100%"}
                            src={`/api/itc-gov-service-new/file/view/` +  previewImageName}
                        />
                    </Modal>

                    <Form.Item
                        label="Нүүр зураг"
                        name="img2"
                        rules={[
                            {
                                required: true,
                                message: "Зургаа оруулна уу!",
                            },

                            {
                                validator(_, value) {
                                    const MAX_FILE_SIZE = 5 * 1024 * 1024;
                                    if (value && value.fileList) {
                                        const file = value.fileList[0];
                                        if (file && file.size > MAX_FILE_SIZE) {
                                            return Promise.reject(new Error('Зураг хэт том байна. Та 5MB-ээс бага хэмжээтэй зураг оруулна уу.'));
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.fileList.filter((el) => el.status === 'done').length !== 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Зураг оруулахад алдаа гарлаа, дахин оруулна уу!'));
                                },
                            }),
                        ]}
                    >
                        <Upload
                            style={{ display: "flex" }}
                            multiple={false}
                            accept="jpg"
                            customRequest={uploadFileImg}
                            onChange={({ fileList }) => setFileList(fileList)}
                            listType="picture-card"
                            fileList={fileList}
                            onRemove={null}
                            onPreview={handlePreview}
                        >
                            {fileList.length >= 1 ? null : "Зураг нэмэх"}
                        </Upload>
                        {progress > 0 ? <Progress percent={progress} /> : null}
                    </Form.Item>

                    <Form.Item
                        label="Мэдээний зураг"
                        name={"img3"}
                        rules={[
                            {
                                required: fileListImg.length === 0,
                                message: "Зураг аа оруулна уу!",
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || value.fileList.filter((el) => el.status === 'done').length !== 0) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Дор хаяж нэг зураг оруулна уу!'));
                                },
                            }),
                        ]}
                    >
                        <Upload
                            style={{ display: "flex" }}
                            multiple={false}
                            accept="jpg"
                            customRequest={uploadFileNewsImages}
                            onChange={({ fileList }) =>
                                setFileListImg(fileList)
                            }
                            listType="picture-card"
                            fileList={fileListImg}
                            onRemove={(file) => {
                                deleteImage(file);
                            }}
                            onPreview={handlePreview}
                        >
                            Зураг нэмэх
                        </Upload>
                        {progressImg > 0 ? <Progress percent={progressImg} /> : null}
                    </Form.Item>

                    <Form.Item
                        label="Гарчиг"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "Гарчиг аа оруулна уу!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Дэлгэрэнгүй" name="purposeDescription">
                        <div className="mt-4">
                            <Editor
                                apiKey="wmksu0je4for1l2gfdhj8pu1422sb7wdsrrzukw95vl85d70"
                                onEditorChange={(newValue, editor) => {
                                    setDescription(newValue);
                                }}
                                onInit={(evt, editor) => {}}
                                value={description}
                                init={{
                                    plugins:
                                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                    toolbar:
                                        "undo redo | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                }}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item label="Mэдээ" name="type">
                        <Checkbox
                            onChange={(e) => setFeaturedNews(e.target.checked)}
                            checked={featuredNews}
                        >
                            Онцлох мэдээ
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="Төрөл"
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: "Төрөл өө оруулна уу!",
                            },
                        ]}
                    >
                        <Select
                            initialvalue={1}
                            style={{
                                width: 120,
                            }}
                            options={[
                                {
                                    value: 1,
                                    label: "Идэвхгүй",
                                },
                                {
                                    value: 3,
                                    label: "Идэвхтэй",
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.List name="addional">
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields?.map((field, index) => (
                                    <Form.Item
                                        key={field.key}
                                        label="Нэмэлт файлууд">
                                        <Form.List
                                            name={[index, 'videos']}>
                                            {(fields, {add, remove}) => (
                                                <>
                                                    {fields.map(({key, name}) => (
                                                        <Form.Item
                                                            key={key}
                                                        >
                                                            <Form.Item
                                                                name={[name]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Youtube холбоос оруулна уу.',
                                                                    },
                                                                ]}
                                                                noStyle
                                                            >
                                                                <Input placeholder="Бичлэгийн холбоос" style={{width: '60%',}}/>
                                                            </Form.Item>
                                                            <MinusCircleOutlined onClick={() => remove(name)}
                                                                                 className="dynamic-delete-button"/>
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item>
                                                        <Button type="dashed"
                                                                style={{
                                                                    width: '60%',
                                                                }}
                                                                onClick={() => add()}
                                                                block
                                                                icon={<PlusOutlined/>}>
                                                            Видео нэмэх
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>
                                ))}
                                <Form.Item {...formItemLayoutBtn}>
                                    <Button
                                        danger={hasAddional}
                                        type="dashed"
                                        onClick={() => {
                                            if (!hasAddional) {
                                                add();
                                                setHasAddional(true);
                                            } else {
                                                remove(0);
                                                setHasAddional(false);
                                            }
                                        }}
                                        style={{
                                            width: "100%",
                                        }}
                                        icon={hasAddional ? <MinusOutlined/> : <PlusOutlined/>}
                                    >
                                        {hasAddional ? "Нэмэлт мэдээлэл хасах" : "Нэмэлт мэдээлэл нэмэх"}
                                    </Button>
                                    <Form.ErrorList errors={errors}/>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    <Form.Item
                        wrapperCol={{
                            offset: 4,
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-blue-500"
                            size="large"
                        >
                            Хадгалах
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default NewsAdd;
