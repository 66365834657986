import Title from "./Title";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";

const Contact = () => {
  return (
    <div>
      <div className="mt-4 max-w-[1280px] mx-auto">
        <Title name={"home_contact"} />
        <div className="grid grid-cols-9 border-t text-brand-50 text-base p-6 gap-6 max-md:grid-cols-1 bg-white max-md:px-6">
          <div className=" col-span-5 max-md:col-span-1 grid grid-cols-1 gap-4 pl-6 max-md:border-t max-md:border-l-0 max-md:pt-6 max-md:pl-0">
            <div className="flex gap-10">
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <svg
                    className="mt-[7px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                      fill="#072E5C"
                    />
                  </svg>
                  <div className="text-md"><FormattedMessage id="home_contact_1"/></div>
                </div>
                <div className="flex gap-2">
                  <svg
                    className="mt-[7px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                      fill="#072E5C"
                    />
                  </svg>
                  <div className="text-md"><FormattedMessage id="home_contact_2"/></div>
                </div>
                <div className="flex gap-2">
                  <svg
                    className="mt-[7px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                      fill="#072E5C"
                    />
                  </svg>
                  <div className="text-md"><FormattedMessage id="home_contact_3"/></div>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-md">7570-1112</div>
                <div className="font-semibold text-md">7507-7333</div>
                <div className="font-semibold text-md">7570-1234</div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 font-semibold leading-5">
                <div className="mt-[3px]">
                  <svg
                    width="14px"
                    height="14px"
                    viewBox="0 0 64 64"
                    enableBackground="new 0 0 64 64"
                  >
                    <path
                      fill="#072E5C"
                      d="M32,0C18.746,0,8,10.746,8,24c0,5.219,1.711,10.008,4.555,13.93c0.051,0.094,0.059,0.199,0.117,0.289l16,24 C29.414,63.332,30.664,64,32,64s2.586-0.668,3.328-1.781l16-24c0.059-0.09,0.066-0.195,0.117-0.289C54.289,34.008,56,29.219,56,24 C56,10.746,45.254,0,32,0z M32,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S36.418,32,32,32z"
                    />
                  </svg>
                </div>
                <FormattedHTMLMessage id="home_address_item"/>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex font-semibold gap-2 ">
                <div className="mt-[6px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                    width="14px"
                    height="14px"
                    viewBox="0 0 1024 1024"
                    className="icon"
                  >
                    <path
                      fill="#072E5C"
                      d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-80.8 108.9L531.7 514.4c-7.8 6.1-18.7 6.1-26.5 0L189.6 268.9A7.2 7.2 0 0 1 194 256h648.8a7.2 7.2 0 0 1 4.4 12.9z"
                    />
                  </svg>
                </div>
                <div>info@itc.gov.mn</div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 h-0 col-span-2  max-md:h-full">
            <FormattedMessage id="home_time"/>
            <div className="font-semibold ">
              <FormattedMessage id="home_time_1"/>
              <div className="text-2xl">08:30-17:30</div>
            </div>
            <div className="font-semibold"><FormattedMessage id="home_time_2"/></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
