import { Collapse, Empty, notification } from "antd";

import { PhoneOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import Title from "./Title";
import Arrow from "../components/assist/arrow/Arrow";
import { jobadsUrl } from "../serviceUrls";
import { getServicePublic } from "../tools";
import { useParams } from "react-router-dom";
import keycloak from "../../keycloack";

const JobIds = () => {
  const [post, setPost] = useState();
  const [contextHolder] = notification.useNotification();

  const params = useParams();

  const getJobAdsDtlDoc = () => {
    getServicePublic(jobadsUrl.getJobAdsDtlDoc, { id: params.id }).then(
      (res) => {
        setPost(res);
      }
    );
  };

  useEffect(() => {
    getJobAdsDtlDoc();
  }, []);

  return (
    <div>
      <SimpleMotion>
        <div className="max-w-[1280px] mx-auto p-4">
          {post ? (
            <div className="flex gap-4 max-sm:flex-col">
              <div className="bg-white w-3/4 pb-20 relative max-sm:w-full">
                <div className="flex items-center justify-between max-sm:flex-col max-sm:mb-3">
                  <Title name={post[0].title} />
                  <div
                    className={
                      "max-sm:flex max-sm:justify-end max-sm:w-full max-sm:relative max-sm:-top-3"
                    }
                  >
                    <div className="bg-brand-50 px-3 py-2 text-gray-100 flex items-center text-sm w-36">
                      <div className="flex items-center pr-2">
                        <ClockCircleOutlined />
                      </div>
                      <div className="flex items-center">
                        {post[0].createdDateStr}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="px-9 text-justify leading-5">
                  {post[0].description}
                </div> */}
                {contextHolder}
                <div
                  className="px-9  text-brand-50 text-sm"
                  dangerouslySetInnerHTML={{
                    __html: post[0].jobPurposeDescription,
                  }}
                ></div>
                <div className="px-9 mt-10">
                  <Collapse
                    defaultActiveKey={0}
                    style={{
                      background: "#fff",
                      borderRadius: "0px",
                      borderRight: "0px",
                      borderLeft: "0px",
                      borderTop: "1px solid #ededed",
                      borderBottomLeftRadius: "0px",
                    }}
                    expandIconPosition="start"
                    items={post.map((e, i) => ({
                      key: i,
                      label: (
                        <div>
                          <div className="text-lg font-semibold text-brand-50">
                            {e.documentTitle}
                          </div>
                        </div>
                      ),
                      children: (
                        <div>
                          <div
                            className="name text-brand-50 px-2"
                            dangerouslySetInnerHTML={{ __html: e.document }}
                          ></div>
                        </div>
                      ),
                    }))}
                    // defaultActiveKey={["1"]}
                  />
                </div>
                <div className="w-[161px] absolute bottom-6 right-10 max-sm:right-0">
                  <div
                    href={"/"}
                    onClick={() => {
                      keycloak.login();
                    }}
                    className="group border-2   cursor-pointer   border-white text-white mx-1 font-medium   flex justify-center items-center duration-300 hover:border-brand-50"
                  >
                    <span className="py-2 bg-brand-50 px-3 border border-white group-hover:border-white flex gap-4">
                      <div className="group-hover:translate-x-2 duration-300">
                        <Arrow name={"white"} />
                      </div>
                      Анкет илгээх
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-1/4 max-sm:w-full">
                <div className="bg-white">
                  <div className="w-full p-6 text-brand-50 font-semibold text-sm flex items-center justify-between">
                    <div className="font-semibold text-xl ">Бусад</div>
                    <div className="text-sm text-brand-50">
                      {post[0].createdDateStr}
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-2 px-6 pb-6 text-brand-50 text-sm font-medium">
                      <div className="flex flex-col font-semibold">
                        <div>Байршил</div>
                        <div className="font-medium">
                          Улаанбаатар хот, Чингэлтэй дүүрэг
                        </div>
                      </div>
                      <div className="flex flex-col font-semibold">
                        <div>Түвшин</div>
                        <div className="font-medium">Мэргэжилтэн</div>
                      </div>
                      <div className="flex flex-col font-semibold">
                        <div>Төрөл</div>
                        <div className="font-medium">Бүтэн цагийн</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-brand-50 mt-4 grid grid-cols-2 text-white p-2">
                  <div className="w-full bg-brand-50 p-6 text-white font-medium text-sm flex items-center justify-center border-r">
                    <div className="font-medium text-5xl">
                      <PhoneOutlined rotate={95} />
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col gap-2 p-6  font-medium">
                      <div className="flex flex-col text-sm font-medium">
                        <div>Холбоо барих:</div>
                        <div className="text-sm font-medium text-gray-200">
                          7012-1033
                        </div>
                      </div>
                      <div className="flex flex-col text-sm font-medium">
                        <div>имэйл хаяг:</div>
                        <div className="text-sm font-medium text-gray-200">
                          hr@itc.gov.mn
                        </div>
                      </div>
                      <div className="flex flex-col text-sm font-medium">
                        <div>Цагийн хуваарь</div>
                        <div className="text-sm font-medium text-gray-200">
                          08:30-17:30
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </SimpleMotion>
    </div>
  );
};

export default JobIds;
