import React, { useState } from "react";
import { Button, Form, Input, Modal, Select, Upload, message, InputNumber } from "antd";
import axios from "axios";
import TeamHeadAdd from "./TeamHeadAdd";
import {postService} from "../../tools";
import {devUrl} from "../../serviceUrls";

const TeamMemberAdd = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formDisable, setFormDisable] = useState(false);
    const [devHdrData, setDevHdrData] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoad] = useState(false);
    const [form] = Form.useForm();
    const showModal = () => {
        setFileList([]);
        getDevHdr();
        setIsModalOpen(true);
    };
    const getDevHdr = async () => {
        setLoad(true);
        const body = {
            page: 0,
            size: 4,
        };
        postService(devUrl.getTeamHead, body)
            .then((res) => {
                if (res.code === 1) {
                    setDevHdrData(res.result);
                }
            })
            .catch((err) => {
                console.log("err: ", err);
            })
            .finally(() => {
                setLoad(false);
            });
    };
    const handleOk = () => {
        form.resetFields();
        setIsModalOpen(false);
        setFileList([]);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
        setFileList([]);
    };

    const onFinish = (values) => {
        const body = {
            devHdrId: values.devHdrId,
            fullName: values.fullName,
            jobPosition: values.jobPosition,
            email: values.email,
            phone: values.phone,
            viewOrder: values.viewOrder,
            type: 0,
        };
        setFormDisable(true);
            postService(devUrl.saveTeamMember, body)
            .then((res) => {
                message.success("Амжилттай");
                setIsModalOpen(false);
                props.getDeveloper();
            })
            .catch((err) => {
                message.success("Алдаа гарлаа");
                console.log("err: ", err);
            })
            .finally(() => {
                setFormDisable(false);
                form.resetFields();
            });
    };
    const onFinishFailed = () => {
        message.error("Алдаа гарлаа")
    };

    return (
        <div>
            <Button type="primary" onClick={showModal} className="bg-blue-500">
                Хөгжүүлэгч +
            </Button>
            <Modal
                title="Хөгжүүлэгч нэмэх"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={form}
                    name="basic"
                    labelAlign="left"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    style={{
                        maxWidth: 700,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    disabled={formDisable}
                >
                    <Form.Item
                        label="Алба"
                        name="devHdrId"
                        rules={[
                            {
                                required: true,
                                message: "Төслийн нэр ээ оруулна уу!",
                            },
                        ]}
                        key={11}
                        className="-mb-3 "
                    >
                        <div className="flex items-center">
                            <Form.Item
                                name="devHdrId"
                                rules={[
                                    {
                                        required: true,
                                        message: "Төслийн нэр ээ оруулна уу!",
                                    },
                                ]}
                                key={222}
                                className="w-full"
                            >
                                <Select
                                    className="w-full"
                                    disabled={loading}
                                    loading={loading}
                                    options={
                                        devHdrData.length > 0
                                            ? devHdrData.map((e, i) => ({
                                                value: e.id,
                                                label: e.title,
                                            }))
                                            : []
                                    }
                                />
                            </Form.Item>
                            <Form.Item key={22332}>
                                <TeamHeadAdd getDeveloper={props.getDeveloper} getDevHdr={getDevHdr} />
                            </Form.Item>
                        </div>
                    </Form.Item>

                    <Form.Item
                        label="Овог нэр"
                        name="fullName"
                        rules={[
                            {
                                required: true,
                                message: "Гарчиг аа оруулна уу!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Албан тушаал"
                        name="jobPosition"
                        rules={[
                            {
                                required: true,
                                message: "Албан тушаал аа оруулна уу!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Цахим шуудан" name="email">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Утасны дугаар" name="phone">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Дугаарлалт" name="viewOrder">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 20,
                            span: 20,
                        }}
                    >
                        <Button type="primary" htmlType="submit" className="bg-blue-500">
                            Хадгалах
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default TeamMemberAdd;
