import { useContext, useEffect, useState } from "react";
import SimpleMotion from "../../components/assist/simpleMotion/SimpleMotion";
import ProjectComp from "../ProjectComp";
import ProjectHeader from "./ProjectHeader";
import { Link, useLocation, useParams } from "react-router-dom";
import { Carousel, Col, Collapse, Empty, Modal, Row, Image, Spin } from "antd";
import Title from "../Title";
import TimeCounterComp from "../../support/TimeCounterComp";
import CustomWindow from "../../support/CustomWindow";
import { getServicePublic } from "../../tools";
import { projectUrl } from "../../serviceUrls";
import ManComp from "../../support/ManComp";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import RouterContext from "../routerContext";

const ProjectDetail = () => {
  const { setRouterCont } = useContext(RouterContext);
  const params = useParams();
  const [winWidth, setWinWidth] = useState(1280);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(null);
  const [post, setPost] = useState();
  const [chartData, setChartData] = useState();
  const [statData, setStatData] = useState();
  const [stat, setStat] = useState();
  const pathname = useLocation();
  const yearList = [];
  for (let i = 2016; i <= new Date().getFullYear(); i++) {
    yearList.push(i + " он");
  }
  const getData = () => {
    setLoading(true);
    getServicePublic(projectUrl.getProjectDetailDocument, {
      id: params.ProjectDtl,
    }).then((res1) => {
      setRouterCont({
        id: params.ProjectDtl,
        title: res1[0]?.projectTitle,
      });
      setPost(res1.length > 0 ? res1 : null);
    });
    getServicePublic(projectUrl.getStatRate).then((res2) => {
      setStatData(res2);
    });
    getServicePublic(projectUrl.getChartData).then((res3) => {
      setChartData(res3);
    });
    getServicePublic(projectUrl.getStatData)
      .then((res4) => {
        setStat(res4);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, [params.ProjectDtl]);

  const renderImage = (element) => {
    if (element.isCarousel && !element.span) {
      return (
        <Carousel autoplay>
          {element.projectDocumentFiles.map((data, i) => (
            <div key={i}>
              <img
                src={"/api/v1/getFile?file=" + data.fileStoreId}
                height="100%"
                width="100%"
              />
            </div>
          ))}
        </Carousel>
      );
    }
    if (!element.isCarousel && element.span) {
      return (
        <Row gutter={[15, 15]}>
          {element.projectDocumentFiles.map((data, i) => (
            <Col key={i} span={element.span}>
              <img
                src={"/api/v1/getFile?file=" + data.fileStoreId}
                height="100%"
                width="100%"
              />
            </Col>
          ))}
        </Row>
      );
    }
    return null;
  };

  const renderChart = (element) => {
    const chartOptions =
      winWidth >= 640
        ? {
            chart: {
              type: "column",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: yearList,
              lineWidth: 1,
              lineColor: "#D9D9D9",
              labels: {
                style: {
                  fontSize: 16,
                  color: "#031842",
                },
              },
            },
            yAxis: {
              visible: false,
              max: 1208023328,
            },
            plotOptions: {
              column: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return Math.round(this.y / 1000000) + " сая";
                  },
                  style: {
                    fontSize: 16,
                    color: "#031842",
                  },
                },
                enableMouseTracking: false,
                animation: {
                  enabled: true,
                  duration: 5000,
                  easing: "linear",
                },
              },
            },
            series: [
              {
                showInLegend: false,
                data: chartData?.map((x) => x.receiptCnt),
                color: "#D9D9D9",
                pointWidth: 30,
              },
            ],
            credits: {
              enabled: false,
            },
          }
        : {
            chart: {
              type: "bar",
            },
            title: {
              text: "",
            },
            xAxis: {
              categories: yearList,
              lineWidth: 1,
              lineColor: "#D9D9D9",
              labels: {
                style: {
                  fontSize: 12,
                  color: "#031842",
                },
              },
            },
            yAxis: {
              visible: false,
              max: 1408023328,
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                    return Math.round(this.y / 1000000) + " сая";
                  },
                  style: {
                    fontSize: 12,
                    color: "#031842",
                  },
                },
                enableMouseTracking: false,
                animation: {
                  enabled: true,
                  duration: 5000,
                  easing: "linear",
                },
              },
            },
            series: [
              {
                showInLegend: false,
                data: chartData.map((x) => x.receiptCnt),
                color: "#D9D9D9",
                pointWidth: 10,
              },
            ],
            credits: {
              enabled: false,
            },
          };
    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  };

  return (
    <SimpleMotion>
      <Spin spinning={loading}>
        {post ? (
          <div className="text-brand-50 fade-in">
            <Modal
              centered
              open={modalOpen}
              onOk={() => setModalOpen(false)}
              onCancel={() => setModalOpen(false)}
              footer={null}
              width={1280}
            >
              <div className={"relative z-50 -mx-6 -my-5"}>
                <div
                  className={
                    "bg-brand-50 text-white text-3xl flex items-center font-semibold justify-center h-fit py-3 px-12"
                  }
                >
                  <div>НӨАТ-ын буцаан олголт, сугалааны урамшуулал</div>
                </div>
                <div className="flex max-md:flex-col bg-white text-brand-50">
                  <div className="w-1/3 max-md:w-full  max-sm:hidden">
                    <img
                      className={"max-md:max-h-[300px]"}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                      }}
                      src={"/images/background/office.jpg"}
                      alt="ITC GOV"
                      width={1400}
                      height={1000}
                    />
                  </div>
                  <div className="w-2/3 max-md:w-full">
                    <Title
                      name={"Нэмэгдсэн өртгийн албан татварыг буцаан олгох"}
                      size={true}
                    />
                    <div className="px-9 pb-5">
                      <strong>Үүнд:</strong>
                      <div className="flex mt-4">
                        <img
                          className="h-fit mx-3 mt-2"
                          src={"/images/icon/check.png"}
                          alt="ITC GOV"
                          width={12}
                          height={7}
                        />
                        <p>
                          Албан татвар суутган төлөгчид НӨАТ-ын 20% хүртэл
                          буцаан олгоно
                        </p>
                      </div>
                      <div className="flex mt-4">
                        <img
                          className="h-fit mx-3 mt-2"
                          src={"/images/icon/check.png"}
                          alt="ITC GOV"
                          width={12}
                          height={7}
                        />
                        <p>
                          Дипломат төлөөлөгчийн болон консулын газрын албан
                          ажлын болон тэдгээрт ажиллагсдын хувийн хэрэгцээнд
                          зориулж Монгол Улсын нутаг дэвсгэрт худалдан авсан
                          бараа, ажил, үйлчилгээнд төлсөн албан татварыг буцаан
                          олгоно.
                        </p>
                      </div>
                      <div className="flex mt-4">
                        <img
                          className="h-fit mx-3 mt-2"
                          src={"/images/icon/check.png"}
                          alt="ITC GOV"
                          width={12}
                          height={7}
                        />
                        <p>
                          Гадаадын жуулчны гаалийн нутаг дэвсгэрээс худалдан
                          авсан бараанд төлсөн албан татварыг 100% буцаан
                          олгоно.
                        </p>
                      </div>
                      <div className="flex mt-4">
                        <img
                          className="h-fit mx-3 mt-2"
                          src={"/images/icon/check.png"}
                          alt="ITC GOV"
                          width={12}
                          height={7}
                        />
                        <p>
                          Төлбөрийн баримтын сугалааны тохирлоор сар бүр
                          30,000-30,000,000 төгрөг хүртэлх урамшууллыг олгоно.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            <SimpleMotion>
              {post[0].hdrId === 167 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-0">
                    <div className="flex max-md:flex-col-reverse bg-brand-50">
                      <div className="w-2/3 max-md:w-full">
                        <Title
                          name={
                            post[0].hdrDescription +
                            " - " +
                            post[0].projectTitle
                          }
                          color={"brand-50"}
                        />
                        <div className="px-9 text-justify pb-5">
                          <div
                            className="text-white"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                          <div
                            className={
                              "text-gray-400 underline hover:text-brand-100 italic py-3 hover:text-white hover:underline-offset-5"
                            }
                            onClick={() => setModalOpen(true)}
                          >
                            Дэлгэрэнгүй
                          </div>
                        </div>
                        <Link
                          href="https://ebarimt.mn/"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            www.ebarimt.mn
                          </div>
                        </Link>
                      </div>

                      <div className="w-1/3 max-md:w-full">
                        <img
                          className={"max-md:max-h-[300px]"}
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/background/office.jpg"}
                          alt="ITC GOV"
                          width={1400}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex flex-col bg-white">
                      <Title name={"Тоон үзүүлэлт"} />
                      <div className="border-l-8 border-brand-50 bg-[#F0F3FA] text-brand-50 px-3 py-3 w-1/2 max-sm:w-2/3 mx-9 text-2xl flex items-center h-fit">
                        2016-2024 онд нийт хэвлэгдсэн баримтын тоо
                      </div>
                      <div className="px-14 max-sm:px-4 py-4">
                        {renderChart()}
                      </div>
                    </div>
                  </div>

                  <TimeCounterComp statData={statData} />

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div
                      className={
                        "grid grid-cols-3 max-md:grid-cols-1 h-fit divide-x-2"
                      }
                    >
                      <CustomWindow
                        title={"Иргэн"}
                        value={
                          "Цахим төлбөрийн баримтын системд <a href='https://ebarimt.mn/' style='text-decoration-line: underline;'>ebarimt.mn</a> веб хаягаар болон ebarimt аппликейшнаар нэвтрэн худалдан авалтын НӨАТ-ын баримтаа бүртгүүлэх, төрийн үйлчилгээг хялбараар авах боломжтой."
                        }
                        router={`${pathname}/1`}
                        imageUrl={"/images/pic/pic10.png"}
                        urlText={"Үргэлжлүүлэх"}
                      />
                      <CustomWindow
                        title={"Аж ахуйн нэгж"}
                        value={
                          "Цахим төлбөрийн баримтын системд татвар төлөгч аж ахуйн нэгжийн авах үйлчилгээг хялбаршуулсан."
                        }
                        router={`${pathname}/2`}
                        imageUrl={"/images/pic/pic9.png"}
                        urlText={"Үргэлжлүүлэх"}
                      />
                      <CustomWindow
                        title={"Гадаад иргэн"}
                        value={
                          "Татварын ерөнхий хуульд зааснаар гадаадын иргэнийг татвар төлөгчөөр бүртгэх, дугаар олгох, мөн НӨАТ-ын тухай хуульд зааснаар Гадаад улсаас Монгол Улсад аялж байгаа гадаадын иргэний хувийн хэрэгцээнд зориулж гаалийн нутаг дэвсгэрээс худалдан авсан бараанд төлсөн албан татварыг буцаан олгох асуудлыг цахимжуулсан."
                        }
                        router={`${pathname}/3`}
                        imageUrl={"/images/pic/pic1.png"}
                        urlText={"Үргэлжлүүлэх"}
                      />
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <ManComp values={stat} />
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div className={"flex max-md:flex-col-reverse h-fit"}>
                      <div className="w-1/2 max-md:w-full max-xl:h-fit">
                        <div
                          className={
                            "h-[76px] flex items-center justify-between px-9"
                          }
                        >
                          <div>
                            <img
                              className="h-fit mt-2"
                              src={"/images/brand/eBarimt_v_1.png"}
                              alt="ITC GOV"
                              width={58}
                              height={47}
                            />
                          </div>
                          <div className={"text-base flex font-black h-6"}>
                            <div className={"text-brand-50 text-center px-3"}>
                              Хувилбар
                            </div>
                            <div
                              className={
                                "bg-[#0F218A] text-white w-[40px] text-center"
                              }
                            >
                              1.0
                            </div>
                          </div>
                        </div>
                        <div className="px-9 pb-3 pt-2">
                          <strong>
                            Татварын нэгдсэн системд холбогдсон бизнес эрхлэгч,
                            аж ахуйн нэгжээс хэвлэсэн төлбөрийн баримтыг цахим
                            төлбөрийн баримтын системд бүртгүүлж, сугалааны
                            урамшуулалд оролцох, нэмэгдсэн өртгийн албан
                            татварын 20 хүртэлх хувийн буцаан олголтын
                            урамшуулал авах боломж бүрдсэн бөгөөд дараах
                            шинэчлэлүүд хийгдсэн.
                          </strong>
                        </div>
                        <div className="px-9 pb-5">
                          <strong>Үүнд:</strong>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              QR код, ДДТД болон сугалааны дугаараар баримтаа
                              бүртгүүлэх
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Хувийн мэдээллээ засварлах, урамшуулал авах дансаа
                              тохируулах
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Банкны картаар бэлэн бус гүйлгээ хийхэд худалдан
                              авалтын НӨАТ-ын баримт{" "}
                              <a
                                href={"https://ebarimt.mn/"}
                                className={"underline hover:text-gray-400"}
                              >
                                ebarimt.mn
                              </a>{" "}
                              системд автоматаар бүртгэгдэхээс гадна бүртгэлтэй
                              цахим шуудангийн хаягт цахимаар илгээгдэх
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Ибаримт аппликейшнд бүртгүүлсэн баримт, сугалааны
                              хонжвор, урамшууллын мэдээллийг харах, худалдан
                              авалтаа ангилах
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full h-[512px] max-lg:h-[720px] max-md:h-fit bg-[#E1E6EB] flex justify-center items-center">
                        <img
                          className=""
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic11.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "flex h-fit bg-brand-50 text-white max-md:flex-col"
                      }
                    >
                      <div className="w-1/2 max-md:w-full h-[512px] max-lg:h-[720px] max-md:h-fit bg-[#E1E6EB] flex justify-center items-center">
                        <img
                          className=""
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic6.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full max-xl:h-fit">
                        <div
                          className={
                            "h-[76px] flex items-center justify-between px-9"
                          }
                        >
                          <div>
                            <img
                              className="h-fit mt-2"
                              src={"/images/brand/eBarimt_v_2.png"}
                              alt="ITC GOV"
                              width={56}
                              height={56}
                            />
                          </div>
                          <div className={"text-base flex font-black h-6"}>
                            <div className={"text-white text-center px-3"}>
                              Хувилбар
                            </div>
                            <div
                              className={
                                "bg-[#BBC1E3] text-brand-50 w-[40px] text-center"
                              }
                            >
                              2.0
                            </div>
                          </div>
                        </div>
                        <div className="pl-9 pr-3 pb-3 pt-2">
                          <strong>
                            Татвар төлөгч иргэн Ибаримт аппликейшнээр төрийн 9
                            байгууллагын 32 төрлийн үйлчилгээг авах ба татварын
                            байгууллагатай иргэн, ААН системээр дамжуулан тайлан
                            мэдээллээ хялбараар түргэн шуурхай дамжуулах зэрэг
                            боломжууд бүрдсэн бөгөөд дараах шинэчлэлүүд
                            хийгдсэн.
                          </strong>
                        </div>
                        <div className="pl-9 pr-3 pb-5">
                          <strong>Үүнд:</strong>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Ибаримт аппликейшнд хандахад дата үнэ төлбөргүй
                              болсон бөгөөд төлбөрийн баримтаа хэзээ ч, хаанаас
                              ч бүртгүүлэх боломжтой
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Худалдан авагчийн буцаасан баримтыг борлуулагч тал
                              e-invoice.ebarimt.mn системээс зөвшөөрөх эсвэл
                              цуцлах боломжтой
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Татвар төлөгч иргэд ХХОАТ-ын хялбаршуулсан тайлан,
                              малын тоо, толгойн тайлангаа илгээх боломжтой.
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              ААН байгууллагын байршил болон дансны дугаараар
                              гомдол гаргах боломжтой.
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Ибаримт аппликейшнд тээврийн хэрэгслийн дугаарыг
                              холбон тээврийн хэрэгслийн орон нутгийн чанартай
                              зам ашигласны төлбөр төлж, баримтаа автоматаар
                              бүртгүүлэх
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"flex h-fit max-md:flex-col-reverse"}>
                      <div className="max-md:w-full max-xl:h-fit w-1/2 h-[512px]">
                        <div
                          className={
                            "h-[76px] flex items-center justify-between px-9"
                          }
                        >
                          <div>
                            <img
                              className="h-fit mt-2"
                              src={"/images/brand/eBarimt_v_3.png"}
                              alt="ITC GOV"
                              width={65}
                              height={65}
                            />
                          </div>
                          <div className={"text-base flex font-black h-6"}>
                            <div className={"text-brand-50 text-center px-3"}>
                              Хувилбар
                            </div>
                            <div
                              className={
                                "bg-[#0F218A] text-white w-[40px] text-center"
                              }
                            >
                              3.0
                            </div>
                          </div>
                        </div>
                        <div className="px-9 pb-3 pt-2">
                          <strong>
                            Ebarimt системийг иргэн, ААН татвар төлөгч
                            хэрэглэхэд илүү хялбар, нэмэлт боломжуудтай болсон
                            бөгөөд НӨАТ-ын буцаан олголтын урамшууллаасаа 9
                            төрлийн хөрөнгийн албан татвар суутган төлөх боломж
                            бүрдсэн бөгөөд дараах шинэчлэлүүд хийгдсэн.
                          </strong>
                        </div>
                        <div className="px-9 pb-5">
                          <strong>Үүнд:</strong>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Ebarimt aппликейшнаар сугалааны нэвтрүүлгийг шууд
                              дамжуулдаг болсон. Тохирлын мэдээллийг{" "}
                              <a
                                href={"https://sugalaa.ebarimt.mn/"}
                                className={"underline hover:text-gray-400"}
                              >
                                sugalaa.ebarimt.mn
                              </a>{" "}
                              веб сайтаас дэлгэрэнгүй харах боломжтой
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Засгийн газрын 2023 оны 12 дугаар сарын 13-ны
                              өдрийн 445 дугаар тогтоолын дагуу Гадаад жуулчны
                              төлсөн нэмэгдсэн өртгийн албан татвар “TAX FREE”
                              буцаан олголт олгох боломжтой
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Бизнес эрхлэгч, ААН, хувь хүнд QR баримт үүсгэх,
                              хэвлэгдсэн болон баталгаажсан баримтын буцаалт
                              хийх боломжтой
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Хялбар бүртгэлд холбогдсон аж ахуйн нэгж
                              байгууллагаас худалдан авалт хийсэн тохиолдолд
                              худалдан авалтын баримт автоматаар бүртгэгдэнэ.
                              Мөн ebarimt.mn/easy хаягаар хандан НӨАТ-ын
                              баримтаа автоматаар бүртгэх тохиргоог хийсэн
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full h-[512px] max-lg:h-[720px] max-md:h-fit bg-[#E1E6EB] flex justify-center items-center">
                        <img
                          className="px-3"
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic8.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 179 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-0">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic16.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={`ТӨРИЙН САНГИЙН УДИРДЛАГЫН ЦАХИМ СИСТЕМ - FINANCE`}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <Title name={"Тоон мэдээлэл"} />
                    <div
                      className={
                        "grid grid-cols-3 max-md:grid-cols-1 h-fit divide-x-2 w-full"
                      }
                    >
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div>
                          <img
                            className="h-fit mx-3 mt-2"
                            src={"/images/icon/finance_logo1.png"}
                            alt="ITC GOV"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className={"py-4"}>
                          <p className={"text-base font-semibold"}>
                            Төрийн сангийн нэгжийн тоо
                          </p>
                          {/*<p className={"text-[30px] font-semibold py-2"}>391</p>*/}
                          <p className={"text-[30px] font-semibold py-2"}>
                            {stat?.filter(
                              (x) => x.statCode === "Төрийн сангийн нэгжийн тоо"
                            )[0]
                              ? stat
                                  ?.filter(
                                    (x) =>
                                      x.statCode ===
                                      "Төрийн сангийн нэгжийн тоо"
                                  )[0]
                                  .statValue.toLocaleString("en-US")
                              : 391}
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div>
                          <img
                            className="h-fit mx-3 mt-2"
                            src={"/images/icon/finance_icon2.png"}
                            alt="ITC GOV"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className={"py-4"}>
                          <p className={"text-base font-semibold"}>
                            Төсөвт байгууллагын тоо
                          </p>
                          {/*<p className={"text-[30px] font-semibold py-2"}>5,264</p>*/}
                          <p className={"text-[30px] font-semibold py-2"}>
                            {stat?.filter(
                              (x) => x.statCode === "Төсөвт байгууллагын тоо"
                            )[0]
                              ? stat
                                  ?.filter(
                                    (x) =>
                                      x.statCode === "Төсөвт байгууллагын тоо"
                                  )[0]
                                  .statValue.toLocaleString("en-US")
                              : "5,259"}
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div>
                          <img
                            className="h-fit mx-3 mt-2"
                            src={"/images/icon/finance_icon3.png"}
                            alt="ITC GOV"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className={"py-4"}>
                          <p className={"text-base font-semibold"}>
                            Үйл ажиллагааны хяналт
                          </p>
                          {/*<p className={"text-[30px] font-semibold py-2"}>31,608</p>*/}
                          <p className={"text-[30px] font-semibold py-2"}>
                            {stat?.filter(
                              (x) =>
                                x.statCode === "Нийт төрийн сангийн дансны тоо"
                            )[0]
                              ? stat
                                  ?.filter(
                                    (x) =>
                                      x.statCode ===
                                      "Нийт төрийн сангийн дансны тоо"
                                  )[0]
                                  .statValue.toLocaleString("en-US")
                              : "31,629"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-white">
                      <div className="w-1/2 max-md:w-full h-[300px] max-xl:h-[400px] max-md:h-fit">
                        <Title
                          name={`Төсвийн хуваарийн дагуу хийсэн зарлагын гүйлгээний тоо:`}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div className="text-brand-50 ">
                            Нэгж гүйлгээний тоо:{" "}
                            <strong>
                              2023 онд{" "}
                              {stat?.filter(
                                (x) =>
                                  x.statCode ===
                                  "Өмнөх оны нийт зарлагын гүйлгээний тоо"
                              )[0]
                                ? stat
                                    ?.filter(
                                      (x) =>
                                        x.statCode ===
                                        "Өмнөх оны нийт зарлагын гүйлгээний тоо"
                                    )[0]
                                    .statValue.toLocaleString("en-US")
                                : "1,859,376"}
                            </strong>
                            {/*Нэгж гүйлгээний тоо: <strong>2023 онд 1,859,376</strong>*/}
                            <br />
                            <br />
                            Нэгж гүйлгээний тоо:{" "}
                            <strong>
                              2024 онд{" "}
                              {stat?.filter(
                                (x) =>
                                  x.statCode ===
                                  "Энэ оны нийт зарлагын гүйлгээний тоо"
                              )[0]
                                ? stat
                                    ?.filter(
                                      (x) =>
                                        x.statCode ===
                                        "Энэ оны нийт зарлагын гүйлгээний тоо"
                                    )[0]
                                    .statValue.toLocaleString("en-US")
                                : "794,622"}
                            </strong>
                            {/*Нэгж гүйлгээний тоо: <strong>2024 онд 526,833</strong>*/}
                            <br />
                            <br />
                            Засгийн газрын удирдлагын систем рүү илгээн
                            гүйлгээний цахим архив үүсгэн төсвийн зарлагын
                            хяналтыг хэрэгжүүлж байна.
                            <br />
                            <br />
                            Тухайн зарлагаас ebarimt invoice системээс үүсгэсэн
                            нэхэмжлэх төлөлтийн тоо{" "}
                            <strong>
                              2024 онд{" "}
                              {stat?.filter(
                                (x) => x.statCode === "Энэ оны нэхэмжлэхийн тоо"
                              )[0]
                                ? stat
                                    ?.filter(
                                      (x) =>
                                        x.statCode ===
                                        "Энэ оны нэхэмжлэхийн тоо"
                                    )[0]
                                    .statValue.toLocaleString("en-US")
                                : "366,033"}
                            </strong>{" "}
                            байна.
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic17.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                    <div className="flex max-md:flex-col bg-white h-fit">
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px] bg-[#90A5C3]">
                        {/*<img priority={true}*/}
                        {/*       className={""}*/}
                        {/*       style={{objectFit: "contain", height: "100%"}}*/}
                        {/*       src={"/images/pic/pic18.png"}*/}
                        {/*       alt="ITC GOV"*/}
                        {/*       width={1000}*/}
                        {/*       height={1000}/>*/}
                        <iframe
                          width="100%"
                          height="100%"
                          src="https://www.youtube.com/embed/UhCz69b4_VU?si=usADjJrLEgvdu-dc"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe>
                      </div>
                      <div className="w-1/2 max-md:w-full h-[300px] max-xl:h-[400px] max-md:h-fit">
                        <div className="px-9 py-8">
                          Уг систем нь төрийн сангийн гүйлгээтэй холбоотой
                          мэдээллийг бусад системээс хүлээн авч мэдээллийг
                          нэгтгэн smart payment систем рүү илгээдэг. Хамтран
                          ажилладаг системүүд:
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Төрийн албаны цалингийн нэгдсэн систем</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Цахим төлбөрийн баримтын систем</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Засгийн газрын санхүү удирдлагын мэдээллийн систем
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid grid-cols-2 max-sm:grid-cols-1 h-fit divide-x-2 h-[300px]">
                      <div className="bg-white">
                        <Title name={`Систем нэвтрэхээс өмнө:`} />
                        <div className="px-9 text-justify pt-5 pb-10">
                          Сангийн яам нь төсвийн байгууллагын гүйлгээг
                          батлагдсан төсвийн хуваарийн дагуу хийж гүйцэтгэхийн
                          тулд төсөвт байгууллага төлбөрийн хүсэлтийг цаасан
                          хэлбэрээр баталгаажуулан, харьяа төрийн санд биечлэн
                          хүргэж, төрийн сангийн ажилтан баримтыг хянан гүйлгээг
                          Засгийн газрын санхүү удирдлагын системд гараар шивэн
                          хүлээн авагчийн дансанд шилжүүлэн цаасан баримтад
                          суурилсан механик ажил ихтэй гүйлгээг хийдэг байсан.
                        </div>
                      </div>
                      <div className="bg-brand-50">
                        <Title
                          name={`Систем нэвтэрсний дараа:`}
                          color={"brand-50"}
                        />
                        <div className="px-9 text-justify pt-5 pb-10 text-white">
                          Төрийн сангийн удирдлагын цахим систем нь цахим
                          төлбөрийн баримтад суурилан иргэн, ААН төсөвт
                          байгууллага төлбөрийн баримт үүсгэн, төсөвт
                          байгууллага баримтыг үндэслэн төлбөрийн хүсэлт үүсгэх,
                          цахимаар баталгаажуулан, төрийн сан цахимаар хянан
                          баталгаажуулж иргэд,ААН-ын данс руу гүйлгээ цахимаар
                          дамжуулах боломжийг бүрдүүлсэн. Ингэснээр татварын
                          мэдээллийн бааз суурь нэмэгдэх, татварын орлого,
                          төсвийн зарлагын хяналт сайжрах, төрийн сангийн төлбөр
                          тооцооны цахим архив үүсэх боломж бүрдсэн.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 181 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-0">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic16.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={`Зөвшөөрлийн цахим систем`}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                        <Link
                          href="https://www.license.mn/"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            www.license.mn
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-white">
                      <div className="w-1/2 max-md:w-full h-[300px] max-xl:h-[400px] max-md:h-fit">
                        <Title name={`Цар хүрээ`} />
                        <div className="px-9 text-justify pb-10">
                          <div className="text-brand-50 ">
                            Улмаар Зөвшөөрлийн тухай хууль 2023 оны 1-р сарын
                            01-ний өдрөөс хэрэгжиж эхэлсэнтэй холбогдуулан
                            Монгол улсын засгийн газрын 2023 оны 233 дугаар
                            тогтоолоор Монгол улсын хэмжээнд олгодог тусгай
                            зөвшөөрлүүдийг цахимаар олгох, сунгах, цуцлах, түүнд
                            хяналт тавих цогц систем болгон хамрах цар хүрээг
                            өргөтгөж “Зөвшөөрлийн цахим систем” болсон.
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic17.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <Title name={"Тоон мэдээлэл"} />
                    <div
                      className={
                        "grid grid-cols-2 max-md:grid-cols-1 h-fit divide-x-2 w-full"
                      }
                    >
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-4"}>
                          <p className={"text-base font-semibold"}>
                            Нэгдсэн санд бүртгэлтэй зөвшөөрөл:
                          </p>
                          <p className={"text-[30px] font-semibold py-2"}>
                            87,044
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-4"}>
                          <p className={"text-base font-semibold"}>
                            Эрх бүхий байгууллагаас илгээж буй зөвшөөрөл:
                          </p>
                          <p className={"text-[30px] font-semibold py-2"}>
                            80,382
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-4"}>
                          <p className={"text-base font-semibold"}>
                            Тусгай зөвшөөрөл олгох эрх бүхий байгууллага:
                          </p>
                          <p className={"text-[30px] font-semibold py-2"}>24</p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-4"}>
                          <p className={"text-base font-semibold"}>
                            2021 оноос хойш системээр олгосон зөвшөөрөл:
                          </p>
                          <p className={"text-[30px] font-semibold py-2"}>
                            3,398
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid grid-cols-2 max-sm:grid-cols-1 h-fit divide-x-2 h-[300px]">
                      <div className="bg-white">
                        <Title name={`Систем нэвтрэхээс өмнө:`} />
                        <div className="px-9 max-sm:pl-3 text-justify pt-5 pb-10 text-white">
                          <div className="relative border-s-2 border-[#C1C1C1] border-dashed dark:border-gray-700 ml-8">
                            <div className="mb-10 pt-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                                Иргэн ААН
                              </h3>
                              <p className="mb-4 text-base font-normal text-gray-500">
                                Материал бүрүүлэх
                              </p>
                            </div>
                            <div className="mb-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-gray-900">
                                Холбогдох газар, хэлтэс
                              </h3>

                              <p className="text-base font-normal text-gray-500">
                                Хяналт тавих
                              </p>
                            </div>
                            <div className="mb-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-gray-900">
                                Мэргэжлийн байгууллага
                              </h3>
                              <p className="text-base font-normal text-gray-500">
                                Хяналт тавих
                              </p>
                            </div>
                            <div className="mb-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
                                Ажлын байртай танилцах
                              </h3>
                              <p className="mb-4 text-base font-normal text-gray-500">
                                Хяналт тавих
                              </p>
                            </div>
                            <div className="mb-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-gray-900">
                                Яамны удирдлагуудад танилцуулах
                              </h3>

                              <p className="text-base font-normal text-gray-500">
                                Баталгаажуулах
                              </p>
                            </div>
                            <div className="mb-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-gray-900">
                                Сайдын тушаал
                              </h3>

                              <p className="text-base font-normal text-gray-500">
                                Баталгаажуулах
                              </p>
                            </div>
                            <div className="pb-16 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-gray-900">
                                Олголт
                              </h3>

                              <p className="text-base font-normal text-gray-500">
                                Тэмдэгтийн хураамж олголт
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-brand-50">
                        <Title
                          name={`Систем нэвтэрсний дараа:`}
                          color={"brand-50"}
                        />
                        <div className="px-9 max-sm:pl-3 text-justify pt-5 pb-10">
                          <div className="relative border-s-2 border-[#C1C1C1] border-dashed dark:border-gray-700 ml-8">
                            <div className="mb-14 pt-10 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="flex items-center mb-1 text-lg font-semibold text-white">
                                Иргэн ААН
                              </h3>
                              <p className="mb-4 text-base font-normal text-white">
                                Хүсэлт гаргах
                              </p>
                            </div>
                            <div className="mb-14 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-white">
                                Хөндлөнгийн мэдээлэл
                              </h3>

                              <p className="text-base font-normal text-white">
                                Хяналт тавих
                              </p>
                            </div>
                            <div className="mb-14 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-white">
                                Зөвшөөрлийн цахим систем
                              </h3>
                              <p className="text-base font-normal text-white">
                                Баталгаажуулах
                              </p>
                            </div>
                            <div className="mb-16 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="flex items-center mb-1 text-lg font-semibold text-white">
                                Зөвшөөрөл олгох эрх бүхий байгууллага
                              </h3>
                              <p className="mb-4 text-base font-normal text-white">
                                Олголт
                              </p>
                            </div>
                            <div className="mb-14 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="flex items-center mb-1 text-lg font-semibold text-white">
                                Гэрчилгээ
                              </h3>
                              <p className="mb-4 text-base font-normal text-white">
                                Тэмдэгтийн хураамж
                              </p>
                            </div>
                            <div className="pb-20 ms-6">
                              <span className="absolute flex items-center justify-center w-6 h-6 rounded-full -start-3 dark:ring-gray-900 dark:bg-blue-900">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                >
                                  <circle
                                    cx="13"
                                    cy="13"
                                    r="12.5"
                                    fill="white"
                                    stroke="#A8A4A4"
                                  />
                                  <path
                                    d="M10 12.6L12.625 15L17 11"
                                    stroke="#164B8A"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </span>
                              <h3 className="mb-1 text-lg font-semibold text-white">
                                Зөвшөөрлийн цахим систем
                              </h3>

                              <p className="text-base font-normal text-white">
                                Байнгын шалгуур
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 169 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-0">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic16.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={
                            post[0].hdrDescription +
                            " - " +
                            post[0].projectTitle
                          }
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                        <Link
                          href="https://www.tulbur.gov.mn"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            www.tulbur.gov.mn
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-white">
                      <div className="w-1/2 max-md:w-full h-[300px] max-xl:h-[300px] max-md:h-fit">
                        <Title name={`Системийн давуу тал`} />
                        <div className="px-9 text-justify pb-10">
                          <div className="text-brand-50 ">
                            ААН, иргэд нь төрийн үйлчилгээг авахдаа төлбөр,
                            хураамжаа оператораар дамжуулах бэлэн бусаар
                            төлбөрөө төлөх, мөн төрийн үйлчилгээ үзүүлж буй
                            байгууллагууд үйлчилгээ болон хураамжийн мөнгөн дүнг
                            нэхэмжлэхийн дундын системд оруулж хаанаас ч
                            үйлчилгээний хураамжийн төлбөрийг байгууллагын
                            дансанд авах боломжтой үйл ажиллагаа юм.
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic17.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <Title name={"Тоон мэдээлэл"} />
                    <div className="px-9 text-justify pb-5">
                      <div className="text-black">
                        2024 оны 3 дугаар сарын 14-ний байдлаар татвар,
                        торгууль, замын хураамжийн төлбөрт нийт{" "}
                        <strong>36,987,978</strong> төгрөгийн төлөлт хийсэн
                        байна.
                      </div>
                    </div>
                    <div
                      className={
                        "grid grid-cols-4 max-xl:grid-cols-2 max-sm:grid-cols-1 h-fit gap-4 w-full px-9 pb-6"
                      }
                    >
                      <div
                        className={
                          "flex px-4 py-3 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-3"}>
                          <p className={"font-semibold pb-2"}>Татвар</p>
                          <p className={"text-[25px] font-semibold py-2"}>
                            8,443,799
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-4 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-3 text-white"}>
                          <p className={"font-semibold pb-2"}>Торгууль</p>
                          <p className={"text-[25px] font-semibold py-2"}>
                            8,443,799
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-4 py-3 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-3"}>
                          <p className={"font-semibold pb-2"}>Замын хураамж</p>
                          <p className={"text-[25px] font-semibold py-2"}>
                            8,443,799
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-4 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-3 text-white"}>
                          <p className={"font-semibold pb-2"}>
                            Татвар торгууль замын хураамж
                          </p>
                          <p className={"text-[25px] font-semibold py-2"}>
                            8,443,799
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid grid-cols-2 max-sm:grid-cols-1 h-fit divide-x-2 h-[300px]">
                      <div className="bg-white">
                        <Title name={`Систем нэвтрэхээс өмнө:`} />
                        <div className="px-9 text-justify pr-20 pb-10 max-sm:pr-10 max-sm:pl-6">
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Төлбөрийн асуудлаар дараалал үүсгэж, иргэдэд
                              хүндрэл үүсгэдэг асуудал
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Иргэн төрийн үйлчилгээний төлбөрийг заавал банканд
                              очиж төлөх шаардлага
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Үйлчилгээний төлбөрийн баримтыг цаасан хэлбэрээр
                              шаарддаг
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-brand-50">
                        <Title
                          name={`Систем нэвтэрсний дараа:`}
                          color={"brand-50"}
                        />
                        <div className="px-9 text-justify pb-10 pr-20 text-white max-sm:pr-10 max-sm:pl-6">
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Биеэр банканд дараалал үүсгэж цаг хугацаа алдахгүй
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Цаасан баримт шаардахгүй</p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Нэмэлт төлбөр авахгүйгээр social pay, qpay, болон
                              пос машин гэсэн сонголтоос хийж төлбөр төлөх
                              боломжтой болсон.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 174 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-0">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/pic/pic16.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={
                            post[0].hdrDescription +
                            " - " +
                            post[0].projectTitle
                          }
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                        <Link
                          href="https://etax.mta.mn/"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            www.etax.mta.mn
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <Title name={"Тоон мэдээлэл /2024 оны байдлаар/"} />
                    <div
                      className={
                        "grid grid-cols-3 max-xl:grid-cols-1 h-fit gap-4 w-full px-9 pb-6"
                      }
                    >
                      <div
                        className={
                          "grid max-xl:grid-cols-2 max-md:grid-cols-1  h-fit gap-4 w-full"
                        }
                      >
                        <div
                          className={
                            "flex px-10 py-3 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3"}>
                            <p className={"font-semibold pb-2"}>
                              Татвар төлөгчийн тоо
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              {stat
                                ?.filter(
                                  (x) => x.statCode === "Татвар төлөгчийн тоо"
                                )[0]
                                ?.statValue?.toLocaleString("en-US")}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "flex px-10 py-3 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3 text-brand-50"}>
                            <p className={"font-semibold pb-2"}>
                              Тайлангийн тоо
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              {stat
                                ?.filter(
                                  (x) => x.statCode === "Тайлангийн тоо"
                                )[0]
                                ?.statValue?.toLocaleString("en-US")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "grid grid-cols-1 max-xl:grid-cols-2 max-md:grid-cols-1 h-fit gap-4 w-full"
                        }
                      >
                        <div
                          className={
                            "flex px-10 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3 text-white"}>
                            <p className={"font-semibold pb-2"}>
                              Тайлангийн төрөл
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              {stat
                                ?.filter(
                                  (x) => x.statCode === "Тайлангийн төрөл"
                                )[0]
                                ?.statValue?.toLocaleString("en-US")}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "flex px-10 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3 text-white"}>
                            <p className={"font-semibold pb-2"}>
                              Тайлангийн илгээлт
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              -
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "grid grid-cols-1 max-xl:grid-cols-2 max-md:grid-cols-1 h-fit gap-4 w-full"
                        }
                      >
                        <div
                          className={
                            "flex px-4 py-2 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div
                            className={
                              "h-full flex items-center pl-3 pr-6 max-sm:pl-0 max-sm:pr-3"
                            }
                          >
                            <img
                              className={"h-[71px] w-[62px] object-contain"}
                              src={"/images/icon/female_icon.png"}
                              alt="ITC GOV"
                              width={44}
                              height={71}
                            />
                          </div>
                          <div className={"py-2 max-sm:py-1"}>
                            <p className={"pb-2 max-sm:pb-1"}>
                              Татварын тайлан шийдвэрлэлт:
                            </p>
                            <p className={"font-black pb-1"}>Байцаагч</p>
                            <p
                              className={
                                "text-[25px] font-black pb-2 max-sm:pb-1"
                              }
                            >
                              32,4%
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "flex px-4 py-2 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div
                            className={
                              "h-full flex items-center pl-3 pr-6 max-sm:pl-0 max-sm:pr-3"
                            }
                          >
                            <img
                              className={"h-[63px] object-contain"}
                              src={"/images/icon/com_icon.png"}
                              alt="ITC GOV"
                              width={62}
                              height={63}
                            />
                          </div>
                          <div className={"py-2 max-sm:py-1"}>
                            <p className={"pb-2 max-sm:pb-1"}>
                              Татварын тайлан шийдвэрлэлт:
                            </p>
                            <p className={"font-black pb-1"}>Систем</p>
                            <p
                              className={
                                "text-[25px] font-black pb-2 max-sm:pb-1"
                              }
                            >
                              67,5%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid grid-cols-2 max-sm:grid-cols-1 h-fit divide-x-2 h-[300px]">
                      <div className="bg-white">
                        <Title name={`Систем нэвтрэхээс өмнө:`} />
                        <div className="px-9 text-justify pr-20 pb-10 max-sm:pr-10 max-sm:pl-6">
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Тайланг биеэр өгч дараалалд дугаарлах</p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Цаг хугацаа шаардах</p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Татварын тайлантай холбоотой хүсэлт тодорхойлолтыг
                              биеэр хүлээн авах
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Цаасан баримт үйлдэх</p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-brand-50">
                        <Title
                          name={`Систем нэвтэрсний дараа:`}
                          color={"brand-50"}
                        />
                        <div className="px-9 text-justify pb-10 pr-20 text-white max-sm:pr-10 max-sm:pl-6">
                          <div className="flex pt-4">
                            <img
                              className="w-[12px] h-[12px] mx-3 mt-2 object-contain"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Хуулийн хугацаанд тайланг хаанаас ч цахимаар
                              тайлагнах, илгээх, хүсэлт тодорхойлолтыг хүлээн
                              авах боломжийг бүрдүүлсэн.
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="mx-3 mt-2 w-[12px] h-[12px] object-contain"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Татвар төлөгчдөд ойлгомжтой, хялбар, түргэн
                              шуурхай хүргэх
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 182 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/upload/lavlagaa.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={post[0].projectTitle}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                        <Link
                          href="https://lavlagaa.e-mongolia.mn"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            lavlagaa.e-mongolia.mn
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <Title
                      name={
                        "Систем нэвтэрсэн 2022.11.25-с 2024.05-р сарын байдлаар тоон мэдээлэл:"
                      }
                    />
                    <div
                      className={
                        "grid grid-cols-2 max-md:grid-cols-1 h-fit w-full"
                      }
                    >
                      <div className={"bg-white"}>
                        <div
                          className={
                            "flex px-6 py-3 border-2 sm:ml-10 sm:mr-4 sm:mb-4 border-gray-300 max-md:pl-12 max-sm:pl-6"
                          }
                        >
                          <div className={"py-4"}>
                            <p className={"text-base font-semibold"}>
                              Татвар төлөгчийн тоо
                            </p>
                            <p className={"text-[30px] font-semibold py-2"}>
                              41
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={"bg-white"}>
                        <div
                          className={
                            "flex px-6 bg-[#031842] py-3 sm:ml-4 sm:mr-10 sm:mb-4 border-gray-300 max-md:pl-12 max-sm:pl-6"
                          }
                        >
                          <div className={"py-4"}>
                            <p
                              className={
                                "text-base text-[#ffffff] font-semibold"
                              }
                            >
                              Лавлагаа авсан тоо
                            </p>
                            <p
                              className={
                                "text-[30px] text-[#ffffff] font-semibold py-2"
                              }
                            >
                              717,151
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={"bg-white"}>
                        <div
                          className={
                            "flex px-6 py-3 border-2 sm:ml-10 sm:mr-4 sm:mb-10 border-gray-300 max-md:pl-12 max-sm:pl-6"
                          }
                        >
                          <div className={"py-4"}>
                            <p className={"text-base font-semibold"}>
                              Нийт хэрэглэгч
                            </p>
                            <p className={"text-[30px] font-semibold py-2"}>
                              7,432
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={"bg-white"}>
                        <div
                          className={
                            "flex px-6 bg-[#031842] py-3 sm:ml-4 sm:mr-10 border-gray-300 max-md:pl-12 max-sm:pl-6"
                          }
                        >
                          <div className={"py-4"}>
                            <p
                              className={
                                "text-base text-[#ffffff] font-semibold"
                              }
                            >
                              Төлбөртэй авсан лавлагаа
                            </p>
                            <p
                              className={
                                "text-[30px] text-[#ffffff] font-semibold py-2"
                              }
                            >
                              4,050
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid grid-cols-2 max-sm:grid-cols-1 h-fit divide-x-2 h-[300px]">
                      <div className="bg-white">
                        <Title name={`Систем нэвтрэхээс өмнө:`} />
                        <div className="px-9 text-justify pr-20 pb-10">
                          <div className="flex pt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Лавлагааг цаасан хэлбэрээр авч ирэхийг шаарддаг
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>·Биечлэн очих шаардлага гардаг</p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Цаг хугацаа, төлбөр хураамж зарцуулдаг</p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-brand-50">
                        <Title
                          name={`Систем нэвтэрсний дараа:`}
                          color={"brand-50"}
                        />
                        <div className="px-9 text-justify pb-10 pr-20 text-white">
                          <div className="flex pt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Төрийн болон хувийн байгууллага иргэнд үйлчилгээ
                              үзүүлэхдээ төрийн байгууллагын лавлагааг цаасан
                              хэлбэрээр авчрахыг шаардахгүй болсон.
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Иргэн төрийн байгууллага дээр биеэр очилгүй,
                              лавлагааг https://lavlagaa.e-mongolia.mn с авах
                              боломжтой болсон.
                            </p>
                          </div>
                          <div className="flex pt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/checkWhite.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Цаг, мөнгө зарцуулдаг байсан асуудыг шийдэж
                              Лавлагааны цахим системээс төрийн байгууллагын 162
                              төрлийн лавлагааг авах боломжтой болоод байна.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 185 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/upload/GVSBB.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={
                            "Эрдэс баялаг мэргэжлийн зөвлөлийн систем - MPC"
                          }
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[211px] pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                        <Link
                          href="https://mpc.mmhi.gov.mn"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            mpc.mmhi.gov.mn
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-white">
                      <div className="w-1/2 max-md:w-full h-[300px] max-xl:h-[300px] max-md:h-fit">
                        <Title name={`Системийн давуу тал`} />
                        <div className="px-9 text-justify pb-10">
                          <div className="text-brand-50 ">
                            Эрдэс баялгийн мэргэжлийн зөвлөлийн үйл ажиллагааг
                            нээлттэй, ил тод, хүнд сурталгүй болгох, зөвлөлийн
                            дүрэмд нийцүүлэх, хяналт тавих, тайлан мэдээлэл,
                            судалгаа боловсруулах, талуудын оролцоог
                            хялбаршуулах зорилготой систем юм.
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/upload/GVS.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid  grid-cols-2 max-sm:grid-cols-1 text-brand-50 font-semibold bg-white divide-x-2 divide-y-2">
                      <div className="py-6 flex items-start h-40 max-sm:h-fit">
                        <div className="w-3 h-7 bg-brand-50"></div>
                        <div className={"leading-5 pl-6 w-11/12 italic"}>
                          ЭБМЗ-ийн хүсэлт шийдвэрлэх шат дамжлага хүний оролцоо
                          ихтэй
                        </div>
                      </div>
                      <div className="py-6 flex items-start h-40 max-sm:h-fit">
                        <div className="w-3 h-7 bg-brand-50"></div>
                        <div className={"leading-5 pl-6 w-11/12 italic"}>
                          Цаасан материал ихээр хуримтлагддаг
                        </div>
                      </div>
                      <div className="py-6 flex items-start h-40 max-sm:h-fit">
                        <div className="w-3 h-7 bg-brand-50"></div>
                        <div className={"leading-5 pl-6 w-11/12 italic"}>
                          Хүсэлтийг мэргэшсэн мэргэжилтнийг шинжээчээр 30 хоногт
                          багтаан томилуулах, нөөцийн тайлан, ТЭЗҮ-г 90 хоногт
                          багтаан мэдээллийн санд бүртгэх талаар шийдвэр гаргах
                          шаардлагатай байсан.
                        </div>
                      </div>
                      <div className="py-6 flex items-start h-40 max-sm:h-fit">
                        <div className="w-3 h-7 bg-brand-50"></div>
                        <div className={"leading-5 pl-6 w-11/12 italic"}>
                          Мөн аж ахуйн нэгж, байгууллага дэгт орсон өдөр, хуралд
                          орох өдөр, хурлаас хасагдсан шалтгаан зэрэг нь маргаан
                          дагуулдаг байсан бол систем нэвтэрснээр дээрх
                          мэдээллүүд нээлттэй ил тод болсон.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 187 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/background/SGS.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title name={"УХААЛАГ ГАРЦ-SGS"} bgColor={"#D5D9E5"} />
                        <div className="px-9 text-justify xl:h-[211px] pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-white">
                      <div className="w-1/2 max-md:w-full h-[300px] max-xl:h-[300px] max-md:h-fit">
                        <div className="px-9 text-justify pb-10">
                          <div className="text-brand-50 p-10">
                            Ухаалаг гарц систем нь тээврийн хэрэгслийн улсын
                            хилээр орох гарах хөдөлгөөнийг бүртгэж, гаалийн
                            хяналтын боомт урт дараалалд үүсдэг байсныг
                            багасгаж, хилийн хяналтын бүсэд тээврийн хэрэгслийн
                            улсын дугаар таних төхөөрөмжийн тусламжтайгаар
                            Гаалийн удирдлагын системтэй холбогдон автоматаар
                            тээврийн хэрэгслийг хилээр нэвтрүүлэх зориулалттай
                            хилийн хяналтын систем юм.
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/background/SGSS.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <Title name={"Системийн үйл ажиллагаа"} />
                    <div className="bg-white flex justify-center md:px-32 py-12">
                      <div className="items-center ml-10 md:ml-0 md:flex font-[600] text-[14px]">
                        <div className="md:mr-10 w-[150px]">
                          <img
                            style={{
                              height: "140px",
                              width: "100%",
                            }}
                            src={"/images/project/sgs-1.svg"}
                            alt="ITC GOV"
                            width={1000}
                            height={1000}
                          />
                          <div className="flex justify-center align-end text-center font-inter mt-6 mb-10">
                            Тээврийн хэрэгсэл
                          </div>
                        </div>
                        <div className="mr-12 w-[150px]">
                          <img
                            style={{
                              height: "140px",
                              width: "100%",
                            }}
                            src={"/images/project/sgs-2.svg"}
                            alt="ITC GOV"
                            width={1000}
                            height={1000}
                          />
                          <div className="flex justify-center align-center mt-6 mb-10">
                            Гаалийн хяналтын бүс
                          </div>
                        </div>
                        <div className="md:mr-16 md:w-[60px] w-[150px] flex justify-center my-10">
                          <div className="rotate-90 md:rotate-0 w-[46px]">
                            <img
                              style={{
                                width: "100%",
                              }}
                              src={"/images/project/sgs-arrow.svg"}
                              alt="ITC GOV"
                              width={1000}
                              height={1000}
                            />
                            <img
                              style={{
                                width: "100%",
                              }}
                              src={"/images/project/sgs-arrow-2.svg"}
                              alt="ITC GOV"
                              width={1000}
                              height={1000}
                            />
                          </div>
                        </div>
                        <div className="mr-16 w-[150px]">
                          <img
                            style={{
                              height: "140px",
                              width: "100%",
                            }}
                            src={"/images/project/sgs-3.svg"}
                            alt="ITC GOV"
                            width={1000}
                            height={1000}
                          />
                          <div className="flex justify-center align-end text-center mt-6 mb-10">
                            Ухаалаг гарц систем
                          </div>
                        </div>
                        <div className="md:mr-16 md:w-[60px] w-[150px] flex justify-center my-10">
                          <div className="rotate-90 md:rotate-0 w-[46px]">
                            <img
                              style={{
                                width: "100%",
                              }}
                              src={"/images/project/sgs-arrow.svg"}
                              alt="ITC GOV"
                              width={1000}
                              height={1000}
                            />
                            <img
                              style={{
                                width: "100%",
                              }}
                              src={"/images/project/sgs-arrow-2.svg"}
                              alt="ITC GOV"
                              width={1000}
                              height={1000}
                            />
                          </div>
                        </div>
                        <div className="w-[150px]">
                          <div className="flex">
                            <img
                              style={{
                                height: "140px",
                                width: "100%",
                              }}
                              src={"/images/project/sgs-4.svg"}
                              alt="ITC GOV"
                              width={1000}
                              height={1000}
                            />
                            <div className="flex mt-16 justify-center text-center">
                              Улсын байцаагч
                            </div>
                          </div>
                          <div className="flex justify-center align-end text-center mt-6 mb-10">
                            Гаалийн удирдлагын нэгдсэн систем
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <Title name={"Хилээр нэвтэрсэн тээврийн хэрэгслийн тоо:"} />
                    <div className="bg-white">
                      <div className="p-5">
                        <div className="relative overflow-x-auto">
                          <table className="w-full  text-sm text-left rtl:text-right text-gray-500 ">
                            <thead className="text-xs text-white bg-[#0C2C52] ">
                              <tr className="font-inter">
                                <th
                                  scope="col"
                                  className="px-10 font-inter border-r border-white py-3 w-[33%]"
                                >
                                  Боомтын нэр
                                </th>
                                <th
                                  scope="col"
                                  className="px-10 font-inter border-r py-3 w-[33%]"
                                >
                                  2023 он
                                </th>
                                <th
                                  scope="col"
                                  className="px-10 font-inter py-3 w-[33%]"
                                >
                                  2024 он
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-white border border-[#7F7F7F] ">
                                <th
                                  scope="row"
                                  className="px-10 font-inter py-4 text-gray-900 whitespace-nowrap"
                                >
                                  Замын-Үүд боомт
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  20,542
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  15,133
                                </th>
                              </tr>
                              <tr className="bg-white border border-[#7F7F7F]">
                                <th
                                  scope="row"
                                  className="px-10 font-inter py-4 text-gray-900 whitespace-nowrap"
                                >
                                  Гашуунсухайт боомт
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  145,604
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  41,298
                                </th>
                              </tr>
                              <tr className="bg-white border border-[#7F7F7F]">
                                <th
                                  scope="row"
                                  className="px-10 font-inter py-4 text-gray-900 whitespace-nowrap"
                                >
                                  Замын-Үүд ачаа тээвэр
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  3,875
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  11,731
                                </th>
                              </tr>
                              <tr className="bg-white border border-[#7F7F7F]">
                                <th
                                  scope="row"
                                  className="px-10 font-inter py-4 text-gray-900 whitespace-nowrap"
                                >
                                  Шивээхүрэн
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  7,372
                                </th>
                                <th
                                  scope="row"
                                  className="px-10 text-gray-900 font-inter py-4"
                                >
                                  9,364
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 189 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/background/rpsBackgroun.png"}
                          alt="ITC GOV"
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={"ЗАМЫН ХУРААМЖИЙН СИСТЕМ - RPS"}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[211px] pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-white">
                      <div className="w-1/2 max-md:w-full h-[427px] max-xl:h-[427px] max-md:h-fit">
                        <Title
                          name={`Төлбөр авах цэгийн үйл ажиллагааг цахимжуулсны давуу тал:`}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div className="text-brand-50 px-4">
                            <div className="mb-3">
                              Авто зам ашигласны төлбөрийг цахимаар авах нөхцөл
                              бүрдсэнээр төлбөр авах цэгүүд дээр түгжрэл
                              үүсэхгүй, зам хэрэглэгчдэд түргэн шуурхай, соёлтой
                              үйлчлэх нөхцөл бүрдэнэ.
                            </div>
                            <div className="mb-3">
                              Төлбөр авах цэг дээр явуулж буй үйл ажиллагааны
                              урсгал зардлыг хэмнэж, ажилчдын эрүүл, аюул
                              осолгүй ажиллаж хөдөлмөрлөх нөхцөл хангагдана.
                            </div>
                            <div className="mb-3">
                              Орон нутагт зорчиж байгаа тээврийн хэрэгслийн
                              бүртгэл, хяналт цахим хэлбэрт орж, зөрчилтэй
                              тээврийн хэрэгсэлд цаг алдалгүй арга хэмжээ
                              авснаар замын хөдөлгөөний аюулгүй байдал, авто
                              замын ашиглалтын нөхцөл байдал сайжирна.
                            </div>
                            <div>
                              Улсын авто замын санд төвлөрөх авто зам ашигласны
                              орлого бүрдүүлэлт ил тод, шилэн болж, орлогын
                              хэмжээ тодорхой хувиар нэмэгдэх зэргээр зам,
                              тээврийн салбарын хөгжилд ахиц дэвшилтэй зэрэг
                              томоохон ач холбогдолыг бүрдүүлсэн.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/background/rpsBackground.jpg"}
                          alt="ITC GOV"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <Title name={"Тоон мэдээлэл /2024 оны байдлаар/"} />
                    <div
                      className={
                        "grid grid-cols-2 max-xl:grid-cols-1 h-fit gap-4 w-full px-9 pb-6"
                      }
                    >
                      <div
                        className={
                          "grid max-xl:grid-cols-2 max-md:grid-cols-1  h-fit gap-4 w-full"
                        }
                      >
                        <div
                          className={
                            "flex px-10 py-3 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3"}>
                            <p className={"font-semibold pb-2"}>
                              АТБӨЯХ-ийн тоо
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              {stat?.filter(
                                (x) => x.statCode === "АТБӨЯХ-ийн тоо"
                              )[0]
                                ? stat
                                    ?.filter(
                                      (x) => x.statCode === "АТБӨЯХ-ийн тоо"
                                    )[0]
                                    .statValue.toLocaleString("en-US")
                                : "7,072"}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "flex px-10 py-3 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3 text-brand-50"}>
                            <p className={"font-semibold pb-2"}>
                              Төлөлтийн тоо
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              {stat?.filter(
                                (x) => x.statCode === "Төлөлтийн тоо"
                              )[0]
                                ? stat
                                    ?.filter(
                                      (x) => x.statCode === "Төлөлтийн тоо"
                                    )[0]
                                    .statValue.toLocaleString("en-Us")
                                : "6,016"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "grid grid-cols-1 max-xl:grid-cols-2 max-md:grid-cols-1 h-fit gap-4 w-full"
                        }
                      >
                        <div
                          className={
                            "flex px-10 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3 text-white"}>
                            <p className={"font-semibold pb-2"}>
                              Нэхэмжлэхийн тоо
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              {stat?.filter(
                                (x) => x.statCode === "Нэхэмжлэхийн тоо"
                              )[0]
                                ? stat
                                    ?.filter(
                                      (x) => x.statCode === "Нэхэмжлэхийн тоо"
                                    )[0]
                                    .statValue.toLocaleString("en-Us")
                                : "46,891"}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "flex px-10 py-3 bg-brand-50 border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"
                          }
                        >
                          <div className={"py-3 text-white"}>
                            <p className={"font-semibold pb-2"}>
                              Төлсөн мөнгөн дүн
                            </p>
                            <p className={"text-[25px] font-semibold py-2"}>
                              {stat?.filter(
                                (x) => x.statCode === "Төлсөн мөнгөн дүн"
                              )[0]
                                ? stat
                                    ?.filter(
                                      (x) => x.statCode === "Төлсөн мөнгөн дүн"
                                    )[0]
                                    .statValue.toLocaleString("en-Us")
                                : "64,079,000"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 191 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col md:h-[310px] bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "310px",
                          }}
                          src={"/images/project/sws1.png"}
                          alt="ITC GOV"
                          width={640}
                          height={310}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={"ХАЛАМЖИЙН НЭГДСЭН СИСТЕМ - SWS"}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[165px]">
                          <div
                            className="text-brand-50 font-sans"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                        <Link
                          href="https://ehalamj.mn/"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            ehalamj.mn
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid grid-cols-2 max-md:grid-cols-1 text-brand-50 font-semibold">
                      <div className="">
                        <img
                          style={{
                            height: "235px",
                            objectFit: "cover",
                          }}
                          src={"/images/project/sws2-1.png"}
                          alt="link-filled"
                          width={644}
                          height={200}
                        />
                        <Title
                          color="brand-50"
                          bgColor="#031842"
                          name={"Тоон мэдээлэл"}
                        />
                        <div
                          className={
                            "grid grid-cols-3 max-sm:grid-cols-1 mb-4 md:mb-0 md:h-[115px]"
                          }
                        >
                          <div
                            className={"flex text-white sm:pl-10 bg-[#031842]"}
                          >
                            <div className={"pl-10 pb-4 sm:pl-0 sm:pb-0"}>
                              <p className={"font-semibold"}>
                                Хөтөлбөрийн тоо:
                              </p>
                              <p className={"text-[30px] font-semibold py-2"}>
                                {stat?.filter(
                                  (x) => x.statCode === "Хөтөлбөрийн тоо"
                                )[0]
                                  ? stat
                                      ?.filter(
                                        (x) => x.statCode === "Хөтөлбөрийн тоо"
                                      )[0]
                                      .statValue.toLocaleString("en-US")
                                  : "391"}
                              </p>
                            </div>
                          </div>
                          <div className={"flex text-white bg-[#031842]"}>
                            <div className={"pl-10 pb-4 sm:pl-0 sm:pb-0"}>
                              <p className={"font-semibold"}>
                                Үйлчилгээний тоо:
                              </p>
                              <p className={"text-[30px] font-semibold py-2"}>
                                {stat?.filter(
                                  (x) => x.statCode === "Үйлчилгээний тоо"
                                )[0]
                                  ? stat
                                      ?.filter(
                                        (x) => x.statCode === "Үйлчилгээний тоо"
                                      )[0]
                                      .statValue.toLocaleString("en-US")
                                  : "5,264"}
                              </p>
                            </div>
                          </div>
                          <div
                            className={
                              "flex text-white bg-[#031842] pb-10 sm:pb-0"
                            }
                          >
                            <div className={"pl-10 sm:pl-0"}>
                              <p className={"font-semibold"}>
                                Гүйлгээний тоо:{" "}
                              </p>
                              <p className={"text-[30px] font-semibold py-2"}>
                                {stat?.filter(
                                  (x) => x.statCode === "Гүйлгээний тоо"
                                )[0]
                                  ? stat
                                      ?.filter(
                                        (x) => x.statCode === "Гүйлгээний тоо"
                                      )[0]
                                      .statValue.toLocaleString("en-US")
                                  : "31,608"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "235px",
                            width: "100%",
                          }}
                          src={"/images/project/sws2-2.png"}
                          alt="link-filled"
                          width={644}
                          height={200}
                        />
                        <div className="bg-white text-[#000000] p-9 sm:p-10 font-sans h-max md:h-[191px]">
                          “Нийгмийн халамжийн үйлчилгээг цахимжуулах талаар авах
                          зарим арга хэмжээний тухай” тогтоолын хүрээнд
                          Хөдөлмөр, нийгмийн хамгааллын сайд, Сангийн сайд нарын
                          2020 оны 02 дугаар сарын 07-ны өдрийн А/46/30 тоот
                          хамтарсан тушаалаар уг системийг 2020 оны 08 дугаар
                          сарын 25-ны өдөр системийн хэвийн найдвартай
                          ажиллагааг ханган, халамжийн бүх хөтөлбөрүүдийг үе
                          шаттайгаар нэвтрүүлсэн.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-white">
                      <div className="w-1/2 max-md:w-full max-sm:h-[180px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/sws3.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-[300px] max-xl:h-[300px] max-md:h-fit">
                        <Title name={`Системийн давуу тал`} />
                        <div className="px-9 text-justify pb-10">
                          <div className="text-brand-50 font-sans">
                            Иргэний цаасаар бүрдүүлдэг баримт бичгийн тоо 80
                            хувиар буурч, хугацааг хэмнэх, банк харгалзахгүйгээр
                            өөрийн нэрийн дансаар дамжуулан нийгмийн халамжийн
                            тэтгэвэр, тэтгэмжийн мөнгө авах, өөрийн гаргасан
                            өргөдөл, хүсэлтийнхээ шийдвэрлэлт, олголтын
                            мэдээллээ харах зэрэг боломжтой болсон.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 201 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[250px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/newtreh.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={"Нэвтрэлтйн нэгдсэн систем"}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[211px] pb-10">
                          <div
                            className="text-brand-50 "
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div
                      className={
                        "flex bg-[#031842] text-white max-md:flex-col-reverse h-fit"
                      }
                    >
                      <div className="max-md:w-full max-xl:h-fit p-4">
                        <div className="px-9 pb-3 pt-2">
                          <strong>
                            Нэвтрэлтийн нэгдсэн системийн тусламжтайгаар
                            хэрэглэгчид олон системд бүртгэл үүсгэх
                            шаардлагагүйгээр уг системд регистрийн дугаараараа
                            бүртгэл үүсгэж дараах 18 төрийн үйлчилгээний системд
                            хялбараар зэрэг нэвтрэх боломжтой болсон.
                          </strong>
                        </div>
                        <div className="grid md:grid-cols-2 grid-cols-1">
                          <div className="px-9 pb-5">
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Цахим татварын систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Цахим төлбөрийн баримтын систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Бараа бүртгэлийн нэгдсэн систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Төрийн албаны цалингийн нэгдсэн систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Төрийн сангийн сургалтын систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Төрийн сангийн гүйлгээний нэгдсэн систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Санхүүгийн цахим тайлангийн систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Цахим хяналт шинжилгээ үнэлгээний систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Шилэн дансны систем</p>
                            </div>
                          </div>
                          <div className="px-9 pb-5">
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Халамжийн нэгдсэн систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Эрүүл мэндийн даатгалын цахим систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Вакцины нэгдсэн систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Эрхийн бүртгэлийн сан систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Мэдээлэл солилцооны систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>
                                Гааль, татвар, санхүүгийн мэдээлэл технологийн
                                танилцуулга систем
                              </p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Валютын гүйлгээний систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Зөвшөөрлийн цахим систем</p>
                            </div>
                            <div className="flex mt-4">
                              <img
                                className="h-fit mx-3 mt-2"
                                src={"/images/icon/check.png"}
                                alt="ITC GOV"
                                width={12}
                                height={7}
                              />
                              <p>Статистикийн мэдээллийн систем</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4">
                    <Title name={"Тоон мэдээлэл"} />
                    <div
                      className={
                        "grid grid-cols-2 max-md:grid-cols-1 h-fit divide-x-2 w-full"
                      }
                    >
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-4 md:pl-28"}>
                          <p className={"text-base font-semibold"}>
                            Холбогдсон системийн тоо
                          </p>
                          <p className={"text-[30px] font-semibold py-2"}>
                            {stat?.filter(
                              (x) => x.statCode === "Системийн тоо"
                            )[0]
                              ? stat
                                  ?.filter(
                                    (x) => x.statCode === "Системийн тоо"
                                  )[0]
                                  .statValue.toLocaleString("en-Us")
                              : "2,674,469"}
                          </p>
                        </div>
                      </div>
                      <div
                        className={
                          "flex px-6 py-3 bg-white border-t-2 border-gray-300 max-md:pl-12 max-sm:pl-6"
                        }
                      >
                        <div className={"py-4 md:pl-28"}>
                          <p className={"text-base font-semibold"}>
                            Хэрэглэгчийн тоо
                          </p>
                          <p className={"text-[30px] font-semibold py-2"}>
                            {stat?.filter(
                              (x) => x.statCode === "Хэрэглэгчийн тоо"
                            )[0]
                              ? stat
                                  ?.filter(
                                    (x) => x.statCode === "Хэрэглэгчийн тоо"
                                  )[0]
                                  .statValue.toLocaleString("en-Us")
                              : "2,674,469"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div className={"flex max-md:flex-col-reverse h-fit"}>
                      <div className="w-1/2 max-md:w-full max-xl:h-fit">
                        <Title name={"Системийн давуу тал:"} />
                        <div className="px-9 pb-5">
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Цахим үйлчилгээг хангасан технологи</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Нэг удаагийн нууц үгийг давхар ашиглах боломж
                              /OTP/
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Нэг нэвтрэлтээр олон систем рүү зэрэг хандах</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Цахим аюулгүй байдлын шаардлагыг хангасан</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Эрхийн тохиргоо үүсгэх, удирдах</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Системийн ачааллыг хуваарилах технологи</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Мэдэгдлийг нэгдсэн байдлаар түгээх</p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>
                              Тоон мэдээлэл, судалгаа нь үнэн зөвийг хангасан
                            </p>
                          </div>
                          <div className="flex mt-4">
                            <img
                              className="h-fit mx-3 mt-2"
                              src={"/images/icon/check.png"}
                              alt="ITC GOV"
                              width={12}
                              height={7}
                            />
                            <p>Ойлгомжтой, хурдан хялбаршуулсан технологи</p>
                          </div>
                        </div>
                      </div>
                      <div className="w-1/2 max-md:w-full h-[512px] max-lg:h-[720px] max-md:h-fit bg-[#E1E6EB] flex justify-center items-center">
                        <img
                          className=""
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/newtrelt2.png"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 193 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[310px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/healtBig.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-full">
                        <Title
                          name={"Эрүүл мэндийн даатгалын цахим систем - HEALTH"}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[165px]">
                          <div
                            className="text-brand-50 font-sans"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                        <Link
                          href="https://health.gov.mn/"
                          className="flex items-center group px-9 pb-8"
                        >
                          <div className="flex items-center justify-center bg-white w-10 h-10">
                            <img
                              className="group-hover:scale-125 duration-300"
                              src={"/images/icon/link-filled.png"}
                              alt="link-filled"
                              width={18}
                              height={18}
                            />
                          </div>
                          <div className="group-hover:underline group-hover:underline-offset-8 items-center bg-white text-brand-50 h-10 px-3 pt-2 border-l">
                            https://health.gov.mn/
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div className={"grid grid-cols-1 md:grid-cols-2 "}>
                      <div className="max-md:w-full order-1 md:order-0 flex justify-center items-center h-[311px]">
                        <div className="font-inter font-[400] text-[#000000] text-[14px] px-12 text-justify py-10 md:py-0">
                          Эрүүл мэндийн сайд, Сангийн сайдын хамтарсан{" "}
                          <span className="font-[700]">
                            2020/09/17-ний А/182 А/434
                          </span>{" "}
                          тоот тушаалаар эрүүл мэндийн байгууллагуудын ашиглаж
                          буй мэдээллийн системээс тусламж үйлчилгээний
                          мэдээллийг тухай бүр хүлээн авах, систем хооронд
                          интеграци хийх, нэгдсэн мэдээллийн санг бий болгож
                          мэдээллийг түргэн шуурхай солилцох зорилготой систем
                          юм.
                        </div>
                      </div>
                      <div className="max-md:w-full md:order-last bg-[#E1E6EB] h-[311px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/healtSmall.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid  grid-cols-3 max-sm:grid-cols-1 text-brand-50 font-semibold bg-white divide-x-2 divide-y-2">
                      <div className="py-6 flex bg-[#031842] items-start h-full max-sm:h-fit">
                        <div className="w-3 h-7 bg-white"></div>
                        <div
                          className={
                            "leading-5 text-white pt-0 md:pt-2 text-justify pl-6 w-11/12 "
                          }
                        >
                          Эрүүл мэндийн байгууллагууд тусламж үйлчилгээний
                          мэдээллийг өөрсдийн ашиглаж буй системээс файл
                          бэлтгэн, сар бүрийн тодорхой өдрүүдэд Эрүүл мэндийн
                          даатгалын цахим системд илгээх шаардлагатай байдаг.
                        </div>
                      </div>
                      <div className="py-6 flex bg-[#031842] items-start h-full max-sm:h-fit">
                        <div className="w-3 h-7 bg-white"></div>
                        <div
                          className={
                            "leading-5 text-white pt-0 md:pt-2 text-justify pl-6 w-11/12 "
                          }
                        >
                          Файлыг боловсруулах явцад алдаа гарах, алдаатай
                          бэлтгэсэн тохиолдолд буцах, дахин илгээх зэрэг
                          хүндрэлүүд байсан бол систем хооронд мэдээлэл
                          солилцох, интеграци хийх, тусламж үйлчилгээний
                          мэдээллийг тухай бүр илгээх боломжтой болсон.
                        </div>
                      </div>
                      <div className="py-6 flex bg-[#031842] items-start h-48 max-sm:h-fit">
                        <div className="w-3 h-7 bg-white"></div>
                        <div
                          className={
                            "leading-5 text-white  pt-0 md:pt-2 text-justify pl-6 w-11/12 "
                          }
                        >
                          Мөн вакцины дараах урвал хүндрэлийг хянах, зорилтот
                          бүлгийг вакцинжуулах төлөвлөгөө, вакцины бүртгэл хийх,
                          улсын хэмжээнд дархлаажуулалтын нэгдсэн тоон судалгаа
                          тайланг харах боломж бүрдсэн.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 195 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full">
                        <img
                          className={
                            "max-md:max-h-[310px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/vicBig.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={"Вакцины нэгдсэн систем - VIC"}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[165px]">
                          <div
                            className="text-brand-50 font-sans"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="grid  grid-cols-3 max-sm:grid-cols-1 text-brand-50 font-semibold bg-white divide-x-2 divide-y-2">
                      <div>
                        <img
                          className={"h-[226px]"}
                          src={"/images/project/vicSmall1.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                        <div className="py-6 flex items-start h-40 max-sm:h-fit">
                          <div className="w-3 h-7 bg-brand-50"></div>
                          <div
                            className={"leading-5 text-justify pl-6 w-11/12 "}
                          >
                            Монгол улсын иргэдийг КОВИД-19 вакцинд хамруулах үйл
                            ажиллагааг зохион байгуулж иргэдэд мэдээлэл түргэн
                            шуурхай хүргэх, цар тахлын дэглэмийг баримталж
                            вакцинжуулах.
                          </div>
                        </div>
                      </div>
                      <div>
                        <img
                          className={"h-[226px]"}
                          src={"/images/project/vicSmall2.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                        <div className="py-6 flex items-start h-50 max-sm:h-fit">
                          <div className="w-3 h-7 bg-brand-50"></div>
                          <div
                            className={"leading-5 text-justify pl-6 w-11/12"}
                          >
                            Эрүүл мэндийн байгууллагад вакцин түгээлтийн ажлыг
                            зохион байгуулах, шинж тэмдгийн асуумж авах,
                            дархлаажуулалт бүртгэх, нэгдсэн мэдээллийн хянах
                            самбар үүсгэх, сертификат олгож ажиллах шаардлага
                            үүссэн бөгөөд иргэд цахимаар сертификатаа хэвлэн
                            авснаар орон нутаг болон гадаадад зорчих хөдөлгөөн
                            нээлттэй болсон.
                          </div>
                        </div>
                      </div>
                      <div>
                        <img
                          className={"h-[226px]"}
                          src={"/images/project/vicSmall3.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                        <div className="py-6 flex items-start h-40 max-sm:h-fit">
                          <div className="w-3 h-7 bg-brand-50"></div>
                          <div
                            className={"leading-5 text-justify pl-6 w-11/12 "}
                          >
                            Мөн вакцины дараах урвал хүндрэлийг хянах, зорилтот
                            бүлгийг вакцинжуулах төлөвлөгөө, вакцины бүртгэл
                            хийх, улсын хэмжээнд дархлаажуулалтын нэгдсэн тоон
                            судалгаа тайланг харах боломж бүрдсэн.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 197 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full md:h-[311px]">
                        <img
                          className={
                            "max-md:max-h-[310px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/currencySmall.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={
                            "Валютын гүйлгээний нэгдсэн систем - MULTICURRENCY"
                          }
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[165px] pb-8 md:pb-0">
                          <div
                            className="text-brand-50 font-sans"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div className={"grid grid-cols-1 md:grid-cols-2 "}>
                      <div className="max-md:w-full order-1 md:order-0 h-[311px]">
                        <Title name={"Систем нэвтрэхээс өмнө ба дараа:"} />
                        <div className="font-inter md:mt-6 font-[400] text-[#000000] text-[14px] px-12 text-justify py-10 md:py-0">
                          Монгол улсад хэрэгжиж буй гадаадын зээл, тусламжийн
                          хөрөнгөөр үйл ажиллагаа эрхлэгч төслүүд нь арилжааны
                          банканд гадаад валютыг байршуулан ашигладаг байсан.
                          Төслийн нягтлан бодогч цаасан төлбөрийн хүсэлтийг
                          төрийн сангийн төлбөр тооцооны мэргэжилтэнд биеэр
                          хүргэж ирдэг байсныг системээр зарлага шивүүлэн төлбөр
                          тооцооны мэргэжилтэн хянан баталгаажуулах үйл
                          ажиллагаа системээр хийгддэг болгосон.
                        </div>
                      </div>
                      <div className="max-md:w-full md:order-last bg-[#E1E6EB] h-[311px]">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/currencyBig.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 198 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full md:h-[311px]">
                        <img
                          className={
                            "max-md:max-h-[310px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/multiBig.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={
                            "Төрийн албаны цалингийн нэгдсэн систем - PAYROLL"
                          }
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify h-[140px] xl:h-[165px]">
                          <div
                            className="text-brand-50 font-sans"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div className={"flex max-md:flex-col-reverse h-fit"}>
                      <div className="grid md:grid-cols-2 grid-cols-1">
                        <div>
                          <img
                            style={{
                              objectFit: "cover",
                            }}
                            className="w-full h-[300px]"
                            src={"/images/project/multiSmall2.jpg"}
                            alt="ITC GOV"
                            width={1000}
                            height={1000}
                          />
                          <div>
                            <Title name="Систем нэвтрэхээс өмнө:" />
                            <div className="px-9 pb-5 text-black">
                              <div className="flex">
                                <img
                                  className="h-fit mx-3 mt-2"
                                  src={"/images/icon/check.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p>
                                  Төсвийн зардлын хэдэн хувийг цалинд зарцуулж
                                  байгааг нэгдсэн сангаас мэдэх боломжгүй
                                </p>
                              </div>
                              <div className="flex mt-4">
                                <img
                                  className="h-fit mx-3 mt-2"
                                  src={"/images/icon/check.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-justify">
                                  Нягтлан бодогч цалин бодолт хийсний дараа
                                  Төлбөрийн хүсэлтийг байгууллагын дарга болон
                                  харьяа төрийн санд цаасаар баталгаажуулан
                                  гүйлгээ хийлгэсний дараагаар арилжааны банканд
                                  дахин гүйлгээ хийдэг байсан.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img
                            style={{
                              objectFit: "cover",
                            }}
                            className="w-full h-[300px]"
                            src={"/images/project/multiSmall1.jpg"}
                            alt="ITC GOV"
                            width={1000}
                            height={1000}
                          />
                          <div className="bg-[#031842] h-[300px]">
                            <Title
                              color="brand-50"
                              name="Систем нэвтэрсний дараа:"
                            />
                            <div className="px-9 pb-10">
                              <div className="flex">
                                <img
                                  className="h-fit mx-3 mt-1 mb-4"
                                  src={"/images/icon/checkWhite.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-white">
                                  Нийт төрийн албан хаагчдын цалингийн нэгдсэн
                                  сан үүсгэх,
                                </p>
                              </div>
                              <div className="flex mt-4">
                                <img
                                  className="h-fit mx-3 mt-1"
                                  src={"/images/icon/checkWhite.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-justify text-white">
                                  Төрийн албаны зөвлөлийн хүний нөөцийн
                                  мэдээлэлтэй уялдуулах, төсөвт байгууллагууд
                                  цалин тооцоолох, албан хаагчдад цалингийн
                                  дэлгэрэнгүй мэдээлэл илгээх болон олгох үйл
                                  ажиллагааг системээр хийдэг болсон.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 199 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full md:h-[311px]">
                        <img
                          className={
                            "max-md:max-h-[310px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/balanceBig.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={"Цахим санхүүгийн тайлангийн систем - EBALANCE"}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[165px] pb-10 md:pb-0">
                          <div
                            className="text-brand-50 font-sans"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div className={"flex max-md:flex-col-reverse h-fit"}>
                      <div className="grid md:grid-cols-2 grid-cols-1">
                        <div>
                          <img
                            style={{
                              objectFit: "cover",
                            }}
                            className="w-full h-[300px]"
                            src={"/images/project/balanceSmall1.jpg"}
                            alt="ITC GOV"
                            width={1000}
                            height={1000}
                          />
                          <div>
                            <Title name="Систем нэвтрэхээс өмнө:" />
                            <div className="px-9 pb-10">
                              <div className="flex">
                                <img
                                  className="h-fit mx-3 mt-2 md:mt-7 mb-4"
                                  src={"/images/icon/check.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-justify pb-0 md:pt-6 md:pb-10">
                                  {" "}
                                  Монгол улсад үйл ажиллагаа эрхлэгч хуулийн
                                  этгээдүүд жилд 2 удаа өөрийн статусаас
                                  хамааран санхүүгийн тайлан илгээдэг ба шинэ
                                  хуулийн этгээдийн бүртгэл хийлгэх, тайлангийн
                                  залруулга хийлгэх үйл ажиллагааг заавал харьяа
                                  бүртгэлийн мэргэжилтэнтэй биеэр уулзаж цаасан
                                  баримт материал бүрдүүлдэг байсан.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img
                            style={{
                              objectFit: "cover",
                            }}
                            className="w-full h-[300px]"
                            src={"/images/project/balanceSmall2.jpg"}
                            alt="ITC GOV"
                            width={1000}
                            height={1000}
                          />
                          <div className="bg-[#031842]">
                            <Title
                              color="brand-50"
                              name="Систем нэвтэрсний дараа::"
                            />
                            <div className="px-9 pb-11">
                              <div className="flex pb-1">
                                <p className="text-white text-justify pl-9 font-[700]">
                                  ИБаланс системд УБЕГ-н ХУР, ТЕГ-н
                                </p>
                              </div>
                              <div className="flex pb-1">
                                <img
                                  className="h-fit mx-3 mt-1"
                                  src={"/images/icon/checkWhite.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-white text-justify">
                                  Татварын удирдлагын систем
                                </p>
                              </div>
                              <div className="flex pb-1">
                                <img
                                  className="h-fit mx-3 mt-1"
                                  src={"/images/icon/checkWhite.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-white text-justify">
                                  ТЕГ-Цахим татварын систем
                                </p>
                              </div>
                              <div className="flex pb-1">
                                <img
                                  className="h-fit mx-3 mt-1"
                                  src={"/images/icon/checkWhite.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-white text-justify">
                                  Нэвтрэлтийн нэгдсэн систем
                                </p>
                              </div>
                              <div className="flex pb-1">
                                <img
                                  className="h-fit mx-3 mt-1"
                                  src={"/images/icon/checkWhite.png"}
                                  alt="ITC GOV"
                                  width={12}
                                  height={7}
                                />
                                <p className="text-white text-justify">
                                  Зөвшөөрлийн нэгдсэн системтэй  мэдээлэл
                                  солилцох холболт хийгдсэнээр хуулийн этгээд
                                  бүртгүүлэх, тайлангийн залруулга, буцаалт хийх
                                  үйл ажиллагаа системээр хийгддэг болсон.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : post[0].hdrId === 204 ? (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col bg-[#D5D9E5]">
                      <div className="w-1/2 max-md:w-full md:h-[311px]">
                        <img
                          className={
                            "max-md:max-h-[310px] max-sm:max-h-[180px]"
                          }
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/project/financePortal0.jpg"}
                          alt="ITC GOV"
                          width={1000}
                          height={1000}
                        />
                      </div>
                      <div className="w-1/2 max-md:w-full h-fit">
                        <Title
                          name={"ЭРХИЙН БҮРТГЭЛИЙН САН"}
                          bgColor={"#D5D9E5"}
                        />
                        <div className="px-9 text-justify xl:h-[165px] pb-10 md:pb-0">
                          <div
                            className="text-brand-50 font-sans leading-[16px]"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4 bg-white">
                    <div className={"flex max-md:flex-col-reverse h-fit"}>
                      <div className="grid md:grid-cols-2 gap-10 grid-cols-1 p-10 md:p-12">
                        <div className="divide-y">
                          <div>
                            <div className="font-[700] text-[25px] text-[#0C2C52]">
                              2020 онд
                            </div>
                            <p className="text-justify font-[400] pr-0 md:pr-9 pt-6 pb-4 leading-[16px]">
                              Эрхийн бүртгэлийн сан системийг "Төрийн албаны
                              цалингийн нэгдсэн систем"-ийн төлбөрийн хүсэлтийг
                              төсөвт байгууллага цахимаар баталгаажуулахын тулд
                              "Төрийн сангийн баталгаажуулалтын" мэдээллийн сан
                              үүсгэх шаардлага үүсэн хөгжүүлэлтийг эхлүүлсэн.
                              Тус шаардлагын хүрээнд "Засгийн газрын санхүүгийн
                              удирдлагын мэдээллийн систем"-ээс төсөвт
                              байгууллага, дансны мэдээлэл, "Татварын удирдлагын
                              нэгдсэн систем"-ээс төсөл сангийн байгууллагын
                              мэдээлэл, "Төрийн албаны зөвлөлийн систем"-ээс
                              төсөвт байгууллагын ажилтны мэдээлэл татан
                              бүртгэлцүүлж дансны баталгаажуулалтын тохиргоог
                              хийн нэгдсэн сан үүсгэж эхэлсэн.
                            </p>
                          </div>

                          <div>
                            <div className="font-[700] mt-4 text-[25px] text-[#0C2C52]">
                              2022 онд
                            </div>
                            <p className="text-justify pr-0 md:pr-9 font-[400] pt-6 pb-6">
                              "Төрийн сангийн удирдлагын цахим систем"-ийн
                              нэвтрүүлэлттэй холбоотой төрийн сангийн
                              хэрэглэгчийн эрхийг нэгтгэн системийн хамрах
                              хүрээг өргөтгөсөн.
                            </p>
                          </div>

                          <div className="">
                            <div className="font-[700] pt-4 text-[25px] text-[#0C2C52]">
                              2023 оны байдлаар
                            </div>
                            <div className="font-[600] pt-6 font-inter text-[14px]">
                              Сангийн яамны
                            </div>
                            <div className="mt-6">
                              <span className="font-[700] mt-24 font-inter text-[30px] pr-2">
                                388
                              </span>
                              төрийн сангийн нэгж
                            </div>
                            <div className="mt-6">
                              <span className="font-[700]  font-inter text-[30px] pr-2">
                                4530
                              </span>
                              төсвийн байгууллагын
                            </div>
                            <div className="mt-6">
                              <span className="font-[700] mt-4 font-inter text-[30px] pr-2">
                                13
                              </span>
                              мянга гаруй хэрэглэгчийн эрхийг зохицуулан
                            </div>
                            <div className="text-justify pt-6 pr-0 md:pr-9">
                              "Төрийн сангийн удирдлагын цахим систем", "Төрийн
                              албаны цалингийн нэгдсэн систем", "Хөрөнгө
                              оруулалтын цахим систем" -ийн 30,000 гаруй дансны
                              баталгаажуулалтыг өдөр бүр цахимаар гүйцэтгэж
                              байна.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <img
                              className="w-full md:w-[465px] h-full md:h-[361px]"
                              style={{
                                objectFit: "cover",
                              }}
                              src={"/images/project/financePortal1.jpg"}
                              alt="ITC GOV"
                              width={1000}
                              height={1000}
                            />
                          </div>
                          <div className="md:relative hidden md:block">
                            <img
                              className="w-full md:absolute md:-right-3 md:-top-12 md:w-[465px] h-full md:h-[361px]"
                              style={{
                                objectFit: "cover",
                              }}
                              src={"/images/project/financePortal2.jpg"}
                              alt="ITC GOV"
                              width={1000}
                              height={1000}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex max-md:flex-col-reverse bg-brand-50">
                      <div className="w-2/3 max-md:w-full">
                        <Title
                          name={
                            post[0].hdrDescription +
                            " - " +
                            post[0].projectTitle
                          }
                          color={"brand-50"}
                        />
                        <div className="px-9 text-justify pb-10">
                          <div
                            className="text-white"
                            dangerouslySetInnerHTML={{
                              __html: post[0].description,
                            }}
                          ></div>
                        </div>
                      </div>

                      <div className="w-1/3 max-md:w-full max-sm:hidden">
                        <img
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          src={"/images/background/office.jpg"}
                          alt="ITC GOV"
                          width={1400}
                          height={1000}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[1280px] mx-auto mt-4">
                    <div className="flex gap-4">
                      <div className="bg-white pb-20">
                        <div className="px-9">
                          {post[0].documentTitle ? (
                            <div className="text-brand-50 mt-10">
                              <Collapse
                                defaultActiveKey={() => {
                                  let array = [];
                                  for (let i = 0; i < post.length; i++) {
                                    array.push(i);
                                  }
                                  return array;
                                }}
                                style={{
                                  background: "#fff",
                                  borderRadius: "0px",
                                  borderRight: "0px",
                                  borderLeft: "0px",
                                  borderTop: "1px solid #ededed",
                                  borderBottomLeftRadius: "0px",
                                }}
                                expandIconPosition="start"
                                items={post.map((e, i) => ({
                                  key: i,
                                  label: (
                                    <div>
                                      <div className="text-lg font-semibold text-brand-50">
                                        {e.documentTitle}
                                      </div>
                                    </div>
                                  ),
                                  children: (
                                    <>
                                      {renderImage(e)}
                                      <div>
                                        <div
                                          style={{ padding: "15px" }}
                                          className="name text-brand-50 px-2"
                                          dangerouslySetInnerHTML={{
                                            __html: e.document,
                                          }}
                                        ></div>
                                      </div>
                                    </>
                                  ),
                                }))}
                                // defaultActiveKey={["1"]}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <ProjectHeader />
              {/* <ProjectDir /> */}
            </SimpleMotion>
            {/* <div className="relative h-[250px]">
              <div className="absolute bottom-0 w-full h-56">
                <Footer />
              </div>
            </div> */}
          </div>
        ) : (
          <div className="mt-4">
            <Empty />
          </div>
        )}
      </Spin>
    </SimpleMotion>
  );
};
export default ProjectDetail;
