import React, { useEffect, useState } from "react";
import { Breadcrumb, Layout, Spin, theme } from "antd";
import { Route, Routes, useLocation } from "react-router-dom";
import MainHeader from "./header/MainHeader";
import MainSider from "./MainSider";
import MainFooter from "./MainFooter";
import { getTokenInfo, postService } from "./tools";
import { menuComponentUrl } from "./serviceUrls";
import FeedBack from "./components/adminComponents/FeedBack";
import WorkList from "./components/userComponents/WorkList";
import Cv from "./components/adminComponents/Cv";
import News from "./components/adminComponents/News";
import Team from "./components/adminComponents/Team";
import Project from "./components/adminComponents/Project";
import Transparency from "./components/adminComponents/Transparency";
import JobAdsComp from "./components/adminComponents/jobAds";

const { Content } = Layout;

export default function MainLayout() {
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loggedInfo, setLoggedInfo] = useState({});
  const [admin, setAdmin] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const checkIsAdmin = (roles) => {
    setLoading(true);
    let admin = false;
    postService(menuComponentUrl.getRoleMenu)
      .then((res) => {
        localStorage.setItem("menuList", JSON.stringify(res));
      })
      .finally(() => {
        setLoading(false);
      });
    if (roles) {
      if (roles.length !== 0) {
        roles.forEach((element) => {
          if (element === "HR" || element === "ADMIN") {
            admin = true;
          }
        });
        setAdmin(admin);
      }
    }
  };

  useEffect(() => {
    let tokenInfo = getTokenInfo();
    if (tokenInfo["resource_access"]) {
      checkIsAdmin(tokenInfo["resource_access"]["itc.gov.mn"]?.roles);
      setLoggedInfo(tokenInfo);
      // clearLocalStorage();
    }
  }, []);

  const getRoutes = () => {
    return (
      <Routes>
        {admin ? (
          <>
            <Route path="/feedback" element={<FeedBack />} />
            <Route path="/cv" element={<Cv />} />
            <Route path="/news" element={<News />} />
            <Route path="/team" element={<Team />} />
            <Route path="/project" element={<Project />} />
            <Route path="/transparency" element={<Transparency />} />
            <Route path="/pushpin" element={<JobAdsComp/>}/>
          </>
        ) : (
          <Route path="/workList" element={<WorkList />} />
        )}
      </Routes>
    );
  };

  return (
    <Spin spinning={loading}>
      <Layout hasSider>
        <MainSider collapsed={collapsed} />
        <Layout style={{ marginLeft: collapsed ? "81px" : "201px" }}>
          <MainHeader
            collapsed={collapsed}
            setCollapsed={(value) => setCollapsed(value)}
          />
          <Content
            style={{
              marginLeft: "16px",
              marginTop: "8px",
              marginRight: "16px",
            }}
          >
            <Breadcrumb
              style={{ marginBottom: "8px" }}
              items={[
                { title: window.location.hostname },
                { title: location.pathname.replace(/\//g, "") },
              ]}
            />
            <div style={{ minHeight: window.innerHeight - 169 }}>
              {getRoutes()}
            </div>
          </Content>
          <MainFooter />
        </Layout>
      </Layout>
    </Spin>
  );
}
