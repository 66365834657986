import React, { useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    Select,
    InputNumber,
    Skeleton,
    Collapse,
    Progress,
    message,
    Checkbox,
    Upload,
} from "antd";
import axios from "axios";
import { EditOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {postService} from "../../tools";
import {projectUrl} from "../../serviceUrls";
const { TextArea } = Input;
const { Panel } = Collapse;
const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 24,
        offset: 0,
    },
};
const formItemLayoutBtn = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 26,
        offset: 4,
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        span: 26,
        offset: 4,
    },
};
const ProjectEdit = ({ getProject, data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileListBgImg, setFileListBgImg] = useState([]);
    const [confirmFiles, setConfirmFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadinghdrDtl, setLoadingData] = useState(false);
    const [getHdrDtl, setHdrDtl] = useState();
    const [valueDoc, setValue] = useState([{ data: "" }]);
    const [docList, setDocList] = useState([]);
    const [progressBg, setProgressBg] = useState(0);
    const [projectDocumentId, setProjectDocumentId] = useState(0);
    const [disableSpan, setDisableSpan] = useState(false);
    const [disableCarousel, setDisableCarousel] = useState(false);

    /**
     * Төслийн мэдээллийг авах
     **/
    const getService = () => {
        const header = {
            Authorization: "Bearer " + localStorage.getItem("token"),
        };
        const body = {
            hdrId: data.id,
        };
        setLoadingData(true);
        postService(projectUrl.getProjectDetailDoc, body)
            .then((res) => {
                setHdrDtl(res.data);
                setValue(res.data.description);
                setDocList(res.data.projectDocumentList);
            })
            .catch(() => {})
            .finally(() => {
                setLoadingData(false);
            });
    };

    const showModal = () => {
        getService();
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    /**
     * Төслийн мэдээллийг шинэчлэх
     **/
    const onFinish = (values) => {
        const body = {
            id: values.id,
            title: values.title,
            description: valueDoc,
            hdrDescription: values.hdrDescription,
            image: "",
            logoGray: "",
            status: values.status,
            projectDocumentList: docList,
        };
        setLoading(true);
        postService(projectUrl.projectHdrDtlUpdate, body)
            .then((res) => {
                message.success(res.data.msg);
            })
            .catch((err) => {
                message.success("error");
            })
            .finally(() => {
                setLoading(false);
                getProject();
                setIsModalOpen(false);
            });
    };

    const editorFunc = (e, index) => {
        const listDoc = [...docList];
        listDoc[index].document = e;
        setDocList(listDoc);
    };

    const requirementFunc = (e, index) => {
        const list = [...docList];
        list[index].documentTitle = e.target.value;
        setDocList(list);
    };

    const handleServiceRemove = (data, index) => {
        const body = {
            id: data.id,
        };
        postService(projectUrl.deleteProjectDocument, body)
            .then((res) => {
                const list = [...docList];
                list.splice(index, 1);
                setDocList(list);
            })
            .catch((err) => {
                message.success("error");
            });
    };

    const addDocument = () => {
        let list = [];
        if (docList) {
            list = [...docList];
        }
        list.push({
            span: null,
            document: "",
            isCarousel: null,
            documentTitle: "",
            projectDocumentList: [],
        });
        setDocList(list);
    };

    /**
     * Slide мөн эсэхийг зарлах
     **/
    const changeIsCarousel = (value, index) => {
        if (value.target.checked) {
            setDisableSpan(true);
        } else {
            setDisableSpan(false);
        }
        const list = [...docList];
        list[index].isCarousel = value.target.checked;
        setDocList(list);
    };

    const uploadFileBgImg = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        if (file.type !== "image/png" && file.type !== "image/jpeg") {
            message.error(` Заавал зураг оруулна уу`);
            setFileListBgImg([]);
        } else {
            const fmData = new FormData();
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
                onUploadProgress: (event) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    setProgressBg(percent);
                    if (percent === 100) {
                        setTimeout(() => setProgressBg(0), 1000);
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                },
            };
            fmData.append("file", file);
            const res = await axios.post(`/api/upload`, fmData, config);
            file.url = `/api/v1/getFile?file=${res.data}`;
            if (res.status == 200) {
                message.success("Амжилтай файл хууллаа");
            }
            onSuccess("Ok");
            saveProjectDocumentFile(res.data, projectDocumentId);
        }
    };

    const saveProjectDocumentFile = (fileUrl, id) => {
        const body = {
            projectDocumentId: id,
            fileStoreId: fileUrl,
            status: 1,
        };
        const header = {
            Authorization: "Bearer " + localStorage.getItem("token"),
        };
        setLoading(true);
        postService(projectUrl.getProjectDetailDoc, body)

            .then((res) => {
                docList.forEach((element) => {
                    if (element.id === id) {
                        element.projectDocumentFilesList.push(res.data);
                    }
                });
            })
            .catch((err) => {
                message.success("error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const setSpan = (value, index) => {
        if (!value) {
            setDisableCarousel(false);
        } else {
            setDisableCarousel(true);
        }
        const list = [...docList];
        list[index].span = value;
        setDocList(list);
    };

    const deleteImage = (data, projectDocumentId) => {
        const body = {
            id: data.id,
        };
        const header = {
            Authorization: "Bearer " + localStorage.getItem("token"),
        };
        axios
            .post("/api/postBT/project/deleteProjectDocumentFile", body, {
                headers: header,
            })
            .then((res) => {
                message.success(res.data.msg);
                getService();
            })
            .catch((err) => {
                message.success("error");
            });
    };

    // const uploadTest = (values) => {
    //   const formData = new FormData();
    //   if (confirmFiles) {
    //     confirmFiles.forEach((file) => {
    //       formData.append("fileId", file.originFileObj, file.name);
    //     });
    //   }
    //   formData.append("name", values.name);
    //   formData.append("code", values.code);
    //   formData.append("approvedDate", values.approvedDate.format(dateFormat));
    //   formData.append("newsId", newsId);
    //   postServiceWithFile("", formData)
    //     .then((result) => {
    //       if (result) message.success("Амжилттай");
    //       onSubmit();
    //     })
    //     .catch((err) => {
    //       setExceptionMessage(getExceptionObj(err));
    //     });
    // };
    return (
        <div>
            <Button
                size="small"
                type="primary"
                onClick={showModal}
                className="bg-brand-50"
                icon={<EditOutlined />}
            ></Button>
            <Modal
                title="Төсөл засах"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={1000}
            >
                {loadinghdrDtl ? (
                    <Skeleton active />
                ) : (
                    <Form
                        name="basic"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            maxWidth: 1000,
                        }}
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            id: data.id,
                            title: data.title,
                            hdrDescription: data.description,
                            // image: data.image,
                            // logoGray: data.logoGray,
                            status: data.status,
                        }}
                        disabled={loading}
                    >
                        <Form.Item label="ID" name="id">
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item
                            label="Гарчиг"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Гарчиг аа оруулна уу!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Cистем дэлгэрэнгүй"
                            name="hdrDescription"
                            rules={[
                                {
                                    required: true,
                                    message: "Cистем дэлгэрэнгүй оруулна уу!",
                                },
                            ]}
                        >
                            <TextArea style={{ height: "150px" }} />
                        </Form.Item>
                        <Form.Item
                            label="Төрөл"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: "Төрөл өө оруулна уу!",
                                },
                            ]}
                        >
                            <Select
                                style={{
                                    width: 200,
                                }}
                                // onChange={handleChangeSel}
                                options={[
                                    {
                                        value: 0,
                                        label: <div className="text-red-500">Идэвхгүй</div>,
                                    },
                                    {
                                        value: 1,
                                        label: <div className="text-green-500">Идэвхтэй</div>,
                                    },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item label="Дэлгэрэнгүй">
                            <div className="mt-4">
                                <Editor
                                    apiKey={"wmksu0je4for1l2gfdhj8pu1422sb7wdsrrzukw95vl85d70"}
                                    onEditorChange={(newValue, editor) => {
                                        setValue(newValue);
                                    }}
                                    onInit={(evt, editor) => {}}
                                    value={valueDoc}
                                    init={{
                                        plugins:
                                            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                        toolbar:
                                            "undo redo | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                    }}
                                />
                            </div>
                        </Form.Item>

                        <Form.List>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {docList?.map((field, index) => (
                                        <Form.Item
                                            {...(index === 0
                                                ? formItemLayout
                                                : formItemLayoutWithOutLabel)}
                                            label={index === 0 ? "Дэлгэрэнгүй" : ""}
                                            required={false}
                                            key={index}
                                        >
                                            <Form.Item noStyle>
                                                <Input
                                                    placeholder="Гарчиг"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    value={field.documentTitle}
                                                    onChange={(e) => requirementFunc(e, index)}
                                                />

                                                <div className="mt-4">
                                                    <InputNumber
                                                        min={1}
                                                        max={24}
                                                        placeholder="Дүрслэх хэмжнээ"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        value={field.span}
                                                        // disabled={disableSpan}
                                                        onChange={(e) => setSpan(e, index)}
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <Checkbox
                                                        checked={field.isCarousel}
                                                        onChange={(e) => changeIsCarousel(e, index)}
                                                        // disabled={disableCarousel}
                                                    >
                                                        Слайд эсэх
                                                    </Checkbox>
                                                </div>
                                                <div className="mt-4">
                                                    <Form.Item name="background" className="-mb-3">
                                                        <Upload
                                                            key={index}
                                                            style={{ display: "flex" }}
                                                            multiple={false}
                                                            accept="jpg"
                                                            customRequest={uploadFileBgImg}
                                                            onChange={({ fileList }) => {
                                                                setFileListBgImg(fileList);
                                                                setProjectDocumentId(field.id);
                                                            }}
                                                            listType="picture-card"
                                                            fileList={field.projectDocumentFilesList}
                                                            onRemove={(file) => {
                                                                deleteImage(file, field.id);
                                                            }}
                                                        >
                                                            Зураг нэмэх
                                                        </Upload>
                                                        {progressBg > 0 ? (
                                                            <Progress percent={progressBg} />
                                                        ) : null}
                                                        {/* <FileUpload
                              files={confirmFiles}
                              mode="single"
                              setFiles={(f) => {
                                uploadFileContoller(f);
                                setConfirmFiles(f);
                              }}
                            /> */}
                                                    </Form.Item>
                                                </div>
                                                <div className="mt-4">
                                                    <Editor
                                                        apiKey={"wmksu0je4for1l2gfdhj8pu1422sb7wdsrrzukw95vl85d70"}
                                                        onEditorChange={(e) => editorFunc(e, index)}
                                                        onInit={(evt, editor) => {}}
                                                        value={field.document}
                                                        init={{
                                                            plugins:
                                                                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                                                            toolbar:
                                                                "undo redo | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                                                        }}
                                                    />
                                                </div>
                                            </Form.Item>
                                            <div
                                                className=" font-semibold flex gap-1 mt-2 cursor-pointer hover:text-red-500"
                                                onClick={() => handleServiceRemove(field, index)}
                                            >
                                                <MinusCircleOutlined className="text-red-500" />
                                                Устгах
                                            </div>
                                        </Form.Item>
                                    ))}
                                    <Form.Item {...formItemLayoutBtn}>
                                        <Button
                                            type="dashed"
                                            onClick={() => addDocument()}
                                            style={{
                                                width: "100%",
                                            }}
                                            icon={<PlusOutlined />}
                                        >
                                            Дэлгэрэнгүй нэмэх
                                        </Button>
                                        <Form.ErrorList errors={errors} />
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 20,
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="bg-blue-500"
                                size="large"
                            >
                                Шинэчлэх
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </div>
    );
};
export default ProjectEdit;