import { Spin } from "antd";
import { useEffect, useState } from "react";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import ProjectComp from "./ProjectComp";
import Programm from "./Programm";
import Iso from "./Iso";
import News from "./News";

export const LandingPage = () => {
  const [loading, setLoading] = useState(false);

  const getOrganizationInfo = () => {
    // setLoading(false);
    // postServicePublic(newsUrl.getNewsActive)
    //   .then((result) => {
    //     if (result) setOrganization(result);
    //   })
    //   .finally(() => setLoading(false));
  };
  useEffect(() => {
    getOrganizationInfo();
  }, []);

  return (
    <Spin spinning={loading}>
      <SimpleMotion>
        <News isHomePage={true} />
      </SimpleMotion>
      <SimpleMotion>
        <ProjectComp />
      </SimpleMotion>
      <SimpleMotion>
        <Programm />
      </SimpleMotion>
      <SimpleMotion>
        <Iso />
      </SimpleMotion>
    </Spin>
  );
};
