import { Divider, Table, Empty, Button, Input } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
// import Delete from "./delete";
import { ReloadOutlined } from "@ant-design/icons";
import { postService } from "../../tools";
import { mainUrl } from "../../serviceUrls";
import FeedbackDelete from "./FeedbackDelete";
// import Add from "@/components/dashboardComp/image/add";

const FeedBack = (props) => {
  const [data, setData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  useEffect(() => {
    getdata();
  }, []);
  const getdata = async () => {
    setLoadingTable(true);
    const body = {
      page: 0,
      size: 10,
    };
    postService(mainUrl.getFeedBack, body)
      .then((res) => {
        setData(res.result);
        setTableParams({
          ...tableParams,
          pagination: {
            current: 1,
            pageSize: 10,
            total: res.totalItems,
          },
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setLoadingTable(true);
    const body = {
      page: pagination.current - 1,
      size: pagination.pageSize,
    };
    postService(mainUrl.getFeedBack, body)
      .then((res) => {
        setData(res.result);
        setTableParams({
          ...tableParams,
          pagination: {
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: res.totalItems,
          },
        });
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  const columns = [
    {
      title: <div>Огноо</div>,
      dataIndex: "createDate",
      key: "createDate",
      width: 120,
      fixed: "left",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      ellipsis: true,
    },
    {
      title: <div>Нэр</div>,
      dataIndex: "username",
      key: "username",
      // width: 120,
      ellipsis: true,
    },
    {
      title: <div>Имэйл</div>,
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: <div>Утасны дугаар</div>,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
    },
    {
      title: <div>Хаяг</div>,
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      title: <div>Дэлгэрэнгүй</div>,
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: <div>Үйлдэл</div>,
      key: "id",
      width: 70,
      render: (params) => (
        <div>
          <div className="flex gap-2">
            {/* <Button size="small" icon={<EditOutlined />}></Button> */}
             <FeedbackDelete getdata={getdata} id={params.id}/>
          </div>
        </div>
      ),
    },
  ];

  const data1 = data.map((e, i) => ({
    key: i,
    username: e.username,
    createDate: e.createDate,
    email: e.email,
    description: e.description,
    phoneNumber: e.phoneNumber,
    address: e.address,
    id: e.id,
  }));
  return (
    <div>
      <div className="items-center">
        <div className="w-full mb-2 flex gap-10 justify-end">
          <Button
            onClick={getdata}
            icon={<ReloadOutlined style={{ padding: "0px 5px" }} />}
          ></Button>
        </div>
      </div>
      <Table
        size="middle"
        pagination={tableParams.pagination}
        columns={columns}
        dataSource={data1}
        bordered
        scroll={{ x: 1000, y: 500 }}
        loading={loadingTable}
        onChange={handleTableChange}
        locale={{
          emptyText: <Empty description="Хоосон" />,
        }}
      />
    </div>
  );
};
export default FeedBack;
