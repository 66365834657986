// import {Exo_2} from "next/font/google";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Title from "../Landing/Title";
import { Image } from "antd";
import { getServicePublic } from "../tools";
import { projectUrl } from "../serviceUrls";
// const inter = Exo_2({
//     subsets: ["latin"],
//     weight: "400",
// });

const ManComp = ({ values }) => {
  const [stat, setStat] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [sum, setSum] = useState(0);
  useEffect(() => {
    getServicePublic(projectUrl.getStatData).then((res) => {
      let data = res;
      let baseStat = [];
      baseStat.push(
        values?.filter((x) => x.statCode === "0-15 настай хэрэглэгчийн хувь")[0]
          .statValue
      );
      baseStat.push(
        data?.filter((x) => x.statCode === "16-25 настай хэрэглэгчийн хувь")[0]
          .statValue
      );
      baseStat.push(
        data?.filter((x) => x.statCode === "26-35 настай хэрэглэгчийн хувь")[0]
          .statValue
      );
      baseStat.push(
        data?.filter((x) => x.statCode === "36-45 настай хэрэглэгчийн хувь")[0]
          .statValue
      );
      baseStat.push(
        data?.filter((x) => x.statCode === "46-60 настай хэрэглэгчийн хувь")[0]
          .statValue
      );
      baseStat.push(
        data?.filter(
          (x) => x.statCode === "60-с дээш настай хэрэглэгчийн хувь"
        )[0]?.statValue
      );
      baseStat.push(
        values?.filter(
          (x) =>
            x.statCode === "Эрэгтэй хэрэглэгчийн нийт бүртгүүлсэн баримтын хувь"
        )[0]?.statValue
      );
      baseStat.push(
        values?.filter(
          (x) =>
            x.statCode === "Эмэгтэй хэрэглэгчийн нийт бүртгүүлсэн баримтын хувь"
        )[0]?.statValue
      );
      setStat(baseStat);
      setSum(
        data?.filter((x) => x.statCode === "Нийт хэрэглэгчийн тоо")[0].statValue
      );
    });
  }, []);

  //values ni end oroh utguudiig aguulah array baina
  return (
    <section className={"flex max-md:flex-col"}>
      <div className={"w-2/3 max-md:w-full"}>
        <div className={"flex justify-between items-center "}>
          <Title
            name={
              "Систем хэрэглэгч: " +
              `${values
                ?.filter((x) => x.statCode === "Нийт хэрэглэгчийн тоо")[0]
                .statValue.toLocaleString("en-US")}`
            }
          />
          <div
            className={
              "bg-brand-50 text-white text-base text-nowrap w-[175px] h-fit text-center py-3 mr-6 font-semibold"
            }
          >
            Насны ангилал
          </div>
        </div>
        <div
          className={
            "grid grid-cols-6 max-md:grid-cols-3 max-md:gap-y-2 divide-x-2 divide-dotted divide-gray-200 pt-5 pb-9 bg-white"
          }
        >
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/man-01.png"}
                alt="man-01"
                height={357}
                width={25}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-300 flex justify-center">
              <div className="rounded-full h-3 w-3 bg-brand-50"></div>
            </div>
            <div>
              <div className="text-base">0-15</div>
              <div className="text-2xl font-semibold text-center">
                {
                  values?.filter(
                    (x) => x.statCode === "0-15 настай хэрэглэгчийн хувь"
                  )[0].statValue
                }
                %
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/man-02.png"}
                alt="man-01"
                height={357}
                width={25}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-300 flex justify-center">
              <div className="rounded-full h-3 w-3 bg-brand-50"></div>
            </div>
            <div>
              <div className="text-base">16-25</div>
              <div className="text-2xl font-semibold text-center">
                {
                  values?.filter(
                    (x) => x.statCode === "16-25 настай хэрэглэгчийн хувь"
                  )[0].statValue
                }
                %
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/man-03.png"}
                alt="man-01"
                height={357}
                width={25}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-300 flex justify-center">
              <div className="rounded-full h-3 w-3 bg-brand-50"></div>
            </div>
            <div>
              <div className="text-base">26-35</div>
              <div className="text-2xl font-semibold text-center">
                {
                  values?.filter(
                    (x) => x.statCode === "26-35 настай хэрэглэгчийн хувь"
                  )[0].statValue
                }
                %
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/man-04.png"}
                alt="man-01"
                height={357}
                width={25}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-300 flex justify-center">
              <div className="rounded-full h-3 w-3 bg-brand-50"></div>
            </div>
            <div>
              <div className="text-base">36-45</div>
              <div className="text-2xl font-semibold text-center">
                {
                  values?.filter(
                    (x) => x.statCode === "36-45 настай хэрэглэгчийн хувь"
                  )[0].statValue
                }
                %
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/man-05.png"}
                alt="man-01"
                height={357}
                width={25}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-300 flex justify-center">
              <div className="rounded-full h-3 w-3 bg-brand-50"></div>
            </div>
            <div>
              <div className="text-base">46-60</div>
              <div className="text-2xl font-semibold text-center">
                {
                  values?.filter(
                    (x) => x.statCode === "46-60 настай хэрэглэгчийн хувь"
                  )[0].statValue
                }
                %
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/man-06.png"}
                alt="man-01"
                height={357}
                width={25}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-300 flex justify-center">
              <div className="rounded-full h-3 w-3 bg-brand-50"></div>
            </div>
            <div>
              <div className="text-base">61-с дээш</div>
              <div className="text-2xl font-semibold text-center">
                {
                  values?.filter(
                    (x) => x.statCode === "60-с дээш настай хэрэглэгчийн хувь"
                  )[0].statValue
                }
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"w-1/3 max-md:w-full bg-brand-50"}>
        <div className={"flex justify-end items-center w-full h-[76px]"}>
          <div
            className={
              "bg-white text-brand-50 text-base text-nowrap w-[100px] h-10 text-center pt-2 mr-6 font-semibold"
            }
          >
            Хүйс
          </div>
        </div>
        <div
          className={
            "grid grid-cols-2 divide-x-2 divide-dotted divide-gray-500 pt-5 pb-9 px-9 text-white"
          }
        >
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/people-strok-02.png"}
                alt="man-01"
                height={357}
                width={47}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-500 flex justify-center"></div>
            <div>
              <div className="text-base">Эрэгтэй</div>
              <div className="text-2xl font-semibold text-center">
                {values?.filter(
                  (x) =>
                    x.statCode ===
                    "Эрэгтэй хэрэглэгчийн нийт бүртгүүлсэн баримтын хувь"
                )[0]
                  ? values?.filter(
                      (x) =>
                        x.statCode ===
                        "Эрэгтэй хэрэглэгчийн нийт бүртгүүлсэн баримтын хувь"
                    )[0].statValue
                  : 46}
                %
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mt-6">
              <img
                className="h-fit object-fit"
                src={"/images/man/people-strok-01.png"}
                alt="man-01"
                height={357}
                width={47}
              />
            </div>
            <div className="w-full h-2 mb-6 mt-2 border-b-2 border-dotted border-gray-500 flex justify-center"></div>
            <div>
              <div className="text-base">Эмэгтэй</div>
              <div className="text-2xl font-semibold text-center">
                {values?.filter(
                  (x) =>
                    x.statCode ===
                    "Эмэгтэй хэрэглэгчийн нийт бүртгүүлсэн баримтын хувь"
                )[0]
                  ? values?.filter(
                      (x) =>
                        x.statCode ===
                        "Эмэгтэй хэрэглэгчийн нийт бүртгүүлсэн баримтын хувь"
                    )[0].statValue
                  : 54}
                %
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManComp;
