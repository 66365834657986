import { BrowserRouter } from "react-router-dom";
import MainLayout from "./common/MainLayout";
import { ConfigProvider, theme } from "antd";
import { useEffect, useState } from "react";
import mnMn from "antd/locale/mn_MN";
import "./App.css";
import MainContext from "./common/mainContext";
import { useKeycloak } from "@react-keycloak/web";
import LandingLayout from "./common/LandingLayout";
import mn from "./common/locales/mn";
import en from "./common/locales/en";
import { IntlProvider } from "react-intl";

const messages = {
    mn: mn,
    en: en
};

function App() {
    const { initialized } = useKeycloak();
    const [userTheme, setUserTheme] = useState(localStorage.getItem("theme") || "light");
    const [langChange, setLangChange] = useState("mn");

    useEffect(() => {
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme) setUserTheme(storedTheme);
        const l = localStorage.getItem('language_local');
        if (l) {
            setLangChange(l);
        } else {
            localStorage.setItem('language_local', 'mn');
        }
    }, []);

    const returnLayout = () => {
        let token = localStorage.getItem("token");
        if (initialized || token) {
            return <MainLayout />;
        }
        return <LandingLayout langChange={langChange} setLangChange={setLangChange}/>;
    };

    return (
        <div>
            <IntlProvider messages={messages[langChange]} locale={langChange} defaultLocale="mn">
                <ConfigProvider
                    locale={mnMn}
                    theme={{
                        token: userTheme === "dark"
                            ? { colorPrimary: "#1890ff", borderRadius: 20, textColor: "white" }
                            : { colorPrimary: "#1890ff" },
                        algorithm: userTheme === "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
                    }}
                >
                    <BrowserRouter>
                        <MainContext.Provider value={{ userTheme, setUserTheme }}>
                            {returnLayout()}
                        </MainContext.Provider>
                    </BrowserRouter>
                </ConfigProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
