import { Skeleton, Pagination, Image } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { postServicePublic } from "../tools";
import { newsUrl } from "../serviceUrls";

const News = ({ isHomePage }) => {
  const [posts, setPosts] = useState([]);
  const [load, setLoad] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [totalNewsNum, setTotalNewsNum] = useState(null);
  const [winWidth, setWinWidth] = useState();
  useEffect(() => {
    setLoad(true);
    const body = {
      page: pageNum,
      size: window.innerWidth > 1280 && !isHomePage ? 8 : 4,
    };
    postServicePublic(newsUrl.getNewsActive, body).then((res) => {
      setPosts(res.result);
      setTotalNewsNum(res.totalItems);
      setLoad(false);
    });
    setWinWidth(window.innerWidth);
    window.addEventListener("resize", () => setWinWidth(window.innerWidth));
  }, [pageNum]);

  const onChange = (pageNumber, pageSize) => {
    setPageNum(pageNumber - 1);
  };
  return (
    <div className="bg-white max-w-[1280px] min-h-[260px] md:min-h-[330px] mx-auto mt-4 max-sm:m-0 text-brand-50 text-base font-semibold py-6 px-9 max-md:w-full">
      <div
        className={
          isHomePage
            ? "grid-cols-4 gap-6 overflow-auto w-full flex"
            : "lg:grid grid-cols-4 gap-6 lg:grid-rows-2 overflow-auto w-full max-lg:flex"
        }
      >
        {load ? (
          <>
            <Skeleton className="mb-[52px] md:mb-[125px]" active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : posts ? (
          posts.map((post, i) => (
            <Link
              to={`/news/${post.id}`}
              className="flex flex-col group max-md:w-[160px] w-[284px] max-md:pb-6"
              key={i}
            >
              <div className="relative block overflow-hidden group bg-cover bg-no-repeat bg-gray-100 max-md:w-[160px] w-[284px]">
                <Image
                  preview={false}
                  className="object-cover transition duration-300 ease-in-out group-hover:scale-110 cursor-pointer bg-no-repeat max-md:h-[101px] max-md:w-[160px] h-[180px] w-[284px]"
                  src={`/api/itc-gov-service-new/file/view/${post.image}`}
                  alt="ITC"
                  width={284}
                  height={180}
                  fallback="/images/error.png"
                  style={{ objectFit: "revert" }}
                />
              </div>
              <div className="text-left line-clamp-2 mt-4 group-hover:underline group-hover:underline-offset-4 max-md:truncate">
                {post.title}
              </div>
            </Link>
          ))
        ) : null}
      </div>
      <div
        style={{ textAlign: "center", paddingTop: "10px" }}
      >
        <Pagination
          style={{ display: "block" }}
          defaultCurrent={1}
          total={totalNewsNum}
          responsive={true}
          onChange={onChange}
          pageSize={winWidth > 1280 && !isHomePage ? 8 : 4}
        />
      </div>
    </div>
  );
};
export default News;
