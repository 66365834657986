import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Layout, Spin } from "antd";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import MainFooter from "./MainFooter";
import { FormattedMessage } from "react-intl";
import { landingComponentList } from "./support/LandingComponent";
import LandingMainHeader from "./header/LandingMainHeader";
import { HomeOutlined } from "@ant-design/icons";
import RouterContext from "./Landing/routerContext";

const { Content } = Layout;

export default function LandingLayout({ langChange, setLangChange }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [breadcrumbList, setBreadcrumbList] = useState([]);
  const [routerCont, setRouterCont] = useState([]);

  const getComponent = (componentCode, span, index) => {
    const component = landingComponentList.find(
      (c) => c.componentCode === componentCode
    );
    if (!component.component)
      return (
        <div style={{ paddingTop: "100px", backgroundColor: "red" }}>GG</div>
      );
    return (
      <Col xs={24} sm={24} md={span} lg={span} xl={span} xxl={span} key={index}>
        {component.component}
      </Col>
    );
  };

  const getPublicRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={getComponent("/", 24, 100)} />
        <Route path="/about" element={getComponent("about", 24, 100)} />
        <Route path="/law" element={getComponent("law", 24, 100)} />
        <Route path="/publicity" element={getComponent("publicity", 24, 100)} />
        <Route path="/news" element={getComponent("news", 24, 100)} />
        <Route path="/news/:id" element={getComponent("newsId", 24, 100)} />
        <Route path="/project" element={getComponent("project", 24, 100)} />
        <Route path="/feedback" element={getComponent("feedback", 24, 100)} />
        <Route path="/iso" element={getComponent("iso", 24, 100)} />
        <Route
          path="/human-resource"
          element={getComponent("human-resource", 24, 100)}
        />
        <Route path="/contact" element={getComponent("contact", 24, 100)} />
        <Route path="/job-ads" element={getComponent("job-ads", 24, 100)} />
        <Route
          path="/job-ads/:id"
          element={getComponent("job-adsId", 24, 100)}
        />
        <Route path="/workers" element={getComponent("workers", 24, 100)} />
        <Route
          path="/project/:ProjectId/:ProjectDtl"
          element={getComponent("projectDtl", 24, 100)}
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  };
  const createBreadCrumb = () => {
    setBreadcrumbList([]);
    let pathArray = location.pathname.split("/");
    let model = [
      {
        href: "",
        title: (
          <Link to={"/"} style={{ color: "#252525" }}>
            <HomeOutlined />
            <span> <FormattedMessage id={"home_breadCrumb"}/></span>
          </Link>
        ),
      },
    ];
    pathArray.forEach((element) => {
      if (element !== "") {
        if (isNaN(element)) {
          landingComponentList.forEach((comp) => {
            if (comp.componentCode === element) {
              model.push({
                title: (
                  <Link to={comp.path}>
                    {comp.icon}
                    <span> {comp.title}</span>
                  </Link>
                ),
              });
            }
          });
        } else {
          if (routerCont) {
            if (routerCont.id === element) {
              model.push({
                title: <span> {routerCont.title}</span>,
              });
            }
          }
        }
      }
    });
    setBreadcrumbList(model);
  };
  useEffect(() => {
    createBreadCrumb();
  }, [location, routerCont]);

  return (
    <Spin spinning={false}>
      <RouterContext.Provider value={{ routerCont, setRouterCont }}>
        <Layout hasSider style={{ backgroundColor: "transparent" }}>
          <Layout style={{ backgroundColor: "transparent" }}>
            <LandingMainHeader
              collapsed={collapsed}
              locale={langChange}
              setLocale={setLangChange}
              setCollapsed={(value) => setCollapsed(value)}
            />
            <Breadcrumb
              separator=">"
              style={{
                padding: "1rem 1.5rem 1rem 1.5rem",
                backgroundColor: "white",
                marginTop: "0px",
              }}
              items={breadcrumbList}
            />
            <Content
              style={{
                marginLeft: "16px",
                marginTop: "8px",
                marginRight: "16px",
              }}
            >
              {getPublicRoutes()}
            </Content>
            <MainFooter />
          </Layout>
        </Layout>
      </RouterContext.Provider>
    </Spin>
  );
}
