import { CiOutlined } from "@ant-design/icons";
import { Image } from "antd";
import Title from "../Landing/Title";
import { Link } from "react-router-dom";
import Arrow from "../components/assist/arrow/Arrow";

const CustomWindow = ({ title, value, router, imageUrl, urlText }) => {
  return (
    <div className="flex flex-col text-brand-50 bg-white">
      <div className="relative">
        <img
          className={"max-md:max-h-[250px] max-sm:max-h-[180px]"}
          style={{ objectFit: "cover", height: "100%", width: "100%" }}
          src={imageUrl}
          alt="ITC GOV"
          // layout='fill'
          width={5000}
          height={5000}
        />
      </div>
      <div className={"flex flex-col justify-between"}>
        <div className="h-[70px]">
          <Title name={title} />
        </div>
        <div className="max-h-[320px] xl:h-[180px] lg:h-[180px] md:h-[300px] mb-2">
          <div
            className="px-9 text-justify pb-10 bg-white"
            dangerouslySetInnerHTML={{ __html: value }}
          ></div>
        </div>
        <div className="relative h-[50px] bottom-0">
          <Link
            href={router}
            className="flex items-center group px-9 gap-4 pt-1"
          >
            <div className="group-hover:translate-x-4 duration-300 flex items-center pr-2">
              <Arrow />
            </div>
            <div className="group-hover:underline group-hover:underline-offset-8 text-xs text-gray-600">
              {urlText}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CustomWindow;
