import { Table, Collapse, Spin, Empty } from "antd";
import { useEffect, useState } from "react";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import Title from "./Title";
import { jobadsUrl } from "../serviceUrls";
import { getServicePublic } from "../tools";

const Workers = () => {
  const [employee, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    getEmployee();
  }, []);
  const columns = [
    {
      title: "Овог нэр",
      dataIndex: "fullName",
      key: "fullName",
      width: 300,
    },
    {
      title: "Албан тушаал",
      dataIndex: "jobPosition",
      key: "jobPosition",
      width: 500,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "И-мейл хаяг",
      dataIndex: "email",
      key: "email",
      width: 300,
      sortDirections: ["descend", "ascend"],
    },
  ];
  const getEmployee = () => {
    setLoading(true);
    getServicePublic(jobadsUrl.getEmployees)
      .then((res) => {
        setEmployees(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const renderData = (value) => {
    return (
      <Table
        rowKey="id"
        dataSource={value}
        columns={columns}
        pagination={false}
        size="small"
        locale={{
          emptyText: <Empty description="Хоосон" />,
        }}
      />
    );
  };
  return (
    <div>
      <SimpleMotion>
        <Spin spinning={loading}>
          <div className="max-w-[1280px] overflow-x-scroll sm:overflow-auto mx-auto mt-4 bg-white text-brand-50">
            <div className="w-[1000px] sm:w-full">
              <Title name={"Албан хаагчдын мэдээлэл"} />
              <Collapse
                expandIconPosition="end"
                defaultActiveKey={[0, 1, 2, 3, 4, 5, 6]}
                style={{
                  background: "#fff",
                  borderRadius: "0px",
                  borderRight: "0px",
                  borderLeft: "0px",
                  borderTop: "1px solid #ededed",
                  borderBottomLeftRadius: "0px",
                  padding: "0px 36px 0px 36px",
                }}
                items={employee?.map((e, i) => ({
                  key: i,
                  label: (
                    <div className="text-lg font-semibold text-brand-50">
                      {e.title}
                    </div>
                  ),
                  children: <div>{renderData(e.developerDetails)}</div>,
                }))}
              />
            </div>
          </div>
        </Spin>
      </SimpleMotion>
    </div>
  );
};
export default Workers;
