import React, {useEffect, useState} from 'react';
import {Button, Dropdown, message, theme} from "antd";
import {BellOutlined, LogoutOutlined, PhoneOutlined, SwapOutlined, UserOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {getTokenInfo, renderUserName} from "../tools";
import {useKeycloak} from "@react-keycloak/web";

const UserProfileMenu = () => {
  const navigate = useNavigate();
  const {token: {colorPrimary}} = theme.useToken();
  const {keycloak} = useKeycloak();

  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "UserProfile":
        navigate("/userProfile");
        break;
      case "ChangeRole":
        window.location.reload();
        break;
      case "Notification":
      case "Contact":
        message.info("Хөгжүүлэлт хийгдэж байгаа тул түр хүлээнэ үү.");
        break;
      case "LogOut":
        localStorage.clear();
        void keycloak.logout();
        break;
      default:
        break;
    }
  };

  const items = [
    {
      label: selectedUserName,
      key: 'UserProfile',
      icon: <UserOutlined />,
    },
    {
      label: 'Эрх солих',
      key: 'ChangeRole',
      icon: <SwapOutlined />,
    },
    {
      type: "divider",
    },
    {
      label: 'Мэдэгдэл',
      key: 'Notification',
      icon: <BellOutlined />,
    },
    {
      label: 'Холбоо барих',
      key: 'Contact',
      icon: <PhoneOutlined />,
    },
    {
      type: "divider",
    },
    {
      label: 'Гарах',
      key: 'LogOut',
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    const loggedUser = getTokenInfo();
    const positionGroup = loggedUser['user-org-group'];
    const name = renderUserName({
      firstname: loggedUser.given_name,
      lastname: loggedUser.family_name,
      positionGroup: positionGroup
    });
    setSelectedRoleName(name);
    setSelectedUserName(renderUserName({firstname: loggedUser.given_name, lastname: loggedUser.family_name}));
  }, []);

  return (
    <Dropdown menu={menuProps} placement="bottomLeft">
      <Button
        icon={<UserOutlined />}
        type="link"
        style={{color: colorPrimary}}
      >
        {selectedRoleName}
      </Button>
    </Dropdown>
  );
};

export default UserProfileMenu;
