import { Table, Empty, Button, Tooltip, Badge, Space } from "antd";
import { useEffect, useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { getServicePublic } from "../../tools";
import { publicityUrl } from "../../serviceUrls";
import TransparencyDelete from "./TransparencyDelete";
import TransparencyAdd from "./TransparencyAdd";

const Transparency = () => {
    const [data, setData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);

    const tableParams = {
        pagination: {
            current: 1,
            pageSize: 10,
        },
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoadingTable(true);
        try {
            const res = await getServicePublic(publicityUrl.getPublicityList);
            setData(res);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingTable(false);
        }
    };

    const columns = [
        {
            title: "Нэр",
            dataIndex: "name",
            key: "name",
            width: 420,
            ellipsis: true,
        },
        {
            title: "Төлөв",
            dataIndex: "status",
            key: "status",
            width: 120,
            render: (status) => (
                <Tooltip title={status === 1 ? "Идэвхтэй" : "Идэвхгүй"}>
                    <Badge
                        status={status === 1 ? "success" : "error"}
                        text={status === 1 ? "Идэвхтэй" : "Идэвхгүй"}
                    />
                </Tooltip>
            ),
            ellipsis: true,
        },
    ];

    const dataSource = data.map((item, index) => ({
        key: index,
        name: item.name,
        status: item.status,
        id: item.id,
        hdrs: item.pubDocumentList,
    }));

    const expandedRowRender = (record) => {
        const subColumns = [
            {
                title: "Date",
                dataIndex: "createdDate",
                key: "createdDate",
                width: 140,
            },
            {
                title: "Нэр",
                dataIndex: "name",
                key: "name",
            },
            {
                title: "Төлөв",
                key: "status",
                render: (text, subRecord) => (
                    <Tooltip title={subRecord.status === 1 ? "Идэвхтэй" : "Идэвхгүй"}>
                        <Badge
                            status={subRecord.status === 1 ? "success" : "error"}
                            text={subRecord.status === 1 ? "Идэвхтэй" : "Идэвхгүй"}
                        />
                    </Tooltip>
                ),
            },
            {
                title: "Үйлдэл",
                key: "operation",
                render: (text, record) => (
                    <Space size="small">
                        <TransparencyDelete data={record} />
                    </Space>
                ),
            },
        ];

        const subData = record.hdrs
            ? record.hdrs.map((e) => ({
                createdDate: e.createdDate,
                name: e.name,
                status: e.status,
                fileStoreId:e.fileStoreId,
                viewOrder:e.viewOrder,
                id: e.id,
            }))
            : [];

        return <Table bordered columns={subColumns} dataSource={subData} pagination={false} />;
    };

    return (
        <div>
            <div className="w-full mb-2 flex gap-10 justify-end">
                <Button onClick={fetchData} icon={<ReloadOutlined style={{ padding: "0px 5px" }} />} />
                <TransparencyAdd pubData={data} />
            </div>
            <Table
                size="middle"
                pagination={tableParams.pagination}
                columns={columns}
                dataSource={dataSource}
                bordered
                scroll={{ x: 1000, y: 500 }}
                loading={loadingTable}
                expandable={{
                    expandedRowRender,
                    defaultExpandAllRows: true,
                }}
                locale={{
                    emptyText: <Empty description="Хоосон" />,
                }}
            />
        </div>
    );
};

export default Transparency;
