import React, {useEffect, useState} from "react";

const StatCounter = ({ name, value, colors, initVal, code }) => {
    const[counter, setCounter] = useState(0);
    const[num, setNum] = useState(initVal);
    useEffect(() => {
        // setCounter(counter + value/5);
        // let intId = setTimeout(() => {
        //     setNum(Math.round(counter + initVal));
        // }, Math.round(200));
        //
        // return () => clearTimeout(intId)
        var updateStat = setInterval(() => {
            setCounter(counter + value/5);
            setNum(Math.round(counter + initVal));
        }, 200);

        return () => {
            clearInterval(updateStat);
        }
    });

    return code === 'VATPS' ? (
        colors === "brand-50" ? (
            <div className={"px-6 py-5 flex-col text-brand-50 bg-white max-sm:mx-3"}>
                <div>
                    <div className="text-[22px] leading-[30px] font-bold">
                        {name}
                    </div>
                    <div className={"pb-2"}>
                        2024 оны байдлаар
                    </div>
                    <div className={"text-3xl font-bold flex gap-2 items-center"}>
                        <p className="w-1/2">{num.toLocaleString('en-US')}</p>
                    </div>
                </div>
            </div>
        ) : (
            <div className={"px-6 py-5 flex-col text-white bg-brand-50 max-sm:mx-3"}>
                <div>
                    <div className="text-[22px] leading-[30px] font-bold">
                        {name}
                    </div>
                    <div className={"pb-2"}>
                        2024 оны байдлаар
                    </div>
                    <div className={"text-3xl font-bold flex gap-2 items-center"}>
                        <p className="w-1/2">{num.toLocaleString('en-US')}</p>
                    </div>
                </div>
            </div>
        )
    ) : code === 'ETAX' ? (
        <div
            className={"flex px-10 py-3 bg-white border-[1px] border-gray-300 max-md:pl-12 max-sm:pl-6 h-[114px]"}>
            <div className={"py-3"}>
                <p className={"font-semibold pb-2"}>{name}</p>
                <p className={"text-[25px] font-semibold py-2"}>{num.toLocaleString('en-US')}</p>
            </div>
        </div>
    ) : (<div></div>);
};
export default StatCounter;
