export const userRoleMappingUrl = {
  getUserRoleMappingList: "/userRole/getUserRoleMappingList",
};

export const menuComponentUrl = {
  getPositionMenuList: "/menuComponent/getPositionMenuList",
  getRoleMenu: "/main/getRoleMenu",
};

export const otherUrl = {
  getMenusWithHTML: "/main/getMenusWithHTML",
};

export const organizationUrl = {
  getLoggedOrganizationInfo: "/organization/getLoggedOrganizationInfo",
};
export const newsUrl = {
  getNewsActive: "/news/getNewsActive",
  getNewsIdDoc: "/news/getNewsIdDoc",
  getSpecialNews: "/news/getNewsType2",
  getAllNews:"/news/getNews",
  saveNews: "/news/saveNews",
  deleteById:"/news/deleteNewsId",
  updateNews:"/news/updateNews",
  saveProjectDocumentFiles:"/news/saveProjectDocumentFiles"
};
export const publicityUrl = {
  getPublicity: "/publicity/getPublicity",
  getPublicityList: "/publicity/getPublicityList",
  deletePublicity: "/publicity/deletePublicityId",
  updatePublicityUrlFile:"/publicity/updatePublicityUrlFile",
  savePublicity:"/publicity/savePublicity"
};

export const mainUrl = {
  getFeedBack: "/main/getFeedBack",
  saveFeedBack: "/main/saveFeedBack",
  getNewsIdDoc: "/news/getNewsIdDoc",
  deleteFeedback: "/main/deleteFeedBackId"
};

export const fileUrl = {
  upload: "/file/upload",
  view: "/file/view",
};

export const jobadsUrl = {
  getEmployees: "/jobads/getEmployees",
  getJobAdsActive: "/jobads/getJobAdsActive",
  getJobsWithDetail: "/jobads/getJobsWithDetail",
  getJobAdsDtlDoc: "/jobads/getJobAdsDtlDoc",
  getJobAdsDetailDocById: "/jobads/getJobAdsDetailDocById",
  deleteAdsId:"/jobads/deleteAdsId",
  saveAdsJob:"/jobads/saveAdsJob",
  sendCV: "/jobads/sendCV",
  checkCv: "/jobads/checkCv"
};

export const projectUrl = {
  getProjectActive: "/project/getProjectActive",
  getStatRate: "/project/getStatRate",
  getChartData: "/project/getChartData",
  getStatData: "/project/getStatData",
  getProjectDetailDocument: "/project/getProjectDetailDocument",
  projectDelete:"/project/projectDelete",
  getProjectType:"/project/getProjectType",
  projectTypeUpdate:"/project/projectTypeUpdate",
  getProjectDetailDoc:"/project/getProjectDetailDoc",
  projectHdrDtlUpdate:"/project/projectHdrDtlUpdate",
  projectHdrDetailDelete:"/project/projectHdrDetailDelete",
  deleteProjectDocument:"/project/deleteProjectDocument",
};

export const userReportUrl = {
  getUserOrgReportInfo: "/userOrgReport/getUserOrgReportInfo",
};
export const getRoleMenu = {
  getRoleMenu: "/main/getRoleMenu",
};

export const cvUrl = {
  getCv : "/jobads/getCV",
  deleteCv: "/jobads/deleteCV"
}
export const devUrl = {
  getDev : "/developer/getDeveloper",
  deleteDev: "/developer/devDetailDelete",
  editDev:"/developer/devDetailUpdate",
  editTeamHead:"/developer/devHdrUpdate",
  getTeamHead:'/developer/getDevHdr',
  saveTeamHead:'/developer/saveHdr',
  saveTeamMember:"/developer/saveDetail"
}


