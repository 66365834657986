import {Modal, Upload, notification, Button, Select, Divider} from "antd";
import {
    DeleteOutlined,
    EyeOutlined, UploadOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { postService } from "../../tools";
import { fileUrl, publicityUrl } from "../../serviceUrls";
import SimpleMotion from "../assist/simpleMotion/SimpleMotion";

const TransparencyAdd = ({ pubData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [data, setData] = useState({
        name: '',
        publicityUrlId: 0,
        status: 1,
        viewOrder: 0,
        fileStoreId: '',
        fileViewUrl:'',
        fileUrl:''
    });

    const handleOk = () => setIsModalOpen(false);

    const handleCancel = () => {
        setData({
            name: '',
            publicityUrlId: 0,
            status: 1,
            viewOrder: 0,
            fileStoreId: ''
        });
        setIsModalOpen(false);
    };

    const propsPdf = {
        showUploadList: {
            showDownloadIcon: true,
            downloadIcon: <EyeOutlined />,
            showRemoveIcon: true,
            removeIcon: <DeleteOutlined />,
        },
    };

    const uploadFile = async ({ file, onSuccess, onError, onProgress }) => {
        const fmData = new FormData();
        fmData.append("file", file);
        try {
            const res = await postService(fileUrl.upload, fmData);
           
            if (res) {
                setData({ ...data, fileStoreId: res.code, name: res.name, fileUrl: res.fileUrl, fileViewUrl: res.fileViewUrl });
                onProgress({ percent: 100 });
                onSuccess("ok");
                api.success({
                    message: 'Амжилттай',
                    description: 'Амжилтай файл хууллаа.',
                });
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            onError(error);
        }
    };

    const statusType = (value) => {
        setData({ ...data, status: value });
    };

    const publicityUrlId = (value) => {
        setData({ ...data, publicityUrlId: value });
    };

    const send = async () => {
        const body = {
            name: data.name,
            publicityUrlId: data.publicityUrlId,
            status: data.status,
            viewOrder: data.viewOrder,
            fileStoreId: data.fileStoreId,
        };

        setLoading(true);

        try {
            await postService(publicityUrl.savePublicity, body).then((res)=>{
                setIsModalOpen(false);
                api.success({
                    message: 'Амжилттай',
                    description: 'Амжилтай үүсгэлээ.',
                });
            });

            setData({
                name: "",
                publicityUrlId: 0,
                status: 1,
                viewOrder: 0,
                fileStoreId: '',
            });
        } catch (err) {
            console.error("Error saving data: ", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SimpleMotion>
            <div>
                <button
                    className="bg-brand-50 h-max text-white px-3 py-2 rounded-md"
                    onClick={() => setIsModalOpen(true)}>
                    + файл хавсаргах
                </button>
                <Modal
                    open={isModalOpen}
                    onOk={handleCancel}
                    onCancel={handleCancel}
                    footer={null}
                    width={800}
                    className="mt-60"
                >
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 700
                        }}
                    >Нэмэх
                    </div>
                    <Divider/>
                    <div className="p-4">
                        <Select
                            onChange={publicityUrlId}
                            placeholder="Сонгох"
                            style={{width: '100%', height: '40px', marginBottom: "14px"}}
                            options={pubData.map(it => ({
                                value: it.id,
                                label: it.name,
                            }))}
                        />
                        <Select
                            value={data.status}
                            onChange={statusType}
                            style={{width: '100%', height: '40px', marginTop: "14px", marginBottom: "14px"}}
                            placeholder="Төлөв"
                            options={[
                                {value: 2, label: "Идэвхгүй"},
                                {value: 1, label: "Идэвхтэй"},
                            ]}
                        />
                        <Upload
                            style={{width: '100%', height: '40px', marginBottom: "14px"}}
                            {...propsPdf}
                            customRequest={uploadFile}
                        >
                            <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                        </Upload>

                        <Button
                            style={{marginTop: '14px', height: "40px"}}
                            onClick={send}
                            type="primary"
                            loading={loading}
                            className="bg-[#031842]"
                        >
                            Хадгалах
                        </Button>
                    </div>
                </Modal>
            </div>
            {contextHolder}
        </SimpleMotion>
    );
};

export default TransparencyAdd;
