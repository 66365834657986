import { Empty } from "antd";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import Title from "./Title";
import { Link } from "react-router-dom";
import Arrow from "../components/assist/arrow/Arrow";
import { postServicePublic } from "../tools";
import { jobadsUrl } from "../serviceUrls";
import { useEffect, useState } from "react";

const JobAds = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    postServicePublic(jobadsUrl.getJobAdsActive, {}).then((res) => {
      //   console.log(res);
      setData(res.result);
    });
  }, []);
  return (
    <div>
      <SimpleMotion>
        <div className="bg-white max-w-[1280px] mx-auto mt-4">
          <Title name="Нээлттэй ажлын байр" />

          {data ? (
            <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 text-brand-50">
              {data?.map((e, i) => (
                <Link
                  key={i}
                  to={`/job-ads/${e.id}`}
                  className="py-6 px-9 border cursor-pointer hover:border-brand-50 group"
                >
                  <div className="flex justify-between text-xl font-semibold mb-2">
                    <div className="w-11/12">{e.title}</div>
                    <div className="group-hover:translate-x-4 duration-300 flex items-center pr-6">
                      <Arrow />
                    </div>
                  </div>
                  <div className="text-sm text-justify leading-5 font-light">
                    {e.description}
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </SimpleMotion>
    </div>
  );
};
export default JobAds;
