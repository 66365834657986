
import { Link } from "react-router-dom";
import Arrow from "../components/assist/arrow/Arrow";
import Title from "./Title";
import {FormattedHTMLMessage} from "react-intl";

const ProjectComp = () => {
  return (
    <div className="max-w-[1280px] mx-auto mt-4 max-sm:m-0">
      <div className="flex max-md:flex-col-reverse bg-brand-50 text-white">
        <div className="w-1/2 max-md:w-full">
          <Title name={"header_projects"} color={"brand-50"} />
          <div className="px-9 text-justify pb-10">
            <FormattedHTMLMessage id={"project_content"}/>
          </div>
          <Link
            to="/project"
            className="flex items-center group px-9 gap-4 py-8"
          >
            <div className="group-hover:translate-x-4 duration-300 flex items-center pr-2">
              <Arrow name={"white"} />
            </div>
            <div className="group-hover:underline group-hover:underline-offset-8 ">
              <FormattedHTMLMessage id={"more_view"}/>
            </div>
          </Link>
        </div>

        <div className="w-1/2 max-md:w-full">
          <img
            className="h-full object-cover"
            src={"/images/background/background9.png"}
            alt="ITC GOV"
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectComp;
