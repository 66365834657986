import React, {useContext} from 'react';
import {Button, Col, Layout, Row, Space, Switch, theme} from 'antd';
import {MenuFoldOutlined, MenuUnfoldOutlined, MoonFilled, SunFilled} from "@ant-design/icons";
import HeaderOtherSystemMenu from "./HeaderOtherSystemMenu";
import UserProfileMenu from "./UserProfileMenu";
import mainContext from "../mainContext";

const {Header} = Layout;

export default function MainHeader({collapsed, setCollapsed}) {
  const {token: {colorBgContainer}} = theme.useToken();
  const {userTheme, setUserTheme} = useContext(mainContext);

  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        boxShadow: '0px 8px 5px -4px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Row>
        <Col xs={2} sm={2} md={5} lg={4} xl={2} xxl={2}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Col>
        <Col
          xs={22}
          sm={22}
          md={19}
          lg={20}
          xl={22}
          xxl={22}
          style={{textAlign: "right", paddingRight: "20px"}}
        >
          <Space>
            <Switch
              checkedChildren={<MoonFilled />}
              unCheckedChildren={<SunFilled />}
              defaultChecked={userTheme === 'dark'}
              onChange={(e) => {
                let uTheme = 'light';
                if (e === true)
                  uTheme = 'dark'
                localStorage.setItem('theme', uTheme);
                setUserTheme(uTheme);
              }}
            />
            <UserProfileMenu />
            <HeaderOtherSystemMenu />
          </Space>
        </Col>
      </Row>
    </Header>
  );
};