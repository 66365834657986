import {Table, Empty, Button, Image, Badge, Tooltip, Space} from "antd";
import { useEffect, useState } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { postService } from "../../tools";
import {devUrl, mainUrl} from "../../serviceUrls";
import TeamHeadAdd from "./TeamHeadAdd";
import TeamMemberAdd from "./TeamMemberAdd";
import TeamEdit from "./TeamEdit";
import TeamDelete from "./TeamDelete";

const Team = (props) => {
    const [data, setData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    useEffect(() => {
        getdata();
    }, []);
    const getdata = async () => {
        setLoadingTable(true);
        const body = {
            page: 0,
            size: 10,
        };
        postService(devUrl.getDev, body)
            .then((res) => {
                setData(res.result);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: res.totalItems,
                    },
                });
            })
            .finally(() => {
                setLoadingTable(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setLoadingTable(true);
        const body = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        };
        postService(mainUrl.getFeedBack, body)
            .then((res) => {
                setData(res.result);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: res.totalItems,
                    },
                });
            })
            .finally(() => {
                setLoadingTable(false);
            });
    };

    const columns = [
        {
            title: <div>Огноо</div>,
            dataIndex: "createdDateStr",
            key: "createdDateStr",
            width: 120,
            fixed: "left",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            ellipsis: true,
        },
        {
            title: <div>Зураг</div>,
            dataIndex: "image",
            key: "image",
            // width: 120,
            ellipsis: true,
            render: (img) => (
                <div>
                    <Image alt="ITC" preview={true} src={"/api/itc-gov-service-new/file/view/" + img} />
                </div>
            ),
        },
        {
            title: <div>Албаны нэр</div>,
            dataIndex: "title",
            key: "title",
            ellipsis: true,
        },
        {
            title: <div>Төлөв</div>,
            dataIndex: "status",
            key: "statuss",
            ellipsis: true,
            sorter: (a, b) => a.status - b.status,
            render: (a) => (
                <div>
                    <Tooltip title={a === 1 ? "Идэвхтэй" : "Идэвхгүй"}>
                        <Badge
                            status={a === 1 ? "success" : "error"}
                            text={a === 1 ? "Идэвхтэй" : "Идэвхгүй"}
                            // style={{ color: "red" }}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const data1 = data.map((e, i) => ({
        key: i,
        title: e.title,
        image: e.image,
        createdDateStr: e.createdDateStr,
        type: e.type,
        status: e.status,
        id: e.id,
        developerDetails: e.developerDetails,
    }));
    const expandedRowRender = (params) => {
        const columns = [
            {
                title: "Date",
                dataIndex: "createdDateStr",
                key: "createdDateStr",
                width: 140,
            },
            {
                title: "Нэр",
                dataIndex: "fullName",
                key: "fullName",
            },
            {
                title: "Албан тушаал",
                dataIndex: "jobPosition",
                key: "jobPosition",
            },
            {
                title: "Утасны дугаар",
                dataIndex: "phone",
                key: "phone",
            },
            {
                title: "Цахим шуудан",
                dataIndex: "email",
                key: "email",
            },
            {
                title: <div>Зураг</div>,
                dataIndex: "image",
                key: "image",
                width: 80,
                render: (img) => (
                    <div>
                        <Image alt="ITC" preview={true} src={"/api/itc-gov-service-new/file/view/" + img} />
                    </div>
                ),
                ellipsis: true,
            },
            {
                title: "Төлөв",
                key: "status",
                sorter: (a, b) => a.status - b.status,
                render: (a) => (
                    <Tooltip title={a.status === 1 ? "Идэвхтэй" : "Идэвхгүй"}>
                        <Badge
                            status={a.status === 1 ? "success" : "error"}
                            text={a.status === 1 ? "Идэвхтэй" : "Идэвхгүй"}
                        />
                    </Tooltip>
                ),
            },
            {
                title: "Үйлдэл",
                dataIndex: "operation",
                width: 120,
                key: "operation",
                render: (params) => (
                    <Space size="small">
                        <TeamEdit data={params} getDeveloper={getdata} />
                        <TeamDelete getDeveloper={getdata} id={params.id} />
                    </Space>
                ),
            },
        ];
        const data = params.developerDetails
            ? params.developerDetails.map((e, i) => ({
                key: i,
                fullName: e.fullName,
                createdDateStr: e.createdDateStr,
                image: e.image,
                type: e.type,
                status: e.status,
                id: e.id,
                jobPosition: e.jobPosition,
                operation: e,
            }))
            : [];
        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                locale={{
                    emptyText: <Empty description="Хоосон" />,
                }}
            />
        );
    }
    return (
        <div>
            <div className="items-center">
                <div className="w-full mb-2 flex gap-10 justify-end">
                    <Button
                        onClick={getdata}
                        icon={<ReloadOutlined style={{ padding: "0px 5px" }} />}
                    ></Button>
                    <TeamHeadAdd getDeveloper={getdata} />
                    <TeamMemberAdd getDeveloper={getdata} />
                </div>
            </div>
            <Table
                size="middle"
                pagination={tableParams.pagination}
                columns={columns}
                dataSource={data1}
                bordered
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ["0"],
                }}
                scroll={{ x: 1000, y: 500 }}
                loading={loadingTable}
                onChange={handleTableChange}
                locale={{
                    emptyText: <Empty description="Хоосон" />,
                }}
            />
        </div>
    );
};
export default Team;
