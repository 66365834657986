import React, { useState } from "react";
import { Flex, Layout, Menu, theme } from "antd";
import logo from "./logo/vertical logo.png";
import logo_mini from "./logo/logo.png";
import { useNavigate } from "react-router-dom";
import { getMenuItems, getTokenInfo, postService } from "./tools";
import { useEffect } from "react";
import { menuComponentUrl } from "./serviceUrls";

const { Sider } = Layout;

export default function MainSider({ collapsed }) {
  const [menuItems, setMenuItems] = useState();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const getLogo = () => {
    if (collapsed === false) return logo;
    return logo_mini;
  };
  useEffect(() => {
    postService(menuComponentUrl.getRoleMenu).then((res) => {
      let items = [];
      if (res.length !== 0) {
        res.forEach((posMenu) => {
          const menu = posMenu;
          if (!menu || !menu.url || !menu.name) return;
          // const icon = getIconByName(menu.icon);
          items.push({
            key: menu.url,
            // icon: React.createElement(icon),
            label: menu.name,
            components: menu.components,
          });
        });
      }
      if (items.length !== 0) {
        navigate(items[0].key);
      }
      setMenuItems(items);
    });
  }, []);
  return (
    <Sider
      collapsed={collapsed}
      style={{
        overflow: "auto",
        height: "100vh",
        left: 0,
        top: 0,
        bottom: 0,
        position: "fixed",
        background: colorBgContainer,
      }}
    >
      <Flex justify="center" align="center" style={{ margin: "15px 0 10px" }}>
        <img src={getLogo()} alt="logo" style={{ height: "40px" }} />
      </Flex>
      <Menu
        mode="inline"
        style={{ minHeight: window.innerHeight - 80 }}
        items={menuItems}
        onClick={(e) => navigate(e.key)}
      />
    </Sider>
  );
}
