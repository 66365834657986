import { Table, Empty, Button } from "antd";
import { useEffect, useState } from "react";
import {EyeOutlined, ReloadOutlined} from "@ant-design/icons";
import { postService } from "../../tools";
import {cvUrl, mainUrl} from "../../serviceUrls";
import {Link} from "react-router-dom";
import CvDelete from "./CvDelete";

const Cv = (props) => {
    const [data, setData] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    useEffect(() => {
        getdata();
    }, []);
    const getdata = async () => {
        setLoadingTable(true);
        const body = {
            page: 0,
            size: 10,
        };
        postService(cvUrl.getCv, body)
            .then((res) => {
                setData(res.result);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: res.totalItems,
                    },
                });
            })
            .finally(() => {
                setLoadingTable(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setLoadingTable(true);
        const body = {
            page: pagination.current - 1,
            size: pagination.pageSize,
        };
        postService(mainUrl.getFeedBack, body)
            .then((res) => {
                setData(res.result);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: res.totalItems,
                    },
                });
            })
            .finally(() => {
                setLoadingTable(false);
            });
    };

    const columns = [
        {
            title: <div>Огноо</div>,
            dataIndex: "createDate",
            key: "createDate",
            width: 120,
            fixed: "left",
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            render: (text) => {
                const date = new Date(text);
                return date.toLocaleDateString('mn-MN', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
            },
            ellipsis: true,
        },
        {
            title: <div>Овог</div>,
            dataIndex: "firstName",
            key: "firstName",
            // width: 120,
            ellipsis: true,
        },
        {
            title: <div>Нэр</div>,
            dataIndex: "lastName",
            key: "lastName",
            ellipsis: true,
        },
        {
            title: <div>Имэйл</div>,
            dataIndex: "email",
            key: "email",
            ellipsis: true,
        },
        {
            title: <div>Утасны дугаар</div>,
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            ellipsis: true,
        },
        {
            title: <div>Боловсрол</div>,
            dataIndex: "education",
            key: "education",
            ellipsis: true,
        },
        {
            title: <div>Ажлын зар</div>,
            dataIndex: "jobAdsHdrTitle",
            key: "jobAdsHdrTitle",
            ellipsis: true,
        },
        {
            title: <div>Үйлдэл</div>,
            key: "id",
            width: 70,
            render: (params) => (
                <div>
                    <div className="flex gap-2">
                        <Link
                            href={`/api/v1/getPdf?file=${params.fileName}`}
                            passHref={true}
                            target="_blank"
                        >
                            <EyeOutlined />
                        </Link>
                        <CvDelete getdata={params.id}/>
                    </div>
                </div>
            ),
        },
    ];

    const data1 = data.map((e, i) => ({
        createDate: e.createDate,
        firstName: e.firstName,
        lastName: e.lastName,
        email: e.email,
        phoneNumber: e.phoneNumber,
        education: e.education,
        jobAdsHdrTitle: e.jobAdsHdrTitle,
        id: e.id,
    }));
    return (
        <div>
            <div className="items-center">
                <div className="w-full mb-2 flex gap-10 justify-end">
                    <Button
                        onClick={getdata}
                        icon={<ReloadOutlined style={{ padding: "0px 5px" }} />}
                    ></Button>
                </div>
            </div>
            <Table
                size="middle"
                pagination={tableParams.pagination}
                columns={columns}
                dataSource={data1}
                bordered
                scroll={{ x: 1000, y: 500 }}
                loading={loadingTable}
                onChange={handleTableChange}
                locale={{
                    emptyText: <Empty description="Хоосон" />,
                }}
            />
        </div>
    );
};
export default Cv;
