import {Button, Image, Modal, Spin} from "antd";
import { useEffect, useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import {  useNavigate } from "react-router-dom";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import Title from "./Title";
import Arrow from "../components/assist/arrow/Arrow";
import { getServicePublic} from "../tools";
import { publicityUrl } from "../serviceUrls";
import {FormattedMessage} from "react-intl";

const Publicity = () => {
  const navigate = useNavigate();
  const [publicity, setPublicity] = useState([]);
  const [iframeSrc, setIframeSrc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [lan, setLan] = useState('')

  useEffect(() => {
    setLoading(true);
    getServicePublic(publicityUrl.getPublicity)
      .then((res) => {
        setPublicity(res);
      })
      .finally(() => {
        setLoading(false);
      });
    setLan(localStorage.getItem("language_local"))
    const to = lan === "en" ? "mn" : "en";
    setLan(to);
  }, []);


  const clickData = (data) => {
    const url = data.pageUrl;
    if (url && url.startsWith("www")) {
      window.open("http://" + url, "_blank").focus();
    } else if (url) {
      navigate(url);
    } else {
      setVisibility(data.id);
    }
  };

  const viewFile = (doc) => {
    setLoading(true);
    getServicePublic(`/file/view/${doc.fileStoreId}`, null, "blob").then(result => {
      const urlObj = window.URL.createObjectURL(result);
      setIframeSrc(urlObj)
    }).finally(() => {
      setLoading(false);
      setModalVisibility(true);
    })
  }

  return (
    <div>
      <SimpleMotion>
        <Spin spinning={loading}>
          {publicity.map((data, i) => (
            <div
              className={`max-w-[1400px] mx-auto mt-4 text-brand-50 bg-white`}
              key={data.id}
            >
              <Title name={lan === "mn" ? data.name : data.name === 'Үйл ажиллагаа' ? "home_transparency_activity" : data.name === 'Хүний нөөц' ? "home_transparency_human" : "home_transparency_finance"} />
              <div
                className={
                  loading
                    ? "grid grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 text-brand-50 bg-white"
                    : "grid grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1 text-brand-50"
                }
              >
                {data.publicityFileList.map((file, index) => (
                  <div key={index}>
                    <div
                      className={`px-9 py-6 border cursor-pointer hover:border-brand-50 group  ${
                        visibility === file.id ? "bg-[#031842]" : "bg-white"
                      }`}
                      onClick={() => {
                        clickData(file);
                      }}
                    >
                      <div
                        className={`flex justify-between items-center text-xl font-semibold`}
                      >
                        <div className="flex gap-8 px-2">
                          <Image
                            src={`${
                              visibility === file.id
                                ? "/images/news/pdf-fileDark.svg"
                                : "/images/news/pdf-file.svg"
                            }`}
                            alt="PDF FILE"
                            width={50}
                            height={50}
                          />
                          <div
                            className={`flex items-center ${
                              visibility === file.id ? "text-white" : ""
                            }`}
                          >
                            {lan === 'mn' ? file.name : file.name === "Төлөвлөгөө" ? <FormattedMessage id={'home_transparency_activity_plan'}/>
                                : file.name === "Төлөвлөгөө" ? <FormattedMessage id={'home_transparency_activity_plan'}/>
                                : file.name === "Санал, хүсэлт шийдвэрлэлт" ? <FormattedMessage id={'home_transparency_activity_report'}/>
                                : file.name === "Албан хаагчдын мэдээлэл" ? <FormattedMessage id={'home_transparency_human_emp'}/>
                                : file.name === "Сул орон тооны мэдээлэл" ? <FormattedMessage id={'home_transparency_human_career'}/>
                                : file.name === "Тендер" ? <FormattedMessage id={'home_transparency_finance_procure'}/>
                                : <FormattedMessage id={'home_transparency_finance_audit'}/>}
                          </div>
                        </div>
                        <div
                          className={`group-hover:translate-x-4 duration-300 flex items-center pr-1 ${
                            visibility === file.id ? "rotate-90" : ""
                          }`}
                        >
                          <Arrow
                            name={`${visibility === file.id ? "white" : ""}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      {file.pubDocumentList.map((doc, index) => (
                        <div
                          className={`grid grid-cols-1 max-lg:grid-cols-3 border-t ${
                            visibility === doc.publicityUrlId ? "" : "hidden"
                          } transition duration-500 ease-in-out delay-150 `}
                          key={index}
                        >
                          <Button
                            onClick={() => viewFile(doc)}
                          >
                            <LinkOutlined />
                            <div className="underline underline-offset-1">
                              {doc.name}
                            </div>
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Spin>
      </SimpleMotion>
      <Modal
          open={modalVisibility}
          onCancel={() => setModalVisibility(false)}
          footer={null}
          width={800}
          bodyStyle={{height:'600px'}}
      >
        <iframe
            className="w-full h-full p-6"
            src={iframeSrc}
        />
      </Modal>

    </div>
  );
};

export default Publicity;
