import { Button, Popconfirm, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState } from "react";
import {postService} from "../../tools";
import {projectUrl} from "../../serviceUrls";

const ProjectDelete = (props) => {
    const [loading, setLoading] = useState(false);
    const deleteFunc = async () => {
        setLoading(true);
        const headers = {
            Authorization: "Bearer " + localStorage.getItem("token"),
        };
        const body = {
            id: props.id,
        };
        postService(projectUrl.projectHdrDetailDelete, body)
            .then((res) => {
                message.success(res.msg);
                props.getProject();
            })
            .catch((er) => {
                console.log("er", er);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <div>
            <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText={"Тийм"}
                okType={"default"}
                onConfirm={deleteFunc}
                cancelText="Үгүй"
            >
                <Button
                    size="small"
                    loading={loading}
                    icon={<DeleteOutlined color="red" />}
                ></Button>
            </Popconfirm>
        </div>
    );
};
export default ProjectDelete;
