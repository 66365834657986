import { Link } from "react-router-dom";
import Arrow from "../components/assist/arrow/Arrow";
import { Image } from "antd";
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";

const Iso = () => {
  const [lan, setLan] = useState("")
  const intl = useIntl();
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language_local");
    if (savedLanguage) {
      setLan(savedLanguage);
    }
  }, []);
  const isoImage = intl.formatMessage({id:'formatted_iso_image_2'})
  return (
    <div className="bg-white max-w-[1280px] mx-auto mt-4 max-sm:m-0 text-brand-50">
      <Link
        to={"/iso"}
        className="flex group hover:border hover:border-brand-50 border max-md:flex-col-reverse"
      >
        <div className="flex items-center w-2/4 py-6 max-md:w-full">
          <div className="group-hover:translate-x-4 duration-300 flex items-center pl-9 pr-2">
            <Arrow />
          </div>
          <div className="flex flex-col ml-6 w-11/12 ">
            <div className=" text-xl font-semibold">
              <FormattedMessage id={"iso_header"}/>
            </div>
            <div className="leading-5 mt-1 text-sm">
              <FormattedMessage id={"iso_content"}/>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-2/4 bg-brand-50 py-6 max-md:w-full">
          <div className="px-6 flex items-center">
                  <Image
                  className="md:h-18 w-60 md:w-50"
                  src={isoImage}
                  alt="ITC GOV"
                  width={260}
                  />
          </div>
          <div className="border-l-2 px-6 ">
            <Image
              className="h-20 w-20 max-md:h-14 max-md:w-14"
              src={"/images/logo/logoWhite.png"}
              alt="ITC GOV"
              width={90}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
export default Iso;
