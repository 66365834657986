
import { Image } from "antd";
import Arrow from "../components/assist/arrow/Arrow";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import Title from "./Title";
import { Link } from "react-router-dom";
import Iso from "./Iso";
import ProjectComp from "./ProjectComp";
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";

const About = () => {
  const [lan, setLan] = useState('')
  const intl = useIntl();
  useEffect(() => {
    setLan(localStorage.getItem("language_local"))
    const savedLan = localStorage.getItem("language_local");
    if (savedLan) {
      setLan(savedLan);
    } else {
      setLan("en");
    }
  }, []);

  const isoImage = intl.formatMessage({id:'formatted_iso_image'})
  return (
    <div>
      {/* <Breadcrumb name={"Бидний тухай"} router={"about"} /> */}
      <SimpleMotion>
        <div className="max-w-[1280px] mx-auto mt-4 bg-white text-brand-50">
          <Title name={"intro"} />
          <div className="text-justify px-9 text-sm">
            <FormattedMessage id="about_us_intro"/>
          </div>
          <div className="flex border-t mt-8 max-md:flex-col">
            <div className="w-1/2 flex items-center justify-center  py-10 max-md:w-full">
              <div className="w-16 h-16 rounded mr-10 max-sm:mr-5">
                <Image
                  className="transition duration-300 ease-in-out group-hover:scale-110 h-14 w-14 cursor-pointer bg-no-repeat max-md:w-16 max-md:h-16"
                  src={"/images/human/alsiinHaraa.png"}
                  alt="ITC GOV"
                />
              </div>
              <div className="w-2/3">
                <div className="text-xl font-semibold"><FormattedMessage id="about_us_vision"/></div>
                <div className="leading-5 mt-2 text-sm max-sm:pr-2">
                  <FormattedMessage id={"about_us_vision_content"}/>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex items-center justify-center py-10 border-l max-md:w-full max-md:border-t">
              <div className="w-16 h-16   rounded mr-10 max-sm:mr-5">
                <Image
                  
                  className="transition duration-300 ease-in-out group-hover:scale-110 h-14 w-14 cursor-pointer bg-no-repeat max-md:w-16 max-md:h-16"
                  src={"/images/human/erhemZorligo.png"}
                  alt="ITC GOV"
                />
              </div>
              <div className="w-2/3">
                <div className="text-xl font-semibold"><FormattedMessage id="about_us_mission"/></div>
                <div className="leading-5 mt-2 text-sm max-sm:pr-2">
                  <FormattedMessage id={"about_us_mission_1"}/><br/>
                  <FormattedMessage id={"about_us_mission_2"}/><br/>
                  <FormattedMessage id={"about_us_mission_3"}/><br/>
                  <FormattedMessage id={"about_us_mission_4"}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1280px] mx-auto mt-4">
          <Title name={"about_main_functions"} />
          <div className="border-t h-[168.5px] max-md:h-[226px] max-sm:h-[390px]">
            <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 text-brand-50">
              <div className="relative">
                <div className="px-9 py-3 border hover:z-30 bg-white cursor-pointer hover:shadow-shadowRB absolute group w-full transition-all h-14 duration-300 hover:h-48 hover:absolute hover:w-full hover:bg-white">
                  <div className="flex justify-between items-center text-xl font-semibold ">
                    <FormattedMessage id={"about_main_functions_development"}/>
                    <div className="transition duration-300 ease-in-out group-hover:rotate-90 flex items-center">
                      <Arrow />
                    </div>
                  </div>

                  <div className="hidden duration-300 animate-fadeIn h-10 group-hover:h-32 transition-all group-hover:block text-sm text-justify pr-10 pl-6 leading-5 font-light bg-white mt-4">
                    <FormattedMessage id={'about_main_functions_development_content'}/>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="px-9 py-3 max-sm:top-14 border hover:z-30 bg-white cursor-pointer hover:shadow-shadowRB absolute group w-full transition-all h-14 duration-300 hover:h-48 hover:absolute hover:w-full hover:bg-white">
                  <div className="flex justify-between items-center text-xl font-semibold ">
                    <div><FormattedMessage id={"about_main_functions_collaboration"}/></div>
                    <div className="transition duration-300 ease-in-out group-hover:rotate-90 flex items-center">
                      <Arrow />
                    </div>
                  </div>

                  <div className="hidden duration-300 animate-fadeIn h-10 group-hover:h-32 transition-all group-hover:block text-sm text-justify pr-10 pl-6 leading-5 font-light bg-white mt-4">
                    <FormattedMessage id={"about_main_functions_collaboration_content"}/>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="px-9 py-3 max-md:top-14 max-sm:top-28 border hover:z-30 bg-white cursor-pointer hover:shadow-shadowRB absolute group w-full transition-all h-14 duration-300 hover:h-48 hover:absolute hover:w-full hover:bg-white">
                  <div className="flex justify-between items-center text-xl font-semibold ">
                    <div><FormattedMessage id={"about_main_functions_security"}/></div>
                    <div className="transition duration-300 ease-in-out group-hover:rotate-90 flex items-center">
                      <Arrow />
                    </div>
                  </div>

                  <div className="hidden duration-300 animate-fadeIn h-10 group-hover:h-32 transition-all group-hover:block text-sm text-justify pr-10 pl-6 leading-5 font-light bg-white mt-4">
                    <FormattedMessage id={"about_main_functions_security_content"}/>
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="px-9 py-3 top-14 max-sm:top-[168px] border hover:z-20 bg-white cursor-pointer hover:shadow-shadowRB absolute group w-full transition-all h-14 duration-300 hover:h-48 hover:absolute hover:w-full hover:bg-white">
                  <div className="flex justify-between items-center text-xl font-semibold ">
                    <div><FormattedMessage id={"about_main_functions_database"}/></div>
                    <div className="transition duration-300 ease-in-out group-hover:rotate-90 flex items-center">
                      <Arrow />
                    </div>
                  </div>

                  <div className="hidden duration-300 animate-fadeIn h-10 group-hover:h-32 transition-all group-hover:block text-sm text-justify pr-10 pl-6 leading-5 font-light bg-white mt-4">
                    <FormattedMessage id={'about_main_functions_database_content'}/>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="px-9 py-3 top-14 max-md:top-28 max-sm:top-[224px] border hover:z-20 bg-white cursor-pointer hover:shadow-shadowRB absolute group w-full transition-all h-14 duration-300 hover:h-48 hover:absolute hover:w-full hover:bg-white">
                  <div className="flex justify-between items-center text-xl font-semibold ">
                    <div><FormattedMessage id={"about_main_functions_innovation"}/></div>
                    <div className="transition duration-300 ease-in-out group-hover:rotate-90 flex items-center">
                      <Arrow />
                    </div>
                  </div>

                  <div className="hidden duration-300 animate-fadeIn h-10 group-hover:h-32 transition-all group-hover:block text-sm text-justify pr-10 pl-6 leading-5 font-light bg-white mt-4">
                    <FormattedMessage id={"about_main_functions_innovation_content"}/>
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="px-9 py-3 top-14 max-md:top-28 max-sm:top-[280px] border hover:z-20 bg-white cursor-pointer hover:shadow-shadowRB absolute group w-full transition-all h-14 duration-300 hover:h-48 hover:absolute hover:w-full hover:bg-white">
                  <div className="flex justify-between items-center text-xl font-semibold ">
                    <div><FormattedMessage id={"about_main_functions_research"}/></div>
                    <div className="transition duration-300 ease-in-out group-hover:rotate-90 flex items-center">
                      <Arrow />
                    </div>
                  </div>

                  <div className="hidden duration-300 animate-fadeIn h-10 group-hover:h-32 transition-all group-hover:block text-sm text-justify pr-10 pl-6 leading-5 font-light bg-white mt-4">
                    <FormattedMessage id={"about_main_functions_research_content"}/>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="px-9 py-3 top-28 max-md:top-[168px] max-sm:top-[336px] border hover:z-10 bg-white cursor-pointer hover:shadow-shadowRB absolute group w-full transition-all h-14 duration-300 hover:h-48 hover:absolute hover:w-full hover:bg-white">
                  <div className="flex justify-between items-center text-xl font-semibold ">
                    <div><FormattedMessage id={"about_main_functions_coherence"}/></div>
                    <div className="transition duration-300 ease-in-out group-hover:rotate-90 flex items-center">
                      <Arrow />
                    </div>
                  </div>
                  <div className="hidden duration-300 animate-fadeIn h-10 group-hover:h-32 transition-all group-hover:block text-sm text-justify pr-10 pl-6 leading-5 font-light bg-white mt-4">
                    <FormattedMessage id={"about_main_functions_coherence_content"}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1280px] mx-auto mt-4 bg-white text-brand-50">
          <div className="flex items-center justify-between max-sm:flex-col">
            <Title name={"org_structure"} />
            <Link to={"/workers"}>
              <div className="px-3 py-2 text-gray-200 flex items-center justify-end text-sm pr-1 mr-7 w-[277px] max-sm:w-full">
                <div className="group border-2 cursor-pointer border-white text-white mx-1 font-medium flex justify-center items-center duration-300 hover:border-brand-50">
                  <span className="py-2 bg-brand-50 px-3 border border-white group-hover:border-white flex gap-4">
                    <div className="group-hover:translate-x-2 duration-300">
                      <Arrow name={"white"} />
                    </div>
                    <FormattedMessage id={"employees"}/>
                  </span>
                </div>
              </div>
            </Link>
          </div>
          {/* <div
            href={"/"}
            onClick={() => setIsModalOpen(true)}
            className="group border-2 cursor-pointer border-white text-white mx-1 font-medium flex justify-center items-center duration-300 hover:border-brand-50"
          >
            <span className="py-2 bg-brand-50 px-3 border border-white group-hover:border-white flex gap-4">
              <div className="group-hover:translate-x-2 duration-300">
                <Arrow name={"white"} />
              </div>
              Албан хаагчдын мэдээлэл
            </span>
          </div> */}
          <div className="text-justify px-9 max-sm:px-0 text-sm pb-8">
            <div>
               <img src={isoImage} alt="ITC GOV"/>
            </div>

          </div>
        </div>
        <div className="mt-4">
          <ProjectComp />
        </div>
        <div className="mt-4">
          <Iso />
        </div>
      </SimpleMotion>
    </div>
  );
};
export default About;
